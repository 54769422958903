import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./ProductClient.module.css";
import { ContentHead } from "../../../components/client/contentHead/ContentHead";
import { AppContext } from "../../../context/app-context";
import { useLocation } from "react-router-dom";
import { ProductBox } from "../../../components/client/productBox/ProductBox";

export const ProductClient = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;

  const [products, setProducts] = useState(null);
  useEffect(() => {
    const GetProducts = async () => {
      const response = await appContext.FetchHandler(
        "product/clientList",
        "post",
        null,
        { id: state.id }
      );

      if (response.ok) {
        response.data && setProducts(response.data);
      }
    };
    GetProducts();
  }, []);

  return (
    <Fragment>
      <section className={classes.section}>
        <ContentHead data={state} />
        <ProductBox products={products} />
      </section>
    </Fragment>
  );
};
