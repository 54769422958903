import React, { Fragment } from "react";
import classes from "./StufFileUpload.module.css";
import { ConvertFileBase64 } from "../../../util/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

export const StufFileUpload = ({ stuff, setStuff }) => {
  const RemoveFile = (event) => {
    event.preventDefault();
    const filtered = Array.from(stuff.resim).filter(
      (x) => x.dosyaAdi !== event.target.id
    );

    if (filtered.length == 0) document.getElementById("file").value = "";

    setStuff((prev) => ({
      ...prev,
      resim: filtered,
    }));
  };
  const FileHandler = (event) => {
    const file = event.target.files[0];

    ConvertFileBase64(file).then((resolve) => {
      setStuff((prev) => ({
        ...prev,
        resim: [
          ...prev.resim,
          {
            base64Resim: resolve.base64,
            dosyaAdi: file.name,
            preview: resolve.preview,
          },
        ],
      }));
    });
  };

  useEffect(() => {}, [stuff]);

  return (
    <Fragment>
      <div className={classes.fileContainer}>
        <label htmlFor="file" className={classes.fileBox}>
          <img src="/assets/icons/imgAdd.png" alt="icon" />
          <h5>Eşya görseli yükleyiniz</h5>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {stuff.resim.length > 0 &&
              Array.from(stuff.resim).map((y, j) => {
                return (
                  <div
                    className={classes.fileListBox}
                    title="Kaldır"
                    key={j}
                    id={y.dosyaAdi}
                    onClick={(event) => RemoveFile(event)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        pointerEvents: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={y.preview} alt="" />
                      <p>{y.dosyaAdi}</p>
                    </div>

                    <FontAwesomeIcon
                      className={classes.removeIcon}
                      icon={faRemove}
                    />
                  </div>
                );
              })}
          </div>
        </label>
        <input
          id="file"
          name="file"
          type="file"
          className={classes.customFile}
          required
          onChange={(event) => FileHandler(event)}
        />
      </div>
    </Fragment>
  );
};
