import React from "react";
import { Fragment } from "react";
import {
  DataTableImage,
  DataTableProcess,
  EntityStatusHtml,
} from "../../../util/commonUtil";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { useState } from "react";
import { useEffect } from "react";
import { SwalSuccess, SwallApprove } from "../../../util/swalUtil";
import { HomePageCreateForm } from "../../../components/admin/homePage/HomePageCreateForm";
import classes from "./HomePage.module.css";
export const HomePage = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Anasayfa";
  const apiName = "homePage";
  const [crudState, setCrudState] = useState(false);

  const [formInput, setFormInput] = useState();

  useEffect(() => {
    const GetData = async () => {
      const response = await appContext.FetchHandler(
        apiName,
        "get",
        null,
        null
      );

      if (response.ok) {
        setFormInput(response.data);
      }
    };
    GetData();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData(document.getElementById("form"));

        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          apiName,
          method,
          "formData",
          formData
        );

        if (response.ok) {
          setCrudState(!crudState);

          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>

      <div className={classes.form}>
        <HomePageCreateForm
          formInput={formInput}
          setFormInput={setFormInput}
          submitHandler={SubmitHandler}
        />
      </div>
    </Fragment>
  );
};
