import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { useLocation } from "react-router-dom";
import { SwalErrorMessage, SwalInfoMessage } from "../../../util/swalUtil";
import classes from "./Stuff.module.css";
import { ContentHead } from "../../../components/client/contentHead/ContentHead";
import { StuffForm } from "../../../components/client/stuffForm/StuffForm";
import Swal from "sweetalert2";
import { StuffStartForm } from "../../../components/client/stuffStartForm/StuffStartForm";
import moment from "moment";
import { CheckFormValid } from "../../../components/ui/input/Validation";
import { OtpForm } from "../../../components/client/otpForm/OtpForm";

export const Stuff = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;

  const formInputJson = {
    gToken: null,
    basvuruTipi: 0,
    basvuruId: state.id,
    tc: null,
    dogumTarihi: null,
    adi: null,
    soyadi: null,
    cepTelefonu: null,
    vergiNo: null,
    firmaAdi: null,
    firmaTelefon: null,
    firmaAdres: null,
    esyaList: [],
  };

  const [formInput, setFormInput] = useState(formInputJson);
  const [formInputIsValid, setFormInputIsValid] = useState({
    tc: { status: false, message: "T.C. Kimlik: Zorunludur." },
    dogumTarihi: { status: false, message: "Doğum Tarihi: Zorunludur." },
    adi: { status: false, message: "Adınız: Zorunludur." },
    soyadi: { status: false, message: "Soyadınız: Zorunludur." },
    cepTelefonu: { status: false, message: "Telefon Numarası: Zorunludur." },
    kvkk: { status: false, message: "KVKK: Onayı zorunludur." },
    rizaMetni: { status: false, message: "Rıza Metni: Onayı zorunludur." },
    vergiNo: { status: true, message: "Vergi Numarası: Zorunludur." },
    firmaAdi: { status: true, message: "Firma Adı: Zorunludur." },
    firmaAdres: { status: true, message: "Firma Adresi: Zorunludur." },
  });

  const [isStaffForm, setIsStaffForm] = useState(false);

  const [stuffTypeData, setStuffTypeData] = useState(null);
  const [stuffKindData, setStuffKindData] = useState(null);
  const [otpForm, setOtpForm] = useState({
    gToken: "",
    otp: 0,
    identityNo: null,
    cepTelefonu: null,
  });
  const [isOtpFormValid, setIsOtpFormValid] = useState({
    otp: { status: true, message: "Doğrulama Kodu (OTP): Zorunludur." },
  });
  const [showOtpForm, setShowOtpForm] = useState(false);

  const GetStuffType = async () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "stuff/StuffType",
        "post",
        "",
        { gToken: gToken },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data.data).forEach((x) => {
          _tempData.push({ value: x.id, text: x.adi });
        });
        setStuffTypeData(_tempData);
        setIsStaffForm(true);
      }
    });
  };

  const GetStuffKind = async (stuffKind) => {
    if (stuffKind === "" || isNaN(stuffKind)) return;
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "stuff/StuffKind",
        "post",
        "",
        { gToken: gToken, esyaTipi: stuffKind },
      );
      if (response.ok) {
        const _tempData = [];

        Array.from(response.data.data.esyaTurleri).forEach((x) => {
          _tempData.push({ value: x.id, text: x.adi });
        });
        setStuffKindData(_tempData);
      }
    });
  };

  const StartFormHandler = async (event) => {
    if (event != undefined) event.preventDefault();

    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    if (formInput.basvuruTipi === 1) {
      appContext.GetGRecapToken().then(async (gToken) => {
        formInput.gToken = gToken;
        const checkIdentity = await appContext.CheckTCWithBD({
          gToken: gToken,
          tcKimlikNo: formInput.tc,
          dogumTarihi: formInput.dogumTarihi,
        });
        if (checkIdentity.ok) {
          await CreateOtpHandler();
        }
      });
    }
    if (formInput.basvuruTipi === 2) {
      await CreateOtpHandler();
    }
  };

  const SubmitHandler = async (event) => {
    event.preventDefault();

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "stuff/save",
        "post",
        "",
        formInput,
      );

      if (response.ok) {
        Swal.fire({
          title: "İşlem Başarılı",
          text: "Başvuru süreciniz başarı ile tamamlanmıştır.",
          icon: "success",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#34A853",
          confirmButtonText: "Tamam",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            return;
          }
        });
      }
    });
  };

  const CreateOtpHandler = async () => {
    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    appContext.GetGRecapToken().then(async (gToken) => {
      const response = await appContext.FetchHandler(
        "SmsVerification/create",
        "post",
        "",
        {
          identityNo:
            formInput.basvuruTipi === 1 ? formInput.tc : formInput.vergiNo,
          cepTelefonu: formInput.cepTelefonu,
          supportId: state.id,
          gToken: gToken,
        },
      );
      if (response.ok) {
        SwalInfoMessage(response.data);
        setShowOtpForm(true);
      }
    });
  };

  const VerifyOtpHandler = async () => {
    const isValid = CheckFormValid(isOtpFormValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    appContext.GetGRecapToken().then(async (gToken) => {
      otpForm.gToken = gToken;
      otpForm.identityNo =
        formInput.basvuruTipi === 1 ? formInput.tc : formInput.vergiNo;
      otpForm.cepTelefonu = formInput.cepTelefonu;
      otpForm.supportId = state.id;
      const response = await appContext.FetchHandler(
        "SmsVerification/Verify",
        "post",
        "",
        otpForm,
      );
      if (response.ok) {
        SwalInfoMessage(response.data);
        setShowOtpForm(false);
        await GetStuffType();
      }
    });
  };

  return (
    <Fragment>
      <section className={classes.section}>
        {showOtpForm ? (
          <Fragment>
            <ContentHead
              data={{
                imageUrl: state.imageUrl,
                name: "SMS Doğrulama",
                shortDescription:
                  "Başvurunuza devam edebilmek için telefonunuza gönderilen kodu giriniz.",
              }}
            />
            <OtpForm
              formInput={otpForm}
              setFormInput={setOtpForm}
              formInputIsValid={isOtpFormValid}
              setFormInputIsValid={setIsOtpFormValid}
              submitHandler={VerifyOtpHandler}
              createOtpHandler={CreateOtpHandler}
            />
          </Fragment>
        ) : (
          <ContentHead data={state} formTitle />
        )}

        {!showOtpForm && !isStaffForm ? (
          <StuffStartForm
            formInputJson={formInputJson}
            formInput={formInput}
            setFormInput={setFormInput}
            formInputIsValid={formInputIsValid}
            setFormInputIsValid={setFormInputIsValid}
            submitHandler={StartFormHandler}
          />
        ) : (
          !showOtpForm && (
            <div className={classes.stuff}>
              <StuffForm
                formInput={formInput}
                setFormInput={setFormInput}
                submitHandler={SubmitHandler}
                stuffTypeData={stuffTypeData}
                stuffKindData={stuffKindData}
                GetStuffKind={GetStuffKind}
              />
            </div>
          )
        )}
      </section>
    </Fragment>
  );
};
