import React, { Fragment } from "react";
import classes from "./IncomeStatusForm.module.css";
import { Input } from "../../ui/input/Input";

export const IncomeStatusForm = ({
  formInput,
  setFormInput,
  submitHandler,
}) => {
  const YesNo = [
    {
      value: 0,
      text: "Hayır",
    },
    {
      value: 1,
      text: "Evet",
    },
  ];
  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <div className={classes.group}>
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Aile Geliri"
            input={{
              id: "aileGeliri",
              type: "number",
              placeholder: "Aile Geliri",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  aileGeliri: event.target.value,
                }));
              },
            }}
          />
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Ailedeki Kişi Sayısı"
            input={{
              id: "haneToplamKisi",
              type: "number",
              placeholder: "Ailedeki Kişi Sayısı",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  haneToplamKisi: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            type="input"
            label="Öğrenci Çocuk Sayısı"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            input={{
              id: "aileOgrenciCocukSayisi",
              type: "number",
              placeholder: "Öğrenci Çocuk Sayısı",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  aileOgrenciCocukSayisi: event.target.value,
                }));
              },
            }}
          />
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Öğrenci Olmayan Çocuk Sayısı"
            input={{
              id: "aileOgrenciOlmayanCocukSayisi",
              type: "number",
              placeholder: "Öğrenci Olmayan Çocuk Sayısı",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  aileOgrenciOlmayanCocukSayisi: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Ailedeki Engelli Sayısı"
            input={{
              id: "aileEngelliSayisi",
              type: "number",
              placeholder: "Ailedeki Engelli Sayısı",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  aileEngelliSayisi: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Sosyal Güvenceniz var mı?"
            type="select"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "sosyalGuvenceVarmi",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  sosyalGuvenceVarmi:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            label="Aracınız var mı?"
            type="select"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "aracSahibiMi",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  aracSahibiMi:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
          <Input
            label="Arazi Tapunuz var mı?"
            type="select"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "araziTapusuVarMi",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  araziTapusuVarMi:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            label="Kira Ödüyor Musunuz?"
            type="select"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "konutDurumu",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  konutDurumu:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
          <Input
            label="Konut Tapunuz var mı?"
            type="select"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "konutTapusuVarMi",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  konutTapusuVarMi:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Var ise Mesleğiniz"
            input={{
              id: "meslek",
              type: "text",
              placeholder: "Var ise Mesleğiniz",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  meslek: event.target.value,
                }));
              },
            }}
          />
          <Input
            type="input"
            label="Var ise Çalıştığınız Kurum"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            input={{
              id: "calisilanKurum",
              type: "text",
              placeholder: "Var ise Çalıştığınız Kurum",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  calisilanKurum: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            label="İş Yeri Tapunuz var mı?"
            type="select"
            style={{ width: "50%", color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "isYeriTapusuVarMi",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  isYeriTapusuVarMi:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
          <Input
            label="Başka Kurumdan Destek Alıyor musunuz?"
            type="select"
            style={{ color: "#1B437E", fontWeight: "600" }}
            option={YesNo}
            input={{
              id: "baskaKurumdanDestekAliyorMu",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  baskaKurumdanDestekAliyorMu:
                    parseInt(event.target.value) === 1 ? true : false,
                }));
              },
            }}
          />
          {formInput.baskaKurumdanDestekAliyorMu && (
            <Input
              type="input"
              label="Başka Kurumdan Alınan Destek Tutarı"
              style={{ color: "#1B437E", fontWeight: "600" }}
              input={{
                id: "baskaKurumDestekTutari",
                type: "text",
                placeholder: "Başka Kurumdan Alınan Destek Tutarı",
                required: "required",
                onChange: (event) => {
                  setFormInput((prev) => ({
                    ...prev,
                    baskaKurumDestekTutari: event.target.value,
                  }));
                },
              }}
            />
          )}
        </div>
        <div className={classes.formButton}>
          <button>BAŞVUR</button>
        </div>
      </form>
    </Fragment>
  );
};
