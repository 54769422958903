import React, { Fragment, useContext, useState } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { LoginFormAdmin } from "../../../components/admin/loginForm/LoginFormAdmin";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { Loader } from "../../../components/ui/common/Loader";
import { AppContext } from "../../../context/app-context";
import { AdminFooter } from "../footer/AdminFooter";
import { AdminHeader } from "../header/AdminHeader";
import { AdminMain } from "../main/AdminMain";
import { SidebarAdmin } from "../sidebar/SidebarAdmin";
import classes from "./AdminLayout.module.css";
import "./AdminL.css";
import { ForgotPasswordForm } from "../../../components/admin/forgotPasswordForm/ForgotPasswordForm";
import { SwalSuccessMessage } from "../../../util/swalUtil";
import Swal from "sweetalert2";

export const AdminLayout = (props) => {
  const appContext = useContext(AppContext);
  const [loginFormInput, setLoginFormInput] = useState(null);
  const [showText, setShowText] = useState(true);
  const [formType, setFormType] = useState(0);

  const ForgotHandler = async () => {
    const response = await appContext.FetchHandler(
      "Auth/ForgotPassword",
      "post",
      null,
      loginFormInput,
    );
    if (response.ok) {
      Swal.fire({
        title: "İşlem Başarılı",
        text: response.data,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tamam",
        cancelButtonText: "Hayır",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setFormType(0);
        }
      });
    }
  };

  return (
    <Fragment>
      {appContext.isLoggedIn ? (
        <ProSidebarProvider>
          <div className={classes.layout}>
            {appContext.loader && <Loader />}
            <SidebarAdmin showText={showText} />
            <div className={classes.right}>
              <AdminHeader setShowText={setShowText} showText={showText} />
              <AdminMain />
            </div>
            <AdminFooter />
          </div>
        </ProSidebarProvider>
      ) : (
        <div className={classes.loginPage}>
          <div className={classes.loginContainer}>
            <h2>{formType === 0 ? "Giriş Yap" : "Şifremi Unuttum"}</h2>
            {formType === 0 ? (
              <LoginFormAdmin
                loginFormInput={loginFormInput}
                setLoginFormInput={setLoginFormInput}
                submitHandler={appContext.LoginHandler}
                setFormType={setFormType}
              />
            ) : (
              <ForgotPasswordForm
                formInput={loginFormInput}
                setFormInput={setLoginFormInput}
                submitHandler={ForgotHandler}
                setFormType={setFormType}
              />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};
