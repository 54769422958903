import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./OrderProduct.module.css";
import { MinMaxDate } from "../../../components/ui/input/MinMaxDate";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { SwalErrorMessage } from "../../../util/swalUtil";
import moment from "moment";
import { AppContext } from "../../../context/app-context";
import { ConvertCurrency, OrderStatusHtml } from "../../../util/commonUtil";

export const OrderProduct = () => {
  const appContext = useContext(AppContext);
  const date = new Date();
  const [formInput, setFormInput] = useState({
    minDate: moment(date).format("YYYY-MM-DD"),
    maxDate: moment(date).format("YYYY-MM-DD"),
  });
  const [dataList, setDataList] = useState();
  const [excelButtonStatus, setExcelButtonStatus] = useState(true);
  const columns = [
    {
      name: "Durum",
      selector: (row) => OrderStatusHtml(row.orderStatus),
      // sortable: true,
      width: "150px",
    },
    {
      name: "Sipariş Id",
      selector: (row) => row.orderId,
      // sortable: true,
      width: "300px",
    },
    {
      name: "Sipariş Tarihi",
      cell: (row) => {
        return moment(row.createdDate).format("DD/MM/YYYY");
      },
      width: "110px",
    },
    {
      name: "Ürün",
      cell: (row) => {
        return `${row.productName} - ${row.productVariantName}`;
      },
      width: "250px",
    },
    {
      name: "Adet",
      cell: (row) => {
        return row.quantity;
      },
      width: "110px",
    },
    {
      name: " Fiyat",
      cell: (row) => {
        return ConvertCurrency(row.price);
      },
      width: "150px",
    },
    {
      name: "Toplam Fiyat",
      cell: (row) => {
        return ConvertCurrency(row.totalAmount);
      },
      width: "150px",
    },
    {
      name: "Vergi",
      cell: (row) => {
        return `%${row.vatPercent}`;
      },
      width: "150px",
    },
    {
      name: "Kullanıcı Tip",
      cell: (row) => {
        switch (row.userType) {
          case 0:
            return "Bireysel";
          case 1:
            return "Kurumsal";
          default:
            break;
        }
      },
      width: "110px",
    },
    {
      name: "Ad Soyad",
      cell: (row) => {
        return row.fullName;
      },
      width: "180px",
    },
    {
      name: "Email",
      cell: (row) => {
        return row.email;
      },
      width: "180px",
    },
    {
      name: "Telefon",
      cell: (row) => {
        return row.phoneNumber;
      },
      width: "180px",
    },
    {
      name: "Ünvan",
      cell: (row) => {
        return row.title;
      },
      width: "180px",
    },
    {
      name: "Vergi No",
      cell: (row) => {
        return row.taxNo;
      },
      width: "140px",
    },
    {
      name: "Fatura Adres",
      cell: (row) => {
        return row.invoiceAddress;
      },
      width: "220px",
    },
  ];

  const GetData = async (event) => {
    if (event != undefined) event.preventDefault();

    const formData = new FormData();
    formData.append(
      "minDate",
      moment(formInput.minDate).format("DD/MM/YYYY HH:mm")
    );
    formData.append(
      "maxDate",
      moment(formInput.maxDate).format("DD/MM/YYYY HH:mm")
    );

    const response = await appContext.FetchHandler(
      "order/productOrder",
      "post",
      "formData",
      formData
    );

    if (response.ok) {
      setDataList(response.data);
    }
  };

  useEffect(() => {
    GetData();
  }, []);


  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3> Ürün Satışları</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btnCreate"*/}
        {/*  onClick={() => {*/}
        {/*    dataList*/}
        {/*      ? GetExcel()*/}
        {/*      : SwalErrorMessage("Aktarılacak veri bulunamadı");*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Excele Aktar*/}
        {/*</button>*/}
      </div>

      <div>
        <MinMaxDate
          formInput={formInput}
          setFormInput={setFormInput}
          submitHandler={GetData}
          buttonStatus={excelButtonStatus}
        />
      </div>
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};

export class OrderAdmin {
}