import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./Izsu.module.css";
import { ContentHead } from "../../../components/client/contentHead/ContentHead";
import { AppContext } from "../../../context/app-context";
import { useLocation } from "react-router-dom";
import { SupportGiveTakeTab } from "../../../components/client/supportGiveTakeTab/SupportGiveTakeTab";
import { AskidaStats } from "../askidaStats/AskidaStats";
import { ConvertCurrency } from "../../../util/commonUtil";
import { IzsuApplyForm } from "../../../components/client/izsuApplyForm/IzsuApplyForm";
import { SwalErrorMessage, SwalSuccessMessage } from "../../../util/swalUtil";
import { IncomeStatusForm } from "../../../components/client/incomeStatusForm/IncomeStatusForm";
import Swal from "sweetalert2";
import {
  IzsuFaturaList,
  IzsuInvoiceList,
} from "../../../components/client/izsuInvoiceList/IzsuInvoiceList";

export const Izsu = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const [supportTab, setSupportTab] = useState(state.isGiveSupport ? 1 : 0);

  const data = {
    name: "Fatura",
    waiting: 2713,
    done: 1674,
    amount: ConvertCurrency(16945.2),
  };

  const [formInput, setFormInput] = useState({
    gToken: "",
    izsuStatus: 0,
    baskaKurumdanDestekAliyorMu: false,
  });

  const [incomeForm, setIncomeForm] = useState(false);
  const [invoiceList, setİnvoiceList] = useState();
  const [filter, setFilter] = useState({
    min: 1,
    max: 999999999999,
  });

  useEffect(() => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "izsu/IzsuInvoiceList",
        "post",
        null,
        filter
      );

      if (response.ok) {
        setİnvoiceList(response.data);
      }
    });
  }, [filter]);

  const SubmitForm = async () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "izsu",
        "post",
        null,
        formInput
      );

      if (response.ok) {
        if (response.statusCode === 202) {
          setFormInput((prev) => ({
            ...prev,
            izsuStatus: 1,
          }));
          SwalSuccessMessage(response.data);
          setIncomeForm(true);
        } else {
          Swal.fire({
            title: "İşlem Başarılı",
            text:
              response.data != null || response.data.length > 0
                ? response.data
                : "Başvuru süreciniz başarı ile tamamlanmıştır.",
            icon: "success",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#34A853",
            confirmButtonText: "Tamam",
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.replace("webApply");
            } else {
              return;
            }
          });
        }
      }
    });
  };

  const SubmitHandler = async (event) => {
    typeof event !== "undefined" && event.preventDefault();
    if (
      formInput.phoneNumber == undefined ||
      formInput.phoneNumber.length < 12
    ) {
      SwalErrorMessage("Telefon numarasını eksik veya hatalı girdiniz.");
      return;
    }
    SubmitForm();
  };

  return (
    <Fragment>
      <section className={classes.mainSection}>
        <ContentHead data={state} />
        <SupportGiveTakeTab
          supportTab={supportTab}
          setSupportTab={setSupportTab}
        />
        <AskidaStats data={data} />
        {supportTab === 0 ? (
          !incomeForm ? (
            <IzsuApplyForm
              formInput={formInput}
              setFormInput={setFormInput}
              submitHandler={SubmitHandler}
            />
          ) : (
            <IncomeStatusForm
              formInput={formInput}
              setFormInput={setFormInput}
              submitHandler={SubmitHandler}
            />
          )
        ) : (
          <IzsuInvoiceList
            invoiceList={invoiceList}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </section>
    </Fragment>
  );
};
