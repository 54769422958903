import React, { Fragment, useContext, useEffect } from "react";
import { Loader } from "../../../components/ui/common/Loader";
import { AppContext } from "../../../context/app-context";
import { Header } from "../header/Header";
import { Main } from "../main/Main";
import classes from "./Layout.module.css";
import { Footer } from "../footer/Footer";
import { ProSidebarProvider } from "react-pro-sidebar";
import { SidebarClient } from "../sidebar/SidebarClient";
import { PopupModal } from "../../../components/client/popupModal/PopupModal";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-router-dom";

export const Layout = () => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    const asd = appContext.announcementData.sort((a, b) => a.index - b.index);
  }, [appContext.announcementData]);
  return (
    <Fragment>
      <ProSidebarProvider>
        {appContext.isMobile && <SidebarClient />}
        {appContext.announcementData != null &&
          appContext.announcementData.length > 0 &&
          appContext.announcementData
            .sort((a, b) => a.index - b.index)
            .reverse()
            .map((x, i) => {
              return (
                <PopupModal
                  showModal={appContext.showAnnouncment[i]}
                  setShowModal={appContext.setShowAnnouncment}
                  title={x.name && x.name}
                  key={i}
                  index={i}
                  data={
                    <>
                      {x.longDescription && HTMLReactParser(x.longDescription)}
                      <Link
                        style={{ marginTop: "2rem" }}
                        to={x.shortDescription}
                        target="_self"
                        onClick={() =>
                          appContext.setShowAnnouncment((prev) => ({
                            ...prev,
                            [i]: false,
                          }))
                        }
                      >
                        <img
                          src={x.imageUrl}
                          title=""
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "contain",
                          }}
                        />
                      </Link>
                    </>
                  }
                ></PopupModal>
              );
            })}
        <div className={classes.layout} id="layout">
          {appContext.loader && <Loader />}
          <Header />
          <Main />
        </div>
      </ProSidebarProvider>
    </Fragment>
  );
};
