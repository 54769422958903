import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./WebApplyFileUpload.module.css";
import { ConvertFileBase64 } from "../../../util/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileArchive,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";

import { AppContext } from "../../../context/app-context";
import { Input } from "../../ui/input/Input";

export const WebApplyFileUpload = ({
  data,
  formInput,
  setFormInput,
  submitHandler,
  ozelAlanlarData,
}) => {
  const appContext = useContext(AppContext);
  const [districtCodesData, setDistrictCodesData] = useState();
  const [alinacakNeighbourhoodCodes, setAlinacakNeighbourhoodCodes] =
    useState();
  const [tasinacakNeighbourhoodCodes, setTasinacakNeighbourhoodCodes] =
    useState();
  const [alinacakStreetCodes, setAlinacakStreetCodes] = useState();
  const [tasinacakStreetCodes, setTasinacakStreetCodes] = useState();
  const [showNakliyeAddressForm, setShowNakliyeAddressForm] = useState(false);

  // if(response.data.supportId === process.env.REACT_APP_NAKLIYE_RANDEVU_ID){
  //   setShowNakliyeAddressForm(true);
  // }

  useEffect(() => {
    if (formInput.supportId === process.env.REACT_APP_NAKLIYE_RANDEVU_ID) {
      setShowNakliyeAddressForm(true);
    }
  }, []);

  useEffect(() => {
    if (showNakliyeAddressForm) {
      DistrictHandler(35);
    }
  }, [showNakliyeAddressForm, appContext.gRecapReady]);

  useEffect(() => {}, [formInput]);

  const DistrictHandler = (cityId) => {
    if (cityId === "" || cityId == 0 || isNaN(cityId)) {
      setDistrictCodesData([]);
      setAlinacakNeighbourhoodCodes([]);
      setTasinacakNeighbourhoodCodes([]);
      setAlinacakStreetCodes([]);
      setTasinacakStreetCodes([]);
      return;
    }
    setFormInput((prev) => ({
      ...prev,
      esyaAlinacakAdres: {
        ilce: "",
        ilceId: 0,
        mahalle: "",
        mahalleId: 0,
        yol: "",
        yolId: 0,
      },
      esyaTasinacakAdres: {
        ilce: "",
        ilceId: 0,
        mahalle: "",
        mahalleId: 0,
        yol: "",
        yolId: 0,
      },
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/DistrictCodes",
        "post",
        "",
        { gToken: gToken, id: cityId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setDistrictCodesData(_tempData);
      }
    });
  };
  const NeighbourhoodHandler = (districtId, type) => {
    if (districtId === "" || districtId === 0 || isNaN(districtId)) {
      switch (type) {
        case "alinacak":
          setAlinacakNeighbourhoodCodes([]);
          setAlinacakStreetCodes([]);
          break;
        case "tasinacak":
          setTasinacakNeighbourhoodCodes([]);
          setTasinacakStreetCodes([]);
          break;
        default:
          break;
      }

      return;
    }

    switch (type) {
      case "alinacak":
        setFormInput((prev) => ({
          ...prev,
          esyaAlinacakAdres: {
            ...prev.esyaAlinacakAdres,
            mahalleId: 0,
            yolId: 0,
          },
        }));
        break;
      case "tasinacak":
        setFormInput((prev) => ({
          ...prev,
          esyaTasinacakAdres: {
            ...prev.esyaAlinacakAdres,
            mahalleId: 0,
            yolId: 0,
          },
        }));
        break;
      default:
        break;
    }

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/NeighborhoodCodes",
        "post",
        "",
        { gToken: gToken, id: parseInt(districtId) },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        type === "alinacak"
          ? setAlinacakNeighbourhoodCodes(_tempData)
          : setTasinacakNeighbourhoodCodes(_tempData);
      }
    });
  };

  const StreetHandler = (nbhId, type) => {
    if (nbhId === "" || nbhId == 0 || isNaN(nbhId)) {
      switch (type) {
        case "alinacak":
          setAlinacakStreetCodes([]);
          break;
        case "tasinacak":
          setTasinacakStreetCodes([]);
          break;
        default:
          break;
      }
      return;
    }

    switch (type) {
      case "alinacak":
        setFormInput((prev) => ({
          ...prev,
          esyaAlinacakAdres: {
            ...prev.esyaAlinacakAdres,
            yolId: 0,
          },
        }));
        break;
      case "tasinacak":
        setFormInput((prev) => ({
          ...prev,
          esyaTasinacakAdres: {
            ...prev.esyaTasinacakAdres,
            yolId: 0,
          },
        }));
        break;
      default:
        break;
    }

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/streetCodes",
        "post",
        "",
        {
          gToken: gToken,
          id:
            type === "alinacak"
              ? formInput.esyaAlinacakAdres.ilceId
              : formInput.esyaTasinacakAdres.ilceId,
          nbhId: nbhId,
        },
      );

      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });

        type === "alinacak"
          ? setAlinacakStreetCodes(_tempData)
          : setTasinacakStreetCodes(_tempData);
      }
    });
  };

  const RemoveFile = (event) => {
    event.preventDefault();

    const filtered = Array.from(formInput.ekliDokumanlar).filter(
      (x) => x.dosyaAdi !== event.target.id,
    );

    setFormInput((prev) => ({
      ...prev,
      ekliDokumanlar: filtered,
    }));
  };
  const FileHandler = (event) => {
    const file = event.target.files[0];

    ConvertFileBase64(file).then((resolve) => {
      setFormInput((prev) => ({
        ...prev,
        ekliDokumanlar: [
          ...prev.ekliDokumanlar,
          {
            kurumOnayiId: event.target.id,
            dosya: resolve.base64,
            dosyaAdi: file.name,
            dosyaUzantisi: file.type,
            aciklama: file.name,
          },
        ],
      }));
    });
  };

  useEffect(() => {}, [formInput]);

  return (
    <Fragment>
      {Array.from(data).map((x, i) => {
        return (
          <div className={classes.fileContainer} key={i}>
            <label htmlFor={x.id} className={classes.fileBox}>
              <img src="/assets/icons/imgAdd.png" alt="icon" />
              <h5>{x.adi}</h5>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {formInput.ekliDokumanlar.length > 0 &&
                  Array.from(formInput.ekliDokumanlar).map((y, j) => {
                    if (x.id === y.kurumOnayiId)
                      return (
                        <div
                          className={classes.fileListBox}
                          title="Kaldır"
                          key={j}
                          id={y.dosyaAdi}
                          onClick={(event) => RemoveFile(event)}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              pointerEvents: "none",
                            }}
                          >
                            <FontAwesomeIcon
                              className={classes.fileListIcon}
                              icon={faFile}
                            />
                            <p>{y.dosyaAdi}</p>
                          </div>

                          <FontAwesomeIcon
                            className={classes.removeIcon}
                            icon={faRemove}
                          />
                        </div>
                      );
                  })}
              </div>
            </label>
            <input
              id={x.id}
              name={x.id}
              type="file"
              className={classes.customFile}
              required
              onChange={(event) => FileHandler(event)}
            />
          </div>
        );
      })}
      {showNakliyeAddressForm && (
        <Fragment>
          <div id="EsyaAlinacakAdres" className={classes.esyaAlinacakAdres}>
            <h2>Eşya Alınacak Adres</h2>
            <div className={classes.group}>
              <Input
                label="İlçe"
                type="select"
                style={{ width: "100%" }}
                option={districtCodesData}
                input={{
                  id: "ilceId",
                  required: "required",
                  value: formInput.esyaAlinacakAdres.ilce_ID,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    NeighbourhoodHandler(
                      parseInt(event.target.value),
                      "alinacak",
                    );
                    setFormInput((prev) => ({
                      ...prev,
                      esyaAlinacakAdres: {
                        ...prev.esyaAlinacakAdres,
                        ilce: event.target[index].text,
                        ilceId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                label="Mahalle"
                type="select"
                style={{ width: "100%" }}
                option={alinacakNeighbourhoodCodes}
                input={{
                  id: "mahalleId",
                  required: "required",
                  value: formInput.esyaAlinacakAdres.mahalleId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    StreetHandler(parseInt(event.target.value), "alinacak");
                    setFormInput((prev) => ({
                      ...prev,
                      esyaAlinacakAdres: {
                        ...prev.esyaAlinacakAdres,
                        mahalle: event.target[index].text,
                        mahalleId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
            </div>
            <div className={classes.group}>
              <Input
                label="Sokak"
                type="select"
                style={{ width: "100%" }}
                option={alinacakStreetCodes}
                input={{
                  id: "yolId",
                  required: "required",
                  value: formInput.esyaAlinacakAdres.yolId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaAlinacakAdres: {
                        ...prev.esyaAlinacakAdres,
                        yol: event.target[index].text,
                        yolId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Bina No"
                input={{
                  id: "adisKapiNo",
                  required: "required",
                  value: formInput.esyaAlinacakAdres.disKapiNo,
                  placeholder: "Bina No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaAlinacakAdres: {
                        ...prev.esyaAlinacakAdres,
                        disKapiNo: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Daire No"
                input={{
                  id: "aicKapiNo",
                  required: "required",
                  value: formInput.esyaAlinacakAdres.icKapiNo,
                  placeholder: "Bina No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaAlinacakAdres: {
                        ...prev.esyaAlinacakAdres,
                        icKapiNo: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
            </div>
          </div>
          <div id="EsyaTasinacakAdres" className={classes.esyaAlinacakAdres}>
            <h2>Eşya Taşınacak Adres</h2>
            <div className={classes.group}>
              <Input
                label="İlçe"
                type="select"
                style={{ width: "100%" }}
                option={districtCodesData}
                input={{
                  id: "ilceId",
                  required: "required",
                  value: formInput.esyaTasinacakAdres.ilceId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    NeighbourhoodHandler(
                      parseInt(event.target.value),
                      "tasinacak",
                    );
                    setFormInput((prev) => ({
                      ...prev,
                      esyaTasinacakAdres: {
                        ...prev.esyaTasinacakAdres,
                        ilce: event.target[index].text,
                        ilceId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                label="Mahalle"
                type="select"
                style={{ width: "100%" }}
                option={tasinacakNeighbourhoodCodes}
                input={{
                  id: "mahalleId",
                  required: "required",
                  value: formInput.esyaTasinacakAdres.mahalleId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    StreetHandler(parseInt(event.target.value), "tasinacak");
                    setFormInput((prev) => ({
                      ...prev,
                      esyaTasinacakAdres: {
                        ...prev.esyaTasinacakAdres,
                        mahalle: event.target[index].text,
                        mahalleId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
            </div>
            <div className={classes.group}>
              <Input
                label="Sokak"
                type="select"
                style={{ width: "100%" }}
                option={tasinacakStreetCodes}
                input={{
                  id: "yolId",
                  required: "required",
                  value: formInput.esyaTasinacakAdres.yolId,
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaTasinacakAdres: {
                        ...prev.esyaTasinacakAdres,
                        yol: event.target[index].text,
                        yolId: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Bina No"
                input={{
                  id: "disKapiNo",
                  required: "required",
                  value: formInput.esyaTasinacakAdres.disKapiNo,
                  placeholder: "Bina No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaTasinacakAdres: {
                        ...prev.esyaTasinacakAdres,
                        disKapiNo: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
                label="Daire No"
                input={{
                  id: "icKapiNo",
                  required: "required",
                  value: formInput.esyaTasinacakAdres.icKapiNo,
                  placeholder: "Bina No",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      esyaTasinacakAdres: {
                        ...prev.esyaTasinacakAdres,
                        icKapiNo: event.target.value.toString(),
                      },
                    }));
                  },
                }}
              />
            </div>
          </div>
        </Fragment>
      )}
      {!ozelAlanlarData && (
        <button
          type="button"
          className={classes.saveButton}
          onClick={() => submitHandler()}
        >
          BAŞVUR
        </button>
      )}
    </Fragment>
  );
};
