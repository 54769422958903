import React, { Fragment, useContext } from "react";
import { AppContext } from "../../../context/app-context";
import classes from "./Header.module.css";
import { Link, NavLink } from "react-router-dom";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useProSidebar } from "react-pro-sidebar";

export const Header = () => {
  const appContext = useContext(AppContext);
  const { collapseSidebar, toggleSidebar } = useProSidebar();

  const CollapsHandler = () => {
    collapseSidebar();
    const contentBoxs = document.querySelectorAll(".menuItemContentBox");
    for (const item of contentBoxs) {
      item.style.justifyContent = "center";
    }
  };

  let activeStyle = {
    textDecoration: "underline",
    color: "#07417e",
  };

  // const SupportLinkHandler = () => {
  //   return appContext.supportData.map((x, i) => {
  //     return (
  //       <Fragment key={i}>
  //         <MenuItem>
  //           <NavLink
  //             to={x.supportType == 6 ? x.outUrl : x.url}
  //             target={x.supportType == 6 ? "_blank" : "_self"}
  //             style={({ isActive }) => (isActive ? activeStyle : undefined)}
  //             state={x}
  //           >
  //             {x.name}
  //           </NavLink>
  //         </MenuItem>
  //       </Fragment>
  //     );
  //   });
  // };

  return (
    <Fragment>
      <header className={classes.header} id="header">
        <main className={classes.mainMobile}>
          <div className={classes.mobileLogoBox}>
            <Link className={classes.mobileLogo} to="/">
              <img src="/assets/img/logoNew.png" alt="logo" />
            </Link>
          </div>

          <button
            className="sb-button"
            onClick={() => toggleSidebar()}
            style={{ color: "#0f476d" }}
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: "1.5rem" }} />
          </button>
        </main>
        <main className={classes.mainDesk}>
          {/*<Link*/}
          {/*  className={classes.ibbLogoBox}*/}
          {/*  to="https://www.izmir.bel.tr/"*/}
          {/*  target={"_blank"}*/}
          {/*>*/}
          {/*  <img src="/assets/img/ibbLogo.svg" alt="logo" />*/}
          {/*</Link>*/}
          <Link className={classes.logoBox} to="/">
            <img src="/assets/img/logoNew.png" alt="logo" />
          </Link>
          <nav>
            <ul>
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to="/sutKuzusu"*/}
              {/*    style={({ isActive }) => (isActive ? activeStyle : undefined)}*/}
              {/*    state={*/}
              {/*      appContext.supportData &&*/}
              {/*      appContext.supportData.filter(*/}
              {/*        (x) => x.id === process.env.REACT_APP_SUT_KUZUSU_ID,*/}
              {/*      )[0]*/}
              {/*    }*/}
              {/*  >*/}
              {/*    SÜT KUZUSU*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to="/randevuHizmetleri"*/}
              {/*    style={({ isActive }) => (isActive ? activeStyle : undefined)}*/}
              {/*  >*/}
              {/*    RANDEVU HİZMETLERİ*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to="/destekVer"*/}
              {/*    style={({ isActive }) => (isActive ? activeStyle : undefined)}*/}
              {/*  >*/}
              {/*    DESTEK VER*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li>
                <NavLink
                  to="/basvuruSorgula"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  BAŞVURU SORGULA
                </NavLink>
              </li>
              <li>
                <Menu
                  menuButton={
                    <MenuButton>
                      İZMİR ŞEHİR KARTI
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{
                          fontSize: ".8rem",
                          marginLeft: ".4rem",
                        }}
                      />
                    </MenuButton>
                  }
                  transition
                  menuStyle={{ flexDirection: "column" }}
                >
                  <MenuItem>
                    <NavLink
                      to="https://servisler.bizimpos.com/izmir_uye_isyerleri"
                      target="_blank"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      Alışveriş Noktaları
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      to="https://www.izmirteknoloji.com.tr/tr/uye-isyeri"
                      target="_blank"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      Üye İş Yeri Başvurusu
                    </NavLink>
                  </MenuItem>
                </Menu>
              </li>
              <li>
                <NavLink
                  to="/hizmetlerimiz"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  HİZMETLERİMİZ
                </NavLink>
              </li>

              {/* <li>
                <Menu
                  menuButton={
                    <MenuButton>
                      SOSYAL DESTEKLER
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{
                          fontSize: ".8rem",
                          marginLeft: ".4rem",
                        }}
                      />
                    </MenuButton>
                  }
                  transition
                  menuStyle={{ flexDirection: "column" }}
                >
                  {appContext.supportData && SupportLinkHandler()}
                </Menu>
              </li> */}
              <li>
                <NavLink
                  to="/sss"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  S.S.S
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/iletisim"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  İLETİŞİM
                </NavLink>
              </li>
            </ul>
          </nav>
        </main>
      </header>
    </Fragment>
  );
};
