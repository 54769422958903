import React, { Fragment, useEffect } from "react";
import classes from "./ContentHead.module.css";
import HTMLReactParser from "html-react-parser";

export const ContentHead = ({ data, formTitle }) => {
  useEffect(() => {
    const layout = document.getElementById("layout");
    layout.style.backgroundColor = "#ffff";
  }, []);

  return (
    <Fragment>
      {data && (
        <div className={classes.contentHead}>
          <img
            src={data.imageUrl ? data.imageUrl : "/assets/img/logo.png"}
            alt=""
          />
          <h1>{data.name && data.name}</h1>
          <p>{data.shortDescription && data.shortDescription}</p>
          {data.longDescription && HTMLReactParser(data.longDescription)}
          {formTitle && <h3>Başvuru Formu</h3>}
        </div>
      )}
    </Fragment>
  );
};
