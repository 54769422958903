import React from "react";
import { Input } from "../../ui/input/Input";
import { EntityStatusData, SupportTypeData } from "../../../util/commonUtil";
import { Fragment } from "react";

export const SupportCreateForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  const dataStatus = [
    {
      value: 0,
      text: "Aktif",
    },
    {
      value: 1,
      text: "Pasif",
    },
    // {
    //   value: 2,
    //   text: "Sil",
    // },
    {
      value: 3,
      text: "Sadece Başvuru Yapılabilir",
    },
    {
      value: 4,
      text: "Sadece Sorgulama Yapılabilir",
    },
  ];
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          {formInput != null && formInput.id != null && (
            <input id="id" name="id" type="hidden" value={formInput.id} />
          )}

          <Input
            label="Ad"
            type="input"
            input={{
              id: "name",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.name != null
                  ? formInput.name
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Destek Tipi"
            type="select"
            option={SupportTypeData()}
            input={{
              id: "supportType",
              required: "required",
              value:
                formInput != null && formInput.supportType != null
                  ? formInput.supportType
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  supportType: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        {formInput && formInput.supportType == 5 && (
          <Input
            label="Link"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "outUrl",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.outUrl != null
                  ? formInput.outUrl
                  : "",
              onChange: (event) => {
                let val = event.target.value
                  .trim()
                  .replaceAll(" ", "")
                  .replaceAll("http://", "");
                setFormInput((prev) => ({
                  ...prev,
                  outUrl: val.startsWith("https://") ? val : `https://${val}`,
                }));
              },
            }}
          />
        )}

        <div style={{ display: "flex" }}>
          <Input
            label="Sıra"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.index != null
                  ? formInput.index
                  : 999,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  index: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={dataStatus}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Görsel"
            type="input"
            style={{ width: "50%" }}
            input={{
              id: "file",
              type: "file",
              accept: "image/*",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  file: event.target.files[0],
                }));
              },
            }}
          />
        </div>
        <Input
          label="Kısa Açıklama"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "shortDescription",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.shortDescription != null
                ? formInput.shortDescription
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                shortDescription: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Açıklama"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
