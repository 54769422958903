import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./ProductDetail.module.css";
import { AppContext } from "../../../context/app-context";
import { Link, useLocation } from "react-router-dom";
import { PaymentForm } from "../../../components/client/paymentForm/PaymentForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ConvertCurrency } from "../../../util/commonUtil";
import HTMLReactParser from "html-react-parser";
import { SwalErrorMessage } from "../../../util/swalUtil";
import { ThreeDModal } from "../../../components/client/ThreeDModal/ThreeDModal";
import { CheckFormValid } from "../../../components/ui/input/Validation";

export const ProductDetail = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;

  const formInputJson = {
    paymentType: 0,
    userType: 0,
    fullName: null,
    phoneNumber: null,
    email: null,
    title: null,
    taxNo: null,
    invoiceAddress: null,
    kvkk: null,
    saleAggrement: null,
    cardNumber: "",
    expiry: "",
    cvc: "",
    holderName: "",
    focus: "",
    variantId: "",
    quantity: 1,
    price: 0,
    totalAmount: 0,
    supportType: 4,
  };
  const [formInput, setFormInput] = useState(formInputJson);
  const formInputIsValidJson = {
    fullName: {
      status: formInput.userType === 1,
      message: "Ad Soyad: Zorunludur.",
    },
    phoneNumber: { status: false, message: "Telefon Numarası: Zorunludur." },
    email: { status: false, message: "Email: Zorunludur." },
    title: { status: formInput.userType === 0, message: "Ünvan: Zorunludur." },
    taxNo: {
      status: formInput.userType === 0,
      message: "Vergi No: Zorunludur.",
    },
    invoiceAddress: {
      status: formInput.userType === 0,
      message: "Fatura Adresi: Zorunludur.",
    },
    kvkk: { status: false, message: "KVKK: Onayı zorunludur." },
    saleAggrement: {
      status: false,
      message: "Açık Rıza Metni: Onayı zorunludur.",
    },
    cardNumber: {
      status: formInput.paymentType === 1,
      message: "Kart Numarası: Zorunludur.",
    },
    expiry: {
      status: formInput.paymentType === 1,
      message: "Kart Geçerlilik Tarihi(Ay, Yıl): Zorunludur.",
    },
    cvc: { status: formInput.paymentType === 1, message: "CVC: Zorunludur." },
    holderName: {
      status: formInput.holderName != "" && formInput.holderName != null,
      message: "Kart Üzerindeki Ad Soyad",
    },
  };
  const [formInputIsValid, setFormInputIsValid] =
    useState(formInputIsValidJson);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [showThreeDModal, setShowThreeDModal] = useState(false);
  const [threedData, setThreedData] = useState(null);
  const [verId, setVerId] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    const GetProduct = async () => {
      if (state == null) window.location.replace("/");
      const response = await appContext.FetchHandler(
        "product/detail",
        "post",
        null,
        { productId: state.id },
      );

      if (response.ok) {
        setProduct(response.data);
        setSelectedVariant(response.data.variantList[0]);
        setFormInput((prev) => ({
          ...prev,
          price: response.data.variantList[0].salePrice,
          totalAmount: response.data.variantList[0].salePrice,
          variantId: response.data.variantList[0].id,
          paymentType:
            response.data.productPaymentType == 2
              ? 0
              : response.data.productPaymentType,
        }));

        if (response.data.productPaymentType == 1) {
          setFormInputIsValid((prev) => ({
            ...prev,
            cardNumber: {
              status: true,
              message: "",
            },
            expiry: {
              status: true,
              message: "",
            },
            cvc: {
              status: true,
              message: "",
            },
            holderName: {
              status: true,
              message: "",
            },
          }));
        }
      }
    };
    GetProduct();
  }, []);

  const IncrementQuantity = () => {
    setFormInput((prev) => ({
      ...prev,
      quantity:
        selectedVariant.productQuantityType === 0 &&
        selectedVariant.quantity > prev.quantity &&
        selectedVariant.maxBuyOneTime > prev.quantity
          ? prev.quantity + 1
          : selectedVariant.maxBuyOneTime > prev.quantity
            ? prev.quantity + 1
            : selectedVariant.maxBuyOneTime,

      totalAmount:
        (prev.quantity + 1 < selectedVariant.maxBuyOneTime
          ? prev.quantity + 1
          : selectedVariant.maxBuyOneTime) * selectedVariant.salePrice,
    }));
  };

  const DecrementQuantity = () => {
    setFormInput((prev) => ({
      ...prev,
      quantity: prev.quantity === 1 ? 1 : prev.quantity - 1,
      totalAmount:
        (prev.quantity === 1 ? 1 : prev.quantity - 1) *
        selectedVariant.salePrice,
    }));
  };

  useEffect(() => {
    const PaySecure = async () => {
      if (verId == null) return;
      let body = {
        holderName: formInput.holderName,
        cardNumber: formInput.cardNumber,
        expiry: formInput.expiry,
        cvc: formInput.cvc,
        verId: verId,
      };
      const response = await appContext.FetchHandler(
        "payment/paySecure",
        "post",
        null,
        body,
      );
      setShowThreeDModal(false);

      if (response.ok) {
        setPaymentSuccess(true);
        setFormInput(formInputJson);
      }
    };
    PaySecure();
  }, [verId]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin ===
            process.env.REACT_APP_API_URL.toString().replace("/api", "") &&
          event.data !== "0"
        ) {
          setVerId(event.data);
        } else {
          setShowThreeDModal(false);
        }
      },
      false,
    );
  }, []);

  const CheckValid = () => {
    if (formInput.phoneNumber.length < 12) {
      SwalErrorMessage("Telefon numarasını eksik veya hatalı girdiniz.");
      return;
    }

    let _formInput = { ...formInput };

    if (_formInput.paymentType.toString() === "1") {
      delete _formInput.cardNumber;
      delete _formInput.cvc;
      delete _formInput.expiry;
      delete _formInput.holderName;
      delete _formInput.focus;
    }
    delete _formInput.gToken;
    delete _formInput.userType;
    delete _formInput.paymentType;
    delete _formInput.quantity;

    if (formInput.userType.toString() === "0") {
      delete _formInput.title;
      delete _formInput.taxNo;
      delete _formInput.invoiceAddress;
    } else {
      delete _formInput.fullName;
    }

    for (const item in _formInput) {
      const val = _formInput[item];
      if (typeof val === "string") val.trim();

      const label = document.querySelector(`label[for='${item}']`);

      if (
        val === false ||
        val.length < 2 ||
        val === "undefined" ||
        val === null
      ) {
        SwalErrorMessage(
          `[ ${
            label != null && label.innerHTML
          } ] - Formda eksik bilgiler var lütfen kontrol ediniz.`,
        );
        return false;
      }
    }
    return true;
  };

  const SubmitHandler = async (event) => {
    event.preventDefault();

    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;

      const response = await appContext.FetchHandler(
        "payment",
        "post",
        null,
        formInput,
      );

      if (response.ok) {
        if (response.data.isThreeD) {
          setThreedData(response.data);
          setShowThreeDModal(true);
        } else {
          setFormInput(formInputJson);
          setPaymentSuccess(true);
        }
      }
    });
  };

  const PaymentResult = () => {
    return (
      <Fragment>
        <div className={classes.paymentSuccess}>
          <img src="/assets/icons/success.png" alt="" />
          <h2>İşlem Başarılı</h2>
          <div className={classes.paymentSuccessContentBox}>
            <p>
              Ödeme yapacağınız IBAN bilgileri tarafınıza SMS olarak da
              iletişmiştir.
            </p>
            <p>
              Değerli Yurttaşımız; Faturanız elektronik fatura olarak
              düzenlenerek tarafınıza e-posta ile iletilecektir.
              Saygılarımızla...
            </p>
            <p>
              Not: Yoğunluktan dolayı elektronik faturanızın gönderiminde
              gecikmeler oluşabilmektedir. Tarafınıza iletilen e-postayı gelen
              kutusunda göremiyorsanız lütfen gereksiz (spam) klasörünü kontrol
              ediniz.
            </p>
          </div>

          <Link to="/" className={classes.btnRed}>
            Anasayfa
          </Link>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {threedData != null && showThreeDModal && (
        <ThreeDModal
          showModal={showThreeDModal}
          setShowModal={setShowThreeDModal}
          data={threedData}
        />
      )}

      <section className={classes.section}>
        {paymentSuccess ? (
          PaymentResult()
        ) : (
          <Fragment>
            {product && selectedVariant && (
              <Fragment>
                <div className={classes.product}>
                  <div className={classes.productLeft}>
                    <div className={classes.productImageBox}>
                      <img src={selectedVariant.imageUrl} alt="" />
                    </div>
                  </div>
                  <div className={classes.productRight}>
                    <div className={classes.productInfo}>
                      <h3>{product.name}</h3>
                      <p>{selectedVariant.shortDescription}</p>
                      <div className={classes.productQPBox}>
                        <div
                          className={classes.productQPBoxItem}
                          style={{
                            opacity:
                              selectedVariant.productQuantityType != 0 && 0,
                          }}
                        >
                          <span>Kalan Adet:</span>
                          {selectedVariant.quantity}
                        </div>

                        <div className={classes.productQPBoxItem}>
                          <span> Fiyat: </span>
                          {ConvertCurrency(selectedVariant.salePrice)}
                        </div>
                        <div className={classes.productQPBoxItem}>
                          <span>Adet:</span>
                          <FontAwesomeIcon
                            className={classes.faIcon}
                            icon={faMinusCircle}
                            color="#1b437e83"
                            fontSize="1.4rem"
                            style={{ marginRight: "1rem" }}
                            onClick={() => DecrementQuantity()}
                          />
                          <div>{formInput.quantity}</div>
                          <FontAwesomeIcon
                            className={classes.faIcon}
                            icon={faPlusCircle}
                            color="#1b437e83"
                            fontSize="1.4rem"
                            style={{ marginLeft: "1rem" }}
                            onClick={() => IncrementQuantity()}
                          />
                        </div>
                        <div className={classes.productQPBoxItem}>
                          <span>Toplam Fiyat:</span>
                          {ConvertCurrency(formInput.totalAmount)}
                        </div>
                      </div>
                      <div className={classes.productVariant}>
                        {Array.from(product.variantList).map((x, i) => {
                          return (
                            <Fragment key={i}>
                              <button
                                className={`${
                                  selectedVariant.id == x.id &&
                                  classes.variantButtonSelected
                                }`}
                                onClick={() => {
                                  setSelectedVariant(x);
                                  setFormInput((prev) => ({
                                    ...prev,
                                    variantId: x.id,
                                    totalAmount:
                                      formInput.quantity * x.salePrice,
                                  }));
                                }}
                              >
                                {x.name}
                              </button>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.description}>
                  {selectedVariant.longDescription &&
                    HTMLReactParser(selectedVariant.longDescription)}
                </div>
              </Fragment>
            )}

            <div className={classes.payment}>
              <PaymentForm
                formInput={formInput}
                setFormInput={setFormInput}
                formInputIsValid={formInputIsValid}
                setFormInputIsValid={setFormInputIsValid}
                product={product}
                submitHandler={SubmitHandler}
                isProduct={true}
              />
            </div>
          </Fragment>
        )}
      </section>
    </Fragment>
  );
};
