import React, { Fragment } from "react";
import classes from "./IzsuApplyDetail.module.css";
import moment from "moment";
import { IzsuStatusHtml } from "../../../util/commonUtil";

export const IzsuApplyDetail = ({ data }) => {
  const GroupHandler = (title, value) => {
    return (
      <div className={classes.group}>
        <label>{title}</label>
        <span>:</span>
        <p>{value}</p>
      </div>
    );
  };

  const YesNoHandler = (value) => {
    return value ? "Evet" : "Hayır";
  };
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.left}>
          {GroupHandler(
            "Başvuru Tarihi",
            moment(data.createdDate).format("DD-MM-YYYY")
          )}
          {GroupHandler("Ad Soyad", data.fullName)}
          {GroupHandler("İzsu Abone No", data.izsuAboneNo)}
          {GroupHandler(
            "Doğum Tarihi",
            moment(data.birthDate).format("DD-MM-YYYY")
          )}
          {GroupHandler("Aile Gelir", data.aileGeliri)}
          {GroupHandler("Toplam Çocuk Sayısı", data.aileToplamCocukSayisi)}
          {GroupHandler("Engelli Sayısı", data.aileEngelliSayisi)}
          {GroupHandler(
            "Başka Kurum Destek",
            YesNoHandler(data.baskaKurumdanDestekAliyorMu)
          )}
          {GroupHandler("Çalışılan Kurum", data.calisilanKurum)}
          {GroupHandler("Araç Sahibi", YesNoHandler(data.aracSahibiMi))}
          {GroupHandler("İş Yeri Tapusu", YesNoHandler(data.isYeriTapusuVarMi))}
          {GroupHandler("Kira Ödüyor", YesNoHandler(data.konutDurumu))}
        </div>
        <div className={classes.right}>
          {GroupHandler("Durum", IzsuStatusHtml(data.izsuStatus))}
          {GroupHandler("Puan", data.score)}
          {GroupHandler("TC No", data.tcNo)}
          {GroupHandler("Telefon", data.phoneNumber)}
          {GroupHandler("Hane Kişi Sayısı", data.haneToplamKisi)}
          {GroupHandler("Öğrenci Çocuk Sayısı", data.aileOgrenciCocukSayisi)}
          {GroupHandler(
            "Ö. Olmayan Çocuk Sayısı",
            data.aileOgrenciOlmayanCocukSayisi
          )}
          {GroupHandler("B.K. Destek Tutarı", data.baskaKurumDestekTutari)}
          {GroupHandler("Meslek", data.meslek)}
          {GroupHandler("Arazi Tapusu", YesNoHandler(data.araziTapusuVarMi))}
          {GroupHandler(
            "Sosyal Güvence",
            YesNoHandler(data.sosyalGuvenceVarmi)
          )}
          {GroupHandler("Konut Tapusu", YesNoHandler(data.konutTapusuVarMi))}
        </div>
      </div>
    </Fragment>
  );
};
