import React, { useEffect, useState } from "react";
import classes from "./BasvuruSorgula.module.css";
import { Fragment } from "react";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { BasvuruSorgulaForm } from "../../../components/client/basvuruSorgulaForm/BasvuruSorgulaForm";
import { SwalErrorMessage, SwalStatusMessage } from "../../../util/swalUtil";
import { CheckFormValid } from "../../../components/ui/input/Validation";
import Swal from "sweetalert2";

export const BasvuruSorgula = () => {
  const appContext = useContext(AppContext);
  const [formInput, setFormInput] = useState({
    basvuruId: null,
    tcKimlikNo: null,
    dogumTarihi: null,
  });
  const [formInputIsValid, setFormInputIsValid] = useState({
    basvuruId: { status: false, message: "Başvuru: Zorunludur." },
    tcKimlikNo: { status: false, message: "T.C. Kimlik: Zorunludur." },
    dogumTarihi: { status: false, message: "Doğum Tarihi: Zorunludur." },
  });
  const [supportDataOpt, setSupportDataOpt] = useState();

  useEffect(() => {
    GetQuerySupportDataList();
  }, []);

  const GetQuerySupportDataList = async () => {
    const response = await appContext.FetchHandler(
      "support/query",
      "get",
      null,
      null,
    );
    if (response.ok) {
      const _tempDataOpt = [];
      Array.from(response.data).forEach((x) => {
        _tempDataOpt.push({ value: x.id, text: x.name });
      });
      setSupportDataOpt(_tempDataOpt);
    }
  };

  const SubmitForm = async () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "webApply/status",
        "post",
        "",
        formInput,
      );

      if (response.ok) {
        Swal.fire({
          title: response.data.yardiM_TURLERI_ADI,
          text:
            response.data.sonuc.mesaj.length > 0
              ? response.data.sonuc.mesaj
              : response.data.duruM_SONUC_ACIKLAMA,
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#1b437e",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tamam",
          cancelButtonText: "Kabul Etmiyorum",
        });
      }
    });
  };

  const SubmitFormHandler = async (event) => {
    typeof event !== "undefined" && event.preventDefault();
    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const checkIdentity = await appContext.CheckTCWithBD({
        gToken: gToken,
        tcKimlikNo: formInput.tcKimlikNo,
        dogumTarihi: formInput.dogumTarihi,
      });
      if (checkIdentity.ok) {
        SubmitForm();
      }
    });
  };

  return (
    <Fragment>
      <section className={classes.section}>
        <div className={classes.formBox}>
          <h1>Başvuru Sorgula</h1>
          <BasvuruSorgulaForm
            formInput={formInput}
            setFormInput={setFormInput}
            formInputIsValid={formInputIsValid}
            setFormInputIsValid={setFormInputIsValid}
            submitHandler={SubmitFormHandler}
            supportData={supportDataOpt}
          />
        </div>
      </section>
    </Fragment>
  );
};
