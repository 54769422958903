import React, { Fragment } from "react";
import { EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";

export const OurServicesCrudForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          <Input
            label="Ad"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "name",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.name != null
                  ? formInput.name
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Sıra"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.index != null
                  ? formInput.index
                  : 999,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  index: event.target.value,
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex" }}>
          <Input
            label="Görsel"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "file",
              type: "file",
              accept: "image/*",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  file: event.target.files[0],
                }));
              },
            }}
          />

          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        <img
          src={
            formInput != null && formInput.imageUrl != null
              ? formInput.imageUrl
              : ""
          }
          alt=""
          width="150"
          style={{ objectFit: "contain", marginLeft: ".5rem" }}
        />
        <Input
          label="Kısa Açıklama"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "shortDescription",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.shortDescription != null
                ? formInput.shortDescription
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                shortDescription: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Açıklama"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
