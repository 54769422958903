import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { AppContext } from "./app-context";
import {
  SwalErrorMessage,
  SwalErrorResponse,
  SwalInfoResponse,
} from "../util/swalUtil";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { PopupModal } from "../components/client/popupModal/PopupModal";
import HTMLReactParser from "html-react-parser";

export const AppContextProvider = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState();
  const [isMobile, setIsMobile] = useState();
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState();
  const [lang, setLang] = useState(0);
  const [supportData, setSupportData] = useState(null);
  const [gRecapReady, setGRecapReady] = useState(false);
  const [announcementData, setAnnouncementData] = useState([]);
  const [showAnnouncment, setShowAnnouncment] = useState({});

  useEffect(() => {
    if (!window.grecaptcha) AppendGR();
  }, []);

  const AppendGR = () => {
    const gRecap = document.createElement("script");
    gRecap.id = "gRecap";
    gRecap.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GRECAPKEY}`;
    document.body.appendChild(gRecap);
    gRecap.addEventListener("load", () => {
      setGRecapReady(true);
    });
  };

  const GetAnnouncementData = async () => {
    const response = await FetchHandler("Announcement", "get", null, null);

    if (response.ok) {
      const tempData = [];
      Array.from(response.data).forEach((x, i) => tempData.push({ [i]: true }));
      setShowAnnouncment(tempData);
      setAnnouncementData(response.data);
    }
  };

  const IsMobileHandler = () => {
    //991.98px

    if (window.matchMedia("(max-width: 1199.98px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", IsMobileHandler);

  useEffect(() => {
    IsMobileHandler();
    CheckLogin();
    if (supportData == null || supportData.length === 0) GetSupportDataList();
    GetAnnouncementData();
  }, []);

  const GetSupportDataList = async () => {
    const response = await FetchHandler("support", "get", null, null);
    if (response.ok) {
      const _tempData = [];
      Array.from(response.data).forEach((x) => {
        switch (x.supportType) {
          case 0:
            x.url = "webApply";
            _tempData.push(x);
            break;
          case 1:
            x.url = "randevuHizmetleri/basvur";
            _tempData.push(x);
            break;
          case 2:
            x.url = "stuff";
            _tempData.push(x);
            break;
          case 3:
            x.url = "izsu";
            _tempData.push(x);
            break;
          case 4:
            x.url = "productClient";
            _tempData.push(x);
            break;
          case 5:
            x.url = "link";
            _tempData.push(x);
            break;
          case 6:
            x.url = "webApply";
            _tempData.push(x);
            break;
          default:
            break;
        }
      });

      setSupportData(_tempData);
    }
  };

  const GetHeaders = (contentType, pageType) => {
    switch (contentType) {
      case "formData":
        return {
          Authorization: `Bearer ${token}`,
          AppName: pageType === "admin" ? "admin" : "client",
        };
      default:
        return {
          Authorization: `Bearer ${token}`,
          AppName: pageType === "admin" ? "admin" : "client",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        };
    }
  };

  const CheckLogin = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      ParseJwt(token);
      setToken(token);
      setIsLoggedIn(true);
    }
  };

  function ParseJwt(token) {
    const data = JSON.parse(
      Buffer.from(token.split(".")[1], "base64").toString(),
    );

    if (data !== "undefined" && data != null) setUser(data);
  }

  const LoginHandler = async (data) => {
    const response = await FetchHandler("Auth/AdminLogin", "post", null, data);
    if (response.ok) {
      setToken(response.data);
      ParseJwt(response.data);
      localStorage.setItem("token", response.data);
      setIsLoggedIn(true);
    }
  };

  const LogoutHandler = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsLoggedIn(false);
  };

  const FetchHandler = async (url, method, contentType, data, pageType) => {
    setLoader(true);
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: method,
      mode: "cors",
      headers: GetHeaders(contentType, pageType),
      body:
        method === "get"
          ? null
          : contentType === "formData"
            ? data
            : JSON.stringify(data),
    })
      .then(async (response) => {
        setLoader(false);

        if (response.status === 401 || response.status === 403) {
          Swal.fire({
            title: "Hata",
            text: "Yetkiniz bulunmamaktadır.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Tamam",
          }).then(async (result) => {
            LogoutHandler();
          });
          return;
        }

        const responseData = await response.json();
        if (responseData !== undefined) {
          if (responseData.ok) return responseData;
          else if (
            responseData.statusCode === 406 ||
            responseData.statusCode === 409
          ) {
            SwalInfoResponse(responseData);
            return responseData;
          } else {
            SwalErrorResponse(responseData);
            return responseData;
          }
        } else {
          SwalErrorMessage(`${response.status} ${response.statusText}`);
          return null;
        }
      })
      .catch((error) => {
        SwalErrorMessage(
          "Servise erişilemiyor, bağlantınızda problem olduğunu düşünmüyorsanız bir süre sonra tekrar deneyiniz.",
        );
        return null;
      });
  };

  const CheckTCWithBD = async (data) => {
    const response = await FetchHandler(
      "webApply/CheckTCWithBD",
      "post",
      "",
      data,
    );

    return response;
  };

  const CheckTC = async (data) => {
    const response = await FetchHandler("webApply/CheckTC", "post", "", data);

    return response;
  };

  const GetGRecapToken = () => {
    return new Promise((res, rej) => {
      try {
        if (gRecapReady) {
          window.grecaptcha.ready(function () {
            window.grecaptcha
              .execute(`${process.env.REACT_APP_GRECAPKEY}`, {
                action: "submit",
              })
              .then(function (token) {
                return res(token);
              });
          });
        }
      } catch (error) {
        // window.location.replace("/");
      }
    });
  };

  return (
    <AppContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isMobile: isMobile,
        loader: loader,
        user: user,
        token: token,
        lang: lang,
        supportData: supportData,
        setLang: setLang,
        setLoader: setLoader,
        LoginHandler: LoginHandler,
        LogoutHandler: LogoutHandler,
        FetchHandler: FetchHandler,
        // GetLang: GetLang,
        CheckTCWithBD: CheckTCWithBD,
        CheckTC: CheckTC,
        GetGRecapToken: GetGRecapToken,
        gRecapReady: gRecapReady,
        announcementData: announcementData,
        showAnnouncment: showAnnouncment,
        setShowAnnouncment: setShowAnnouncment,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
