import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./ChangePasswordForm.module.css";

export const ChangePasswordForm = ({
  formInput,
  setFormInput,
  submitHandler,
}) => {
  return (
    <Fragment>
      <form
        id="form"
        className={`form ${classes.form}`}
        onSubmit={submitHandler}
      >
        <div style={{ display: "flex" }}>
          <Input
            label="Mevcut Şifre"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "password",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.password != null
                  ? formInput.password
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Yeni Şifre"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "newPassword",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.newPassword != null
                  ? formInput.newPassword
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  newPassword: event.target.value,
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
        </div>
      </form>
    </Fragment>
  );
};
