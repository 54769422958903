import React, { Fragment } from "react";
import { Input } from "./Input";

export const MinMaxDate = ({
  formInput,
  setFormInput,
  submitHandler,
  buttonStatus,
}) => {
  return (
    <Fragment>
      <form
        className="form"
        onSubmit={submitHandler}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Input
            label="Başlangıç Tarihi"
            type="input"
            style={{ width: "25%" }}
            input={{
              id: "minDate",
              type: "date",
              required: "required",
              value: formInput.minDate,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  minDate: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Bitiş Tarihi"
            type="input"
            style={{ width: "25%" }}
            input={{
              id: "maxDate",
              type: "date",
              required: "required",
              value: formInput.maxDate,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  maxDate: event.target.value,
                }));
              },
            }}
          />
          <div>
            <button
              type="submit"
              className="btnSave"
              disabled={buttonStatus ? false : true}
              style={{ marginTop: "2.5rem" }}
            >
              Listele
            </button>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}></div>
      </form>
    </Fragment>
  );
};
