import React, { Fragment, useEffect } from "react";
import classes from "./PaymentForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { Input } from "../../ui/input/Input";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import Swal from "sweetalert2";
import HTMLReactParser from "html-react-parser";
import {
  AcikRizaMetniKurban,
  KVKK,
  MesafeliSatisSizkesnesi,
} from "../../../util/commonText";
import { Validation } from "../../ui/input/Validation";

export const PaymentForm = ({
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
  submitHandler,
  product,
  isProduct,
}) => {
  const monthData = [
    { value: "01", text: "01" },
    { value: "02", text: "02" },
    { value: "03", text: "03" },
    { value: "04", text: "04" },
    { value: "05", text: "05" },
    { value: "06", text: "06" },
    { value: "07", text: "07" },
    { value: "08", text: "08" },
    { value: "09", text: "09" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" },
  ];
  const GetYearData = () => {
    const yearData = [];
    const date = new Date();

    for (let i = date.getFullYear(); i < date.getFullYear() + 8; i++) {
      yearData.push({ value: i.toString().substring(2, 4), text: i });
    }
    return yearData;
  };

  const PaymentTypeHandler = (payment) => {
    if (payment === 0) {
      setFormInput((prev) => ({
        ...prev,
        cardNumber: "",
        expiry: "",
        cvc: "",
        holderName: "",
      }));
      setFormInputIsValid((prev) => ({
        ...prev,
        cardNumber: {
          status: false,
          message: "Kart Numarası: Zorunludur.",
        },
        expiry: {
          status: false,
          message: "Kart Geçerlilik Tarihi(Ay, Yıl): Zorunludur.",
        },
        cvc: { status: false, message: "CVC: Zorunludur." },
        holderName: {
          status: false,
          message: "Kart Üzerindeki Ad Soyad",
        },
      }));
    }
    if (payment === 1) {
      setFormInputIsValid((prev) => ({
        ...prev,
        cardNumber: {
          status: true,
          message: "",
        },
        expiry: {
          status: true,
          message: "",
        },
        cvc: {
          status: true,
          message: "",
        },
        holderName: {
          status: true,
          message: "",
        },
      }));
    }
    setFormInput((prev) => ({
      ...prev,
      paymentType: payment,
    }));
  };

  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <h3>Ödeme Bilgileri</h3>
        <div className={classes.row}>
          <div className={classes.rowLeft}>
            <div className={classes.paymentType}>
              <label>Ödeme Tipi</label>
              <div className={classes.paymentTypeGroup}>
                {product &&
                  (product?.productPaymentType === 0 ||
                    product?.productPaymentType === 2) && (
                    <div
                      className={`${classes.paymentTypeItem} ${
                        formInput.paymentType === 0 &&
                        classes.paymentTypeItemAcive
                      }`}
                      onClick={() => PaymentTypeHandler(0)}
                    >
                      <FontAwesomeIcon icon={faCreditCard} fontSize="2rem" />
                      <h4>Kredi/Banka Kartı ile Ödeme</h4>
                    </div>
                  )}
                {product &&
                  (product.productPaymentType === 1 ||
                    product.productPaymentType === 2) && (
                    <div
                      className={`${classes.paymentTypeItem} ${
                        ((product && product.productPaymentType === 1) ||
                          formInput.paymentType === 1) &&
                        classes.paymentTypeItemAcive
                      }`}
                      onClick={() => PaymentTypeHandler(1)}
                    >
                      <FontAwesomeIcon
                        icon={faMoneyBillTransfer}
                        fontSize="2rem"
                      />
                      <h4>Havale/EFT</h4>
                    </div>
                  )}
              </div>
            </div>
            <div className={classes.invoiceType}>
              <div className={classes.invoiceTypeGroup}>
                <Input
                  label="Fatura Tipi"
                  type="radioGrup"
                  id="userType"
                  option={[
                    {
                      id: 0,
                      text: "Bireysel",
                      checked: true,
                      value: 0,
                    },
                    {
                      id: 1,
                      text: "Kurumsal",
                      checked: false,
                      value: 1,
                    },
                  ]}
                  input={{
                    name: "userType",
                    onChange: (event) => {
                      const value = parseInt(event.target.value);
                      
                      setFormInput((prev) => ({
                        ...prev,
                        userType: value,
                      }));
                      if (value === 0) {
                        setFormInputIsValid((prev) => ({
                          ...prev,
                          title: {
                            status: true,
                            message: "",
                          },
                          taxNo: {
                            status: true,
                            message: "",
                          },
                          invoiceAddress: {
                            status: true,
                            message: "",
                          },
                          fullName: Validation("Ad Soyad", formInput.fullName, {
                            isRequired: true,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          title: null,
                          taxNo: null,
                          invoiceAddress: null,
                        }));
                      } else {
                        
                        setFormInputIsValid((prev) => ({
                          ...prev,
                          fullName: {
                            status: true,
                            message: "",
                          },
                          title: {
                            status: false,
                            message: "Ünvan: Zorunludur.",
                          },
                          taxNo: {
                            status: false,
                            message: "Vergi No: Zorunludur.",
                          },
                          invoiceAddress: {
                            status: false,
                            message: "Fatura Adresi: Zorunludur.",
                          },
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          fullName: null,
                        }));
                      }
                    },
                  }}
                />
              </div>
            </div>
            {formInput.userType == 0 ? (
              <Input
                divId="fullNameDiv"
                label="Ad Soyad"
                style={{ width: "100%" }}
                type="input"
                validation={
                  formInput.fullName !== null && formInputIsValid.fullName
                }
                input={{
                  id: "fullName",
                  type: "text",
                  placeholder: "Ad Soyad",
                  value: formInput.fullName != null ? formInput.fullName : "",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      fullName: Validation("Ad Soyad", value, {
                        isRequired: true,
                      }),
                      holderName: Validation(
                        "Kart Üzerindeki Ad Soyad",
                        value,
                        {
                          isRequired: true,
                        },
                      ),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      fullName: event.target.value,
                      holderName: event.target.value,
                    }));
                  },
                }}
              />
            ) : (
              <Fragment>
                <Input
                  divId="titleDiv"
                  label="Ünvan"
                  type="input"
                  style={{ width: "100%" }}
                  validation={
                    formInput.title !== null && formInputIsValid.title
                  }
                  input={{
                    id: "title",
                    type: "text",
                    placeholder: "Ünvan",
                    value: formInput.title != null ? formInput.title : "",
                    onChange: (event) => {
                      const value = event.target.value;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        title: Validation("Ünvan", value, {
                          isRequired: true,
                          min: 2,
                          max: 50,
                        }),
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        title: value,
                      }));
                    },
                  }}
                />
                <Input
                  divId="taxNoDiv"
                  label="Vergi No"
                  type="input"
                  style={{ width: "100%" }}
                  validation={
                    formInput.taxNo !== null && formInputIsValid.taxNo
                  }
                  input={{
                    id: "taxNo",
                    type: "text",
                    placeholder: "Vergi No",
                    min: 0,
                    value: formInput.taxNo != null ? formInput.taxNo : "",
                    onChange: (event) => {
                      const value = event.target.value;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        taxNo: Validation("Vergi No", value, {
                          isRequired: true,
                          type: 1,
                          min: 10,
                          max: 11,
                        }),
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        taxNo: value,
                      }));
                    },
                  }}
                />
                <Input
                  divId="invoiceAddress"
                  label="Fatura Adresi"
                  type="textArea"
                  style={{ width: "100%" }}
                  validation={
                    formInput.invoiceAddress !== null &&
                    formInputIsValid.invoiceAddress
                  }
                  input={{
                    id: "invoiceAddress",
                    placeholder: "Fatura Adresi",
                    min: 0,
                    value:
                      formInput != null && formInput.invoiceAddress != null
                        ? formInput.invoiceAddress
                        : "",
                    onChange: (event) => {
                      const value = event.target.value;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        invoiceAddress: Validation("Fatura Adresi", value, {
                          isRequired: true,
                          min: 10,
                          max: 50,
                        }),
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        invoiceAddress: event.target.value,
                      }));
                    },
                  }}
                />
              </Fragment>
            )}

            <Input
              label="Telefon"
              type="phoneNumber"
              onlyCountries={["tr"]}
              style={{ width: "100%" }}
              validation={
                formInput.phoneNumber !== null && formInputIsValid.phoneNumber
              }
              input={{
                id: "phoneNumber",
                type: "text",
                value:
                  formInput != null && formInput.phoneNumber != null
                    ? formInput.phoneNumber
                    : "",
                onChange: (event) => {
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    phoneNumber: Validation("Telefon Numarası", event, {
                      isRequired: true,
                      min: 12,
                      max: 12,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    phoneNumber: event,
                  }));
                },
              }}
            />
            <Input
              label="Email"
              type="input"
              style={{ width: "100%" }}
              validation={formInput.email != null && formInputIsValid.email}
              input={{
                id: "email",
                type: "email",
                placeholder: "Email",
                info: "Faturanız e-Arşiv fatura olarak düzenlenerek tarafınıza e-posta ile iletilecektir.",
                value:
                  formInput != null && formInput.email != null
                    ? formInput.email
                    : "",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    email: Validation("Email", value, {
                      isRequired: true,
                      type: 2,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    email: event.target.value,
                  }));
                },
              }}
            />
          </div>
          <div className={classes.rowRight}>
            {product &&
            (product.productPaymentType == 0 ||
              product.productPaymentType == 2) &&
            formInput.paymentType == 0 ? (
              <div className={classes.cardItems}>
                <div className={classes.card}>
                  <Cards
                    number={formInput.cardNumber}
                    expiry={formInput.expiry}
                    cvc={formInput.cvc}
                    name={formInput.holderName}
                    placeholders={{
                      name: "Ad Soyad",
                    }}
                    focused={formInput.focus}
                  />
                </div>
                <div className={classes.cardInputs}>
                  <Input
                    label="Kart Numarası"
                    type="input"
                    style={{ width: "100%" }}
                    validation={
                      formInput.cardNumber !== null &&
                      formInput.cardNumber != "" &&
                      formInputIsValid.cardNumber
                    }
                    input={{
                      id: "cardNumber",
                      type: "text",
                      min: 0,
                      placeholder: "Kart Numarası",
                      value:
                        formInput.cardNumber != null
                          ? formInput.cardNumber
                          : "",
                      onChange: (event) => {
                        const value = event.target.value;
                        setFormInputIsValid((prev) => ({
                          ...prev,
                          cardNumber: Validation("Kart Numarası", value, {
                            isRequired: true,
                            type: 1,
                            min: 16,
                            max: 16,
                          }),
                        }));

                        setFormInput((prev) => ({
                          ...prev,
                          cardNumber: event.target.value,
                        }));
                      },
                      onFocus: () => {
                        setFormInput((prev) => ({
                          ...prev,
                          focus: "expiry",
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Kart Üzerindeki Ad Soyad"
                    type="input"
                    style={{ width: "100%" }}
                    validation={
                      formInput.holderName !== null &&
                      formInput.holderName != "" &&
                      formInputIsValid.holderName
                    }
                    input={{
                      id: "holderName",
                      type: "text",
                      placeholder: "Kart Üzerindeki Ad Soyad",
                      value:
                        formInput.holderName != null
                          ? formInput.holderName
                          : "",
                      onChange: (event) => {
                        const value = event.target.value;
                        setFormInputIsValid((prev) => ({
                          ...prev,
                          holderName: Validation(
                            "Kart Üzerindeki Ad Soyad",
                            value,
                            {
                              isRequired: true,
                            },
                          ),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          holderName: event.target.value,
                        }));
                      },
                      onFocus: () => {
                        setFormInput((prev) => ({
                          ...prev,
                          focus: "expiry",
                        }));
                      },
                    }}
                  />
                  <div className={classes.cardMYC}>
                    <Input
                      label="Ay"
                      type="select"
                      style={{ width: "100%" }}
                      option={monthData}
                      input={{
                        id: "expiry",
                        onChange: (event) => {
                          let temp = formInput.expiry;
                          if (temp.length === 4) {
                            temp = temp.substr(2, 3);

                            temp = event.target.value + temp;
                          } else {
                            temp = event.target.value + "00";
                          }

                          if (!temp.includes("00")) {
                            setFormInputIsValid((prev) => ({
                              ...prev,
                              expiry: {
                                status: true,
                                message: "",
                              },
                            }));
                          }

                          setFormInput((prev) => ({
                            ...prev,
                            expiry: temp,
                            focus: "expiry",
                          }));
                        },
                        onFocus: () => {
                          setFormInput((prev) => ({
                            ...prev,
                            focus: "expiry",
                          }));
                        },
                      }}
                    />
                    <Input
                      label="Yıl"
                      type="select"
                      style={{ width: "100%" }}
                      option={GetYearData()}
                      input={{
                        id: "expiry",
                        onChange: (event) => {
                          let temp = formInput.expiry;

                          if (temp.length === 4) {
                            temp = temp.substr(0, 2);
                            temp = temp + event.target.value;
                          } else {
                            temp = "00" + event.target.value;
                          }

                          if (!temp.includes("00")) {
                            setFormInputIsValid((prev) => ({
                              ...prev,
                              expiry: {
                                status: true,
                                message: "",
                              },
                            }));
                          }

                          setFormInput((prev) => ({
                            ...prev,
                            expiry: temp,
                            focus: "expiry",
                          }));
                        },
                        onFocus: () => {
                          setFormInput((prev) => ({
                            ...prev,
                            focus: "expiry",
                          }));
                        },
                      }}
                    />
                    <Input
                      label="CVC"
                      type="input"
                      style={{ width: "100%" }}
                      validation={
                        formInput.cvc !== null &&
                        formInput.cvc != "" &&
                        formInputIsValid.cvc
                      }
                      input={{
                        id: "cvc",
                        type: "number",
                        min: 0,
                        placeholder: "CVC",
                        value: formInput.cvc != null ? formInput.cvc : "",
                        onChange: (event) => {
                          if (event.target.value.length > 4) {
                            return;
                          }
                          const value = event.target.value;
                          setFormInputIsValid((prev) => ({
                            ...prev,
                            cvc: Validation("CVC", value, {
                              isRequired: true,
                              min: 2,
                              max: 5,
                            }),
                          }));
                          setFormInput((prev) => ({
                            ...prev,
                            cvc: event.target.value,
                            focus: "cvc",
                          }));
                        },
                        onFocus: () => {
                          setFormInput((prev) => ({
                            ...prev,
                            focus: "cvc",
                          }));
                        },
                        onBlur: () => {
                          setFormInput((prev) => ({
                            ...prev,
                            focus: "expiry",
                          }));
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.ibanItems}>
                <img src="/assets/img/logo.png" alt="" />
                <div className={classes.ibanElement}>
                  <h4>Banka:</h4>
                  <p>{product?.bankName}</p>
                </div>
                <div className={classes.ibanElement}>
                  <h4>Hesap Adı:</h4>
                  <p>{product?.ibanName}</p>
                </div>
                <div className={classes.ibanElement}>
                  <h4>IBAN No:</h4>
                  <p>{product?.ibanNo}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.pay}>
          <Input
            disableLabel={true}
            label={HTMLReactParser(
              `<p><span style="color: #1b437e;">KVKK Aydınlatma Metni</span> 'ni okuduğumu onaylıyorum.</p>`,
            )}
            type="input"
            checked={
              formInput != null && formInput.kvkk != null && formInput.kvkk
            }
            checkboxStyle={{ justifyContent: "flex-start" }}
            validation={formInput.kvkk != null && formInputIsValid.kvkk}
            input={{
              id: "kvkk",
              type: "checkbox",
              onChange: (event) => {
                Swal.fire({
                  width: 1000,
                  html: KVKK(),
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#118c11",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Evet",
                  cancelButtonText: "Hayır",
                }).then(async (result) => {
                  const element = document.getElementById(event.target.id);
                  if (result.isConfirmed) {
                    element.checked = true;
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      kvkk: {
                        status: true,
                        message: ``,
                      },
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      kvkk: true,
                    }));
                  } else {
                    element.checked = false;
                    setFormInput((prev) => ({
                      ...prev,
                      kvkk: false,
                    }));
                  }
                });
              },
            }}
          />
          {isProduct && (
            <Input
              disableLabel={true}
              label={HTMLReactParser(
                `<p><span style="color: #1b437e;">Açık Rıza Metni</span> 'ni okuduğumu onaylıyorum.</p>`,
              )}
              type="input"
              checked={
                formInput != null &&
                formInput.saleContract != null &&
                formInput.saleContract
              }
              checkboxStyle={{ justifyContent: "flex-start" }}
              validation={
                formInput.saleAggrement != null &&
                formInputIsValid.saleAggrement
              }
              input={{
                id: "saleAggrement",
                type: "checkbox",
                onChange: (event) => {
                  Swal.fire({
                    width: 1000,
                    html: AcikRizaMetniKurban(),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#118c11",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                  }).then(async (result) => {
                    const element = document.getElementById(event.target.id);
                    if (result.isConfirmed) {
                      element.checked = true;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        saleAggrement: {
                          status: true,
                          message: ``,
                        },
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        saleAggrement: true,
                      }));
                    } else {
                      element.checked = false;
                      setFormInput((prev) => ({
                        ...prev,
                        saleAggrement: false,
                      }));
                    }
                  });
                },
              }}
            />
          )}
          <button type="submit" className={classes.payButton}>
            ÖDEME YAP
          </button>
        </div>
      </form>
    </Fragment>
  );
};
