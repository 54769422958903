import React, { Fragment, useContext, useEffect, useState } from "react";
import { CompanyCrudForm } from "../../../components/admin/company/CompanyCrudForm";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import {
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";

export const Company = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Şirket";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState();
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) =>
        DataTableProcess(
          row,
          (event) => DeleteHandler(event),
          () => EditHandler(row),
          null
        ),
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "MerchantId",
      selector: (row) => row.merchantId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Password",
      selector: (row) => row.password,
      sortable: true,
      width: "200px",
    },
    {
      name: "TerminalNo",
      selector: (row) => row.terminalNo,
      sortable: true,
      width: "200px",
    },
    {
      name: "Iban Banka",
      selector: (row) => row.ibanBank,
      sortable: true,
      width: "200px",
    },
    {
      name: "Iban Ad",
      selector: (row) => row.ibanName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Iban",
      selector: (row) => row.iban,
      sortable: true,
      width: "200px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "Company",
        "get",
        null,
        null,
        "admin"
      );
      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          "company",
          method,
          null,
          formInput
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "company",
          "delete",
          null,
          { id: event.target.id }
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput();
            setShowModal(true);
          }}
        >
          {pageTitle} Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>

      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle - Düzenle`}
        >
          <CompanyCrudForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
          />
        </ModalAdmin>
      )}
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};
