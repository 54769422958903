import React, { Fragment } from "react";
import classes from "./IzsuApplyForm.module.css";
import { Input } from "../../ui/input/Input";
import Swal from "sweetalert2";
import { KVKK, RizaMetni } from "../../../util/commonText";
import HTMLReactParser from "html-react-parser";

export const IzsuApplyForm = ({ formInput, setFormInput, submitHandler }) => {
  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <div className={classes.group}>
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="Ad Soyad"
            input={{
              id: "fullName",
              type: "text",
              placeholder: "Ad Soyad",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  fullName: event.target.value,
                }));
              },
            }}
          />
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="T.C. Kimlik No"
            input={{
              id: "tcKimlikNo",
              type: "number",
              placeholder: "T.C. Kimlik No",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  tcKimlikNo: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div className={classes.group}>
          <Input
            type="input"
            label="Doğum Tarihi"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            input={{
              id: "dogumTarihi",
              type: "date",
              placeholder: "Doğum Tarihi",
              required: "required",
              value:
                formInput != null && formInput.birthDate != null
                  ? formInput.birthDate
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  birthDate: event.target.value,
                }));
              },
            }}
          />
          <Input
            type="input"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            label="İzsu Abone No"
            input={{
              id: "izsuAboneNo",
              type: "number",
              placeholder: "İzsu Abone No",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  izsuAboneNo: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div className={classes.phoneDiv}>
          <Input
            type="phoneNumber"
            label="Telefon"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            input={{
              id: "phoneNumber",
              type: "text",
              required: "required",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  phoneNumber: event,
                }));
              },
            }}
          />
        </div>

        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline">KVKK Aydınlatma Metni</span>’ni okuduğumu onaylıyorum.</span>`
          )}
          style={{ width: "100%" }}
          type="input"
          checked={
            formInput != null && formInput.kvkk != null && formInput.kvkk
          }
          checkboxStyle={{ justifyContent: "flex-start" }}
          input={{
            id: "kvkk",
            type: "checkbox",
            required: "required",
            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: KVKK(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: true,
                  }));
                } else {
                  element.checked = false;
                  setFormInput((prev) => ({
                    ...prev,
                    kvkk: false,
                  }));
                }
              });
            },
          }}
        />
        <Input
          disableLabel={true}
          label={HTMLReactParser(
            `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline">Rıza Metni</span>’ni okuduğumu onaylıyorum.</span>`
          )}
          style={{ width: "100%" }}
          type="input"
          checked={
            formInput != null && formInput.kvkk != null && formInput.kvkk
          }
          checkboxStyle={{ justifyContent: "flex-start" }}
          input={{
            id: "rizaMetni",
            type: "checkbox",
            required: "required",
            onChange: (event) => {
              Swal.fire({
                width: 1000,
                html: RizaMetni(),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#118c11",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              }).then(async (result) => {
                const element = document.getElementById(event.target.id);
                if (result.isConfirmed) {
                  element.checked = true;
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: true,
                  }));
                } else {
                  element.checked = false;
                  setFormInput((prev) => ({
                    ...prev,
                    rizaMetni: false,
                  }));
                }
              });
            },
          }}
        />
        <div className={classes.formButton}>
          <button>BAŞVUR</button>
        </div>
      </form>
    </Fragment>
  );
};
