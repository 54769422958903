import React, {Fragment} from "react";
import {Outlet} from "react-router-dom";
import classes from "./Main.module.css";
import {Footer} from "../footer/Footer";

export const Main = () => {
    return (
        <Fragment>
            <main className={classes.main} id="main">
                <Outlet/>
                <Footer/>
            </main>
        </Fragment>
    );
};
