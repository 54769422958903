import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./ForgotPasswordForm.module.css";

export const ForgotPasswordForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setFormType,
}) => {
  return (
    <Fragment>
      <form
        id="form"
        className={`form ${classes.forgotForm}`}
        onSubmit={(event) => {
          event.preventDefault();
          submitHandler();
        }}
      >
        <Input
          label="Email"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "userName",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.userName != null
                ? formInput.userName
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                userName: event.target.value,
              }));
            },
          }}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Gönder
          </button>
          <button
            type="submit"
            className="btnCancel"
            onClick={() => setFormType(0)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
