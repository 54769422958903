import React, { Fragment } from "react";
import classes from "./AppointmentMine.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCancel,
    faDiceOne,
    faHandsWash,
    faRemove,
    faShieldBlank,
    faShieldHeart
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { ConvertCharset } from "../../../util/commonUtil";

export const AppointmentMine = ({ userAppointmentsData, CancelHandler }) => {
  return (
    <Fragment>
      <div className={classes.groupBox}>
        {userAppointmentsData && userAppointmentsData.length>0 ?
            (        Array.from(userAppointmentsData).map((x, i) => {
                let iptalEdilebilir = "true"
                let hizmetAldi = "";
                iptalEdilebilir = JSON.parse(x.iptalEdilebilir);

                hizmetAldi =
                    ConvertCharset(x.durum).replaceAll(" ", "") == "hizmetaldi";
                return (
                    <div
                        key={i}
                        className={`${classes.box} 
              ${!iptalEdilebilir && !hizmetAldi && classes.boxCancel} 
              ${hizmetAldi && classes.boxDone}
              `}
                        onClick={() =>
                            iptalEdilebilir
                                ? CancelHandler(x.randevuId.replace(".0", ""))
                                : null
                        }
                    >
                        <div className={classes.boxTitle}>
                            <h4>Randevu Bilgileri</h4>
                            <FontAwesomeIcon
                                icon={
                                    iptalEdilebilir ? faRemove : !hizmetAldi ? faCancel : faShieldHeart
                                }
                                style={
                                    iptalEdilebilir ? { color: "#1b437e" }
                                        : hizmetAldi ? { color: "#000" }
                                            :{ color: "red" }
                                }
                            />
                        </div>
                        <div className={classes.boxLineGroup}>
                            <div className={classes.line}>
                                <h5>Tarih:</h5>
                                <p>{moment(x.tarihSaat).format("DD.MM.YYYY")}</p>
                            </div>
                            <div className={classes.line}>
                                <h5>Saat:</h5>
                                <p>{moment(x.tarihSaat).format("HH:mm")}</p>
                            </div>
                            <div className={classes.line}>
                                <h5>Yer:</h5>
                                <p>{x.hizmetYeriAdi}</p>
                            </div>
                            <div className={classes.line}>
                                <h5>Makine:</h5>
                                <p>{x.aracAdi}</p>
                            </div>
                        </div>
                        <div
                            className={`${classes.boxBottom} 
              ${!iptalEdilebilir && !hizmetAldi && classes.boxBottomCancel} 
              ${hizmetAldi && classes.boxBottomDone}
              `}
                        >
                            {x.durum}
                        </div>
                    </div>
                );
            })):(
                <h1 style={{textAlign:"center"}}>Randevunuz bulunmamaktadır.</h1>
            )
        
        }
      </div>
    </Fragment>
  );
};
