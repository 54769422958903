import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./SutKuzusu.module.css";
import { ContentHead } from "../../../components/client/contentHead/ContentHead";
import { WebApplyForm } from "../../../components/client/webApplyForm/WebApplyForm";
import { SwalErrorMessage, SwalInfoMessage } from "../../../util/swalUtil";
import { OzelAlanlarForm } from "../../../components/client/ozelAlanlarForm/OzelAlanlarForm";
import Swal from "sweetalert2";
import { ChildTcForm } from "../../../components/client/childTcForm/ChildTcForm";
import { WebApplyFileUpload } from "../../../components/client/webApplyFileUpload/WebApplyFileUpload";
import {
  CheckFormValid,
  Validation,
} from "../../../components/ui/input/Validation";
import { OtpForm } from "../../../components/client/otpForm/OtpForm";

export const SutKuzusu = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state;
  const [state, setState] = useState(null);

  useEffect(() => {
    stateLocation != null ? setState(stateLocation.state) : setState(null);
    if (state === undefined || state == null) setState(stateLocation);
  }, []);

  const formInputLocation = stateLocation?.formInput;

  const formInputJson = {
    gToken: "",
    supportId: "",
    basvuruId:
      formInputLocation?.basvuruId !== undefined
        ? formInputLocation?.basvuruId
        : "",
    randevuId: "",
    cepTelefonu:
      formInputLocation !== undefined ? formInputLocation.cepTelefonu : null,
    tcKimlikNo:
      formInputLocation !== undefined ? formInputLocation.tcKimlikNo : null,
    dogumTarihi:
      formInputLocation !== undefined ? formInputLocation.dogumTarihi : null,
    kampanyaBilgileriId: "",
    basvuruTipiId: "",
    basvuruSekliId: "",
    yardimTurleriId: "",
    ozelAlanlar: [],
    basvurulanAileBilgisiTcKimlikNoListesi: [],
    ekliDokumanlar: [],
    isTcList: false,
    isAddressChangeAble: true,
    kvkk:
      formInputLocation !== undefined &&
      formInputLocation !== null &&
      formInputLocation?.tcKimlikNo !== null &&
      formInputLocation?.tcKimlikNo !== ""
        ? true
        : null,
    rizaMetni:
      formInputLocation !== undefined &&
      formInputLocation != null &&
      formInputLocation?.tcKimlikNo != null &&
      formInputLocation?.tcKimlikNo !== ""
        ? true
        : null,
  };
  const [formInput, setFormInput] = useState(formInputJson);
  const [formInputIsValid, setFormInputIsValid] = useState({
    tcKimlikNo: {
      status: formInputLocation !== undefined,
      message: "T.C. Kimlik: Zorunludur.",
    },
    dogumTarihi: {
      status: formInputLocation !== undefined,
      message: "Doğum Tarihi: Zorunludur.",
    },
    cepTelefonu: {
      status: formInputLocation !== undefined,
      message: "Telefon Numarası: Zorunludur.",
    },
    kvkk: {
      status: formInputLocation !== undefined,
      message: "KVKK: Onayı zorunludur.",
    },
    rizaMetni: {
      status: formInputLocation !== undefined,
      message: "Rıza Metni: Onayı zorunludur.",
    },
  });
  const [ozelAlanlarData, setOzelAlanlarData] = useState(null);
  const [showWebApplyForm, setShowWebApplyForm] = useState(false);
  const [showChildTcForm, setShowChildTcForm] = useState(false);
  const [ekliDokumanBilgisiData, setEkliDokumanBilgisiData] = useState(null);

  const [otpForm, setOtpForm] = useState({
    gToken: "",
    otp: 0,
    tcKimlikNo: null,
    cepTelefonu: null,
  });
  const [isOtpFormValid, setIsOtpFormValid] = useState({
    otp: { status: true, message: "Doğrulama Kodu (OTP): Zorunludur." },
  });
  const [showOtpForm, setShowOtpForm] = useState(false);
  const id = process.env.REACT_APP_SUT_KUZUSU_ID;

  useEffect(() => {
    if (id != null && appContext.supportData != null) {
      const _state = appContext.supportData.filter(
        (x) => x.id.toString() === id.toString(),
      )[0];

      setState(_state);
    }
  }, [appContext.supportData]);

  useEffect(() => {
    if (state !== undefined && state !== null) {
      GetSupportData();
    }
  }, [state]);

  useEffect(() => {
    if (formInputLocation != null) {
      appContext.setLoader(appContext.loader);
    }
    if (
      formInput.tcKimlikNo !== undefined &&
      formInput.tcKimlikNo != null &&
      formInput.tcKimlikNo !== ""
    ) {
      if (formInput.basvuruTipiId != "") {
        SubmitHandler();
      }
    } else {
      setShowWebApplyForm(true);
    }
  }, [formInput.basvuruTipiId]);

  const GetSupportData = async () => {
    const response = await appContext.FetchHandler(
      "webApply/detail",
      "post",
      null,
      id,
    );

    if (response.ok) {
      response.data &&
        setFormInput((prev) => ({
          ...prev,
          kampanyaBilgileriId: response.data.kampanyaBilgileriId.toString(),
          basvuruTipiId: response.data.basvuruTipiId.toString(),
          basvuruSekliId: response.data.basvuruSekliId.toString(),
          yardimTurleriId: response.data.yardimTurleriId.toString(),
          isTcList: response.data.isTcList,
          isAddressChangeAble: response.data.isAddressChangeAble,
          supportId: response.data.supportId,
        }));
    }
  };

  const SubmitCheckForm = async () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "webApply/formCheck",
        "post",
        "",
        formInput,
      );

      if (!response.ok) {
        setShowWebApplyForm(true);
        setShowChildTcForm(false);
        setShowOtpForm(false);
        return;
      }

      if (
        state.supportType == 1 &&
        response.data.basvuruNo != null &&
        response.data.basvuruNo != 0
      ) {
        setFormInput((prev) => ({
          ...prev,
          basvuruId: response.data.basvuruNo.replace(".0", ""),
        }));
        formInput.basvuruId = response.data.basvuruNo.replace(".0", "");

        navigate("/appointment", {
          state: { state: state, formInput: formInput },
        });
      } else if (response.ok && response.data != null) {
        if (
          formInput.isTcList &&
          formInput.basvurulanAileBilgisiTcKimlikNoListesi.length < 0
        )
          setShowChildTcForm(true);
        else if (
          response.data.ozelAlanlar != null &&
          response.data.ozelAlanlar.gruplar.length > 0 &&
          response.data.ekliDokumanBilgisi != null &&
          response.data.ekliDokumanBilgisi.kurumOnaylari.length > 0
        ) {
          setShowWebApplyForm(false);
          setShowChildTcForm(false);
          setOzelAlanlarData(response.data.ozelAlanlar.gruplar[0].altGruplar);
          setEkliDokumanBilgisiData(
            response.data.ekliDokumanBilgisi.kurumOnaylari,
          );
        } else if (
          response.data.ozelAlanlar != null &&
          response.data.ozelAlanlar.gruplar.length > 0
        ) {
          setOzelAlanlarData(response.data.ozelAlanlar.gruplar[0].altGruplar);
          setShowWebApplyForm(false);
        } else if (
          response.data.ekliDokumanBilgisi != null &&
          response.data.ekliDokumanBilgisi.kurumOnaylari.length > 0
        ) {
          setShowChildTcForm(false);
          setEkliDokumanBilgisiData(
            response.data.ekliDokumanBilgisi.kurumOnaylari,
          );
        } else {
          SubmitLast();
        }
      } else {
        setShowWebApplyForm(true);
        setShowChildTcForm(false);
      }
    });
  };

  const CreateOtpHandler = async () => {
    const isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "SmsVerification/create",
        "post",
        "",
        {
          identityNo: formInput.tcKimlikNo,
          cepTelefonu: formInput.cepTelefonu,
          supportId: formInput.supportId,
          gToken: gToken,
        },
      );
      if (response.ok) {
        SwalInfoMessage(response.data);
        setShowWebApplyForm(false);
        setShowOtpForm(true);
      } else {
        setShowWebApplyForm(true);
      }
    });
  };

  const VerifyOtpHandler = async () => {
    const isValid = CheckFormValid(isOtpFormValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    appContext.GetGRecapToken().then(async (gToken) => {
      otpForm.gToken = gToken;
      otpForm.identityNo = formInput.tcKimlikNo;
      otpForm.cepTelefonu = formInput.cepTelefonu;
      otpForm.supportId = formInput.supportId;

      const response = await appContext.FetchHandler(
        "SmsVerification/Verify",
        "post",
        "",
        otpForm,
      );
      if (response.ok) {
        SwalInfoMessage(response.data);
        setShowWebApplyForm(false);
        setShowOtpForm(false);
        if (formInput.isTcList) {
          setShowChildTcForm(true);
        } else {
          await SubmitCheckForm();
        }
      }
    });
  };

  const SubmitHandler = async (event) => {
    if (event != undefined) event.preventDefault();
    const isValid = CheckFormValid(formInputIsValid);

    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;

      const checkIdentity = await appContext.CheckTCWithBD(formInput);
      if (checkIdentity.ok) {
        // setShowWebApplyForm(false);
        // if (formInput.isTcList) {
        //   setShowChildTcForm(true);
        // } else {
        //   await SubmitCheckForm();
        // }
        await CreateOtpHandler();
      } else {
        setShowWebApplyForm(true);
      }
    });
  };

  const SubmitLast = async (event) => {
    if (event != undefined) event.preventDefault();

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "webApply/save",
        "post",
        "",
        formInput,
      );

      if (response.ok) {
        Swal.fire({
          title: "İşlem Başarılı",
          text:
            response?.data?.mesaj != null || response?.data?.mesaj.length > 0
              ? response.data.mesaj
              : "Başvuru süreciniz başarı ile tamamlanmıştır.",
          icon: "success",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#34A853",
          confirmButtonText: "Tamam",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.replace("webApply");
          } else {
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <section className={classes.mainSection}>
        {showOtpForm ? (
          <Fragment>
            <ContentHead
              data={{
                imageUrl: state.imageUrl,
                name: "SMS Doğrulama",
                shortDescription:
                  "Başvurunuza devam edebilmek için telefonunuza gönderilen kodu giriniz.",
              }}
            />
            <OtpForm
              formInput={otpForm}
              setFormInput={setOtpForm}
              formInputIsValid={isOtpFormValid}
              setFormInputIsValid={setIsOtpFormValid}
              submitHandler={VerifyOtpHandler}
              createOtpHandler={CreateOtpHandler}
            />
          </Fragment>
        ) : (
          <ContentHead data={state} formTitle />
        )}
        {showWebApplyForm && (
          <Fragment>
            <WebApplyForm
              formInput={formInput}
              setFormInput={setFormInput}
              formInputIsValid={formInputIsValid}
              setFormInputIsValid={setFormInputIsValid}
              submitHandler={SubmitHandler}
            />
          </Fragment>
        )}

        {ozelAlanlarData && (
          <OzelAlanlarForm
            ozelAlanlarData={ozelAlanlarData}
            setOzelAlanlarData={setOzelAlanlarData}
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitLast}
            ekliDokumanBilgisiData={ekliDokumanBilgisiData}
          />
        )}
        {showChildTcForm && (
          <ChildTcForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitCheckForm}
          />
        )}
        {ekliDokumanBilgisiData && (
          <WebApplyFileUpload
            data={ekliDokumanBilgisiData}
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitLast}
          />
        )}
      </section>
    </Fragment>
  );
};
