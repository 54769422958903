import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { Link, useLocation, useParams } from "react-router-dom";
import classes from "./AnnouncementDetail.module.css";
import moment from "moment";
import HTMLReactParser from "html-react-parser";

export const AnnouncementDetail = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  // const { name, id } = useParams();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (location.state) {
      setData(location.state.data);
      setSelectedData(location.state.selectedData);
    } else {
      if (id != null) {
        GetAnnouncementData();
      } else {
        window.location.replace("/");
      }
    }
  }, []);

  const GetAnnouncementData = async () => {
    const response = await appContext.FetchHandler(
      "Announcement",
      "get",
      null,
      null,
    );

    if (response.ok) {
      const selected = response.data.find((x) => x.id === id);
      setSelectedData(selected);
      const _data = response.data.filter((x) => x.id !== id);
      setData(_data);
    }
  };

  const SelectedDataHandler = (data) => {
    setData((prev) => [...prev.filter((m) => m.id != data.id), selectedData]);
    setSelectedData(data);
  };

  return (
    <Fragment>
      <section className={classes.section}>
        {data && selectedData && (
          <Fragment>
            <div className={classes.detail}>
              <div className={classes.imgBox}>
                <img src={selectedData.imageUrl} alt="" />
                <div className={classes.createdDate}>
                  Yayınlanma Tarihi:
                  <p>
                    {moment(selectedData.createdDate).format(
                      "DD.MM.YYYY HH:MM",
                    )}
                  </p>
                </div>
              </div>
              <div className={classes.detailHead}>
                <h2>{selectedData.name}</h2>
              </div>
              <div className={classes.detailContent}>
                <p>{selectedData.shortDescription}</p>
                {selectedData.longDescription &&
                  HTMLReactParser(selectedData.longDescription)}
              </div>
            </div>
            <div className={classes.list}>
              <h4>Diğer Duyurular</h4>
              {Array.from(data).map((x, i) => {
                return (
                  <button
                    className={classes.listItem}
                    key={i}
                    onClick={() => {
                      SelectedDataHandler(x);
                    }}
                  >
                    {/*<img src="/assets/icons/ibbBlueMini.png" alt="" />*/}
                    <img src="/assets/img/logo.png" alt="" />
                    <p>{x.name}</p>
                  </button>
                );
              })}
            </div>
          </Fragment>
        )}
      </section>
    </Fragment>
  );
};
