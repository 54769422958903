import React, { Fragment, useContext, useEffect, useState } from "react";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import {
  DataTableProcess,
  DownloadExcel,
  EntityStatusHtml,
  LangHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import { CategoryCreateForm } from "../../../components/admin/category/CategoryCreateForm";
import { CategoryEditForm } from "../../../components/admin/category/CategoryEditForm";
import { useLocation, useNavigate } from "react-router-dom";
import { LangCrudForm } from "../../../components/admin/commonLang/LangCrudForm";

export const CategoryEdit = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const pageTitle = "Kategori Düzenle";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState({
    id: state.id,
    index: state.index,
    entityStatus: state.entityStatus,
  });

  const [langFormInput, setLangFormInput] = useState();
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) =>
        DataTableProcess(
          row,
          (event) => DeleteHandler(event),
          () => EditHandler(row),
          null
        ),
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Dil",
      selector: (row) => LangHtml(row.lang),
      sortable: true,
      width: "100px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setLangFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "categoryLang/List",
        "post",
        null,
        { id: state.id }
      );
      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          "category",
          method,
          null,
          formInput
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };
  const SubmitLangHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const method = langFormInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          "categoryLang",
          method,
          null,
          langFormInput
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "categoryLang",
          "delete",
          null,
          { id: event.target.id }
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ margin: "0.5rem" }}>{pageTitle}</h3>
        <button
          type="button"
          className="btnCancel"
          onClick={() => navigate(-1)}
        >
          Geri Dön
        </button>
      </div>

      <CategoryEditForm
        formInput={formInput}
        setFormInput={setFormInput}
        submitHandler={SubmitHandler}
      />
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} - Çeviri Ekle & Düzenle`}
        >
          <LangCrudForm
            formInput={langFormInput}
            setFormInput={setLangFormInput}
            submitHandler={SubmitLangHandler}
            setShowModal={setShowModal}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className="btnLangAdd"
              onClick={() => {
                ScrollTop();
                setShowModal(true);
                setLangFormInput({ parentId: state.id });
              }}
            >
              Çeviri Ekle
            </button>
            <h3 style={{ margin: "0 auto", paddingRight: "10rem" }}>
              Çeviriler
            </h3>
          </div>

          <DatatableAdmin
            data={dataList}
            columns={columns}
            // downloadExcel={DownloadExcelHandler}
          />
        </div>
      )}
    </Fragment>
  );
};
