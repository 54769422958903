import React, { Fragment, useContext, useEffect, useState } from "react";
import { DownloadExcel, FormatDateWithClock } from "../../../util/commonUtil";
import moment from "moment";
import { AppContext } from "../../../context/app-context";
import { SwalErrorMessage } from "../../../util/swalUtil";
import { MinMaxDate } from "../../../components/ui/input/MinMaxDate";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";

export const IzelmanCcSales = () => {
  const appContext = useContext(AppContext);
  const [formInput, setFormInput] = useState({
    minDate: null,
    maxDate: null,
  });
  const [dataList, setDataList] = useState();
  const [excelButtonStatus, setExcelButtonStatus] = useState(false);
  const columns = [
    {
      name: "Tip",
      selector: (row) => row.tip,
      sortable: true,
      width: "200px",
    },
    {
      name: "İşlem Numarası",
      selector: (row) => row.islemNumarasi,
      sortable: true,
      width: "500px",
    },
    {
      name: "Sipariş Numarası",
      selector: (row) => row.siparisNumarasi,
      sortable: true,
      width: "500px",
    },
    {
      name: "Sipariş Tarihi(Gün)",
      cell: (row) => {
        return row.siparisGun;
      },
      width: "150px",
    },
    {
      name: "Sipariş Tarihi(Ay)",
      cell: (row) => {
        return row.siparisAy;
      },
      width: "150px",
    },
    {
      name: "Sipariş Tarihi(Yıl)",
      cell: (row) => {
        return row.siparisYil;
      },
      width: "150px",
    },
    {
      name: "Ad Soyad / Firma Adı",
      cell: (row) => {
        return row.adSoyadUnvan;
      },
      width: "500px",
    },
    {
      name: "Vergi Numarası",
      cell: (row) => {
        return row.vergiNo;
      },
      width: "500px",
    },
    {
      name: "Cep Telefonu",
      cell: (row) => {
        return row.cepTelefon;
      },
      width: "500px",
    },
    {
      name: "E-Posta",
      cell: (row) => {
        return row.email;
      },
      width: "500px",
    },
    {
      name: "Kategori",
      cell: (row) => {
        return row.kategori;
      },
      width: "500px",
    },

    {
      name: "Birim Fiyat",
      cell: (row) => {
        return row.birimFiyat;
      },
      width: "500px",
    },
    {
      name: "Satış Adedi",
      cell: (row) => {
        return row.adet;
      },
      width: "150px",
    },
    {
      name: "Satış Adedi",
      cell: (row) => {
        return row.adet;
      },
    },

    {
      name: "Toplam Tutar",
      cell: (row) => {
        return row.toplamTutar;
      },
      width: "200px",
    },
    {
      name: "KDV",
      cell: (row) => {
        return row.kdv;
      },
      width: "150px",
    },
  ];

  useEffect(() => {
    if (formInput.minDate == null || formInput.maxDate == null) {
      setExcelButtonStatus(false);
    } else {
      setExcelButtonStatus(true);
    }
  }, [formInput]);

  const GetData = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append(
      "minDate",
      moment(formInput.minDate).format("DD/MM/YYYY HH:mm")
    );
    formData.append(
      "maxDate",
      moment(formInput.maxDate).format("DD/MM/YYYY HH:mm")
    );

    const response = await appContext.FetchHandler(
      "report/IzelmanCcSales",
      "post",
      "formData",
      formData
    );

    if (response.ok) {
      setDataList(response.data);
    }
  };

  const GetExcel = async () => {
    const formData = new FormData();
    formData.append(
      "minDate",
      moment(formInput.minDate).format("DD/MM/YYYY HH:mm")
    );
    formData.append(
      "maxDate",
      moment(formInput.maxDate).format("DD/MM/YYYY HH:mm")
    );

    const response = await appContext.FetchHandler(
      "Report/IzelmanCcSalesExcel",
      "post",
      "formData",
      formData
    );

    if (response.ok) {
      window.open(response.data);
    }
  };
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3> İzelman Kredi Kartı Satışlar</h3>
      </div>

      <button
        type="button"
        className="btnCreate"
        onClick={() => {
          excelButtonStatus ? GetExcel() : SwalErrorMessage("Tarih seçmediniz");
        }}
      >
        Excele Aktar
      </button>
      <div>
        <MinMaxDate
          formInput={formInput}
          setFormInput={setFormInput}
          submitHandler={GetData}
          buttonStatus={excelButtonStatus}
        />
      </div>
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};
