import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export const Vakif = () => {
  let form = useRef();

  //   const { acsurl, paReq, termUrl, md } = UseQueryParams();
  const [searchParams] = useSearchParams();

  const acsurl = decodeURIComponent(searchParams.get("acsurl"));
  const paReq = decodeURIComponent(searchParams.get("paReq"));
  const termUrl = decodeURIComponent(searchParams.get("termUrl"));
  const md = decodeURIComponent(searchParams.get("md"));

  useEffect(() => {
    form.current.submit();
  }, []);

  return (
    <div>
      <form
        action={encodeURI(acsurl)}
        method="post"
        id="frmMpiForm"
        name="frmMpiForm"
        ref={form}
      >
        <input type="hidden" name="PaReq" value={encodeURI(paReq)} />
        <input type="hidden" name="TermUrl" value={encodeURI(termUrl)} />
        <input type="hidden" name="MD" value={encodeURI(md)} />
        <noscript>
          <input type="submit" id="btnSubmit" value="Gönder" />
        </noscript>
      </form>
    </div>
  );
};
