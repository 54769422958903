import React, { Fragment } from "react";
import { EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";

export const CompanyCrudForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          <Input
            label="Ad"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "name",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.name != null
                  ? formInput.name
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex" }}>
          <Input
            label="MerchantId"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "merchantId",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.merchantId != null
                  ? formInput.merchantId
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  merchantId: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Password"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "password",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.password != null
                  ? formInput.password
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="TerminalNo"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "terminalNo",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.terminalNo != null
                  ? formInput.terminalNo
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  terminalNo: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Iban Banka Ad"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "ibanBank",
              type: "text",

              value:
                formInput != null && formInput.ibanBank != null
                  ? formInput.ibanBank
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  ibanBank: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Iban Hesap Ad"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "ibanName",
              type: "text",

              value:
                formInput != null && formInput.ibanName != null
                  ? formInput.ibanName
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  ibanName: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Iban"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "iban",
              type: "text",

              value:
                formInput != null && formInput.iban != null
                  ? formInput.iban
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  iban: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
