import React, { Fragment } from "react";
import classes from "./ThreeDModal.module.css";

export const ThreeDModal = ({ showModal, setShowModal, data }) => {
  const url = `/vakif?acsurl=${encodeURI(data.acsurl)}&paReq=${encodeURI(
    data.paReq
  )}&termUrl=${encodeURI(data.termUrl)}&md=${encodeURIComponent(data.md)}`;

  return (
    <Fragment>
      {showModal && (
        <Fragment>
          <div className={classes.modalBackdrop}></div>
          <div className={classes.modal}>
            <iframe
              width="100%"
              height="500px"
              src={url}
              title="3D Secure"
            ></iframe>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
