import React, { Fragment } from "react";
import {
  EntityStatusData,
  ProductQuantityTypeData,
} from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";

export const ProductVariantCreateForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
  productType,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        {formInput != null && formInput.id != null && (
          <input type="hidden" id="id" name="id" value={formInput.id} />
        )}

        <Input
          label="Ad"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "name",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.name != null ? formInput.name : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            },
          }}
        />
        <div style={{ display: "flex" }}>
          <Input
            label="Sıra"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.index != null
                  ? formInput.index
                  : 999,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  index: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Tek Seferde En Fazla Alım Adedi"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "maxBuyOneTime",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.maxBuyOneTime != null
                  ? formInput.maxBuyOneTime
                  : 5,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  maxBuyOneTime: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Görsel"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "file",
              type: "file",
              accept: "image/*",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  file: event.target.files[0],
                }));
              },
            }}
          />
        </div>
        {formInput != null && formInput.imageUrl != null && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={formInput.imageUrl} width="250" title="" alt="" />
          </div>
        )}

        <div style={{ display: "flex" }}>
          <Input
            label="Stok Tipi"
            type="select"
            style={{ width: "100%" }}
            option={ProductQuantityTypeData()}
            input={{
              id: "productQuantityType",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.productQuantityType != null
                  ? formInput.productQuantityType
                  : 0,
              onChange: (event) => {
                const quantityDiv = document.getElementById("quantityDiv");
                switch (parseInt(event.target.value)) {
                  case 0:
                    quantityDiv.style.display = "flex";
                    setFormInput((prev) => ({
                      ...prev,
                      quantity: 0,
                    }));
                    break;
                  case 1:
                    quantityDiv.style.display = "none";
                    break;
                  default:
                    break;
                }
                setFormInput((prev) => ({
                  ...prev,
                  productQuantityType:
                    event.target.value && parseInt(event.target.value),
                }));
              },
            }}
          />

          <Input
            label="Stok"
            divId="quantityDiv"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "quantity",
              type: "number",
              required: "required",
              min: "0",
              value:
                formInput != null && formInput.quantity != null
                  ? formInput.quantity
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  quantity: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Stok Kod"
            divId="stockCodeDiv"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "stockCode",
              type: "text",
              value:
                formInput != null && formInput.stockCode != null
                  ? formInput.stockCode
                  : `IZDY-${(Math.random() + 1)
                      .toString(36)
                      .substring(7)
                      .toUpperCase()}${(Math.random() + 1)
                      .toString(36)
                      .substring(7)
                      .toUpperCase()}`,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  stockCode: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Vergi Orani (%18 için 18 giriniz)"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "vatPercent",
              type: "text",
              required: "required",
              pattern: "[0-9 _,]*",
              title:
                " Örnekteki gibi giriş yapınız. Nokta yerine nokta kullanınız. (100 veya 100,25)",
              min: "1",
              value:
                formInput != null && formInput.vatPercent != null
                  ? formInput.vatPercent
                  : 18,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  vatPercent: event.target.value.replaceAll(".", ","),
                }));
              },
            }}
          />
          <Input
            label="Satış Fiyatı"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "salePrice",
              type: "text",
              required: "required",
              pattern: "[0-9 _,]*",
              title:
                " Örnekteki gibi giriş yapınız. Nokta yerine nokta kullanınız. (100 veya 100.25)",
              min: "1",
              value:
                formInput != null && formInput.salePrice != null
                  ? formInput.salePrice
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  salePrice: event.target.value.replaceAll(".", ","),
                }));
              },
            }}
          />
        </div>
        {productType.toString() === "1" && (
          <div style={{ display: "flex" }}>
            <Input
              label="Etkinlik Yeri"
              type="input"
              style={{ width: "100%" }}
              input={{
                id: "eventLocation",
                type: "text",
                value:
                  formInput != null && formInput.eventLocation != null
                    ? formInput.eventLocation
                    : "",
                onChange: (event) => {
                  setFormInput((prev) => ({
                    ...prev,
                    eventLocation: event.target.value,
                  }));
                },
              }}
            />
            <Input
              type="input"
              label="Etkinlik Tarihi"
              style={{ width: "100%" }}
              input={{
                id: "dogumTarihi",
                type: "datetime-local",
                placeholder: "Doğum Tarihi",
                required: "required",
                value:
                  formInput != null && formInput.eventDate != null
                    ? formInput.eventDate
                    : "",
                onChange: (event) => {
                  setFormInput((prev) => ({
                    ...prev,
                    eventDate: event.target.value,
                  }));
                },
              }}
            />
          </div>
        )}

        <Input
          label="Kısa Açıklama"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "shortDescription",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.shortDescription != null
                ? formInput.shortDescription
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                shortDescription: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Açıklama"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
