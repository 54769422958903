import {
  faAnchor,
  faBridgeWater,
  faBuilding,
  faBuildingFlag, faBullhorn,
  faGlassWater,
  faHandHoldingWater,
  faHome,
  faQuestion,
  faShoppingBasket,
  faStore,
  faTable,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import classes from "./SidebarAdmin.module.css";

export const SidebarAdmin = (props) => {
  const [pathName, setPathName] = useState();
  const { toggleSidebar } = useProSidebar();

  const MenuHandler = (pathName) => {
    setPathName(pathName);
    toggleSidebar();
  };

  const MenuItemHandler = (url, text, icon) => {
    return (
      <MenuItem
        active={pathName === url}
        component={<Link to={url} />}
        onClick={() => MenuHandler(url)}
      >
        <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
          <FontAwesomeIcon
            icon={icon}
            className={classes.menuItemContentIcon}
          />
          {props.showText && (
            <span className={classes.menuItemContentText}>{text}</span>
          )}
        </div>
      </MenuItem>
    );
  };

  return (
    <Sidebar
      breakPoint="md"
      backgroundColor="#1b437e"
      rootStyles={{
        border: "none",
        backgroundColor: "#043657",
        boxShadow: "0 2px 15px rgba(0, 0, 0, .3)",
        height: "100vh",
      }}
    >
      <div className={classes.logo}>
        <img
          src="/assets/img/logo.png"
          alt="logo"
          style={props.showText ? { width: "70px" } : { width: "50px" }}
        ></img>
        {/* {props.showText && (
          <span
            style={{ marginLeft: "1rem", fontWeight: "bold", color: "white" }}
          >
            İktisat Kongresi
          </span>
        )} */}
      </div>

      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // if (level === 0) {

            // }
            return {
              color: disabled ? "#ccc" : "#fff",
              "&:hover": {
                backgroundColor: "#1670ad",
              },
              backgroundColor: active && "#1670ad",
              fontWeight: "bold",
              fontSize: ".9rem",
            };
          },
        }}
        rootStyles={{ paddingBottom: "2rem" }}
      >
        {MenuItemHandler("/admin", "Dashboard", faTable)}
        {MenuItemHandler("/admin/homePage", "Anasayfa", faHome)}
        {MenuItemHandler("/admin/announcement", "Duyurular", faBullhorn)}
        {MenuItemHandler("/admin/support", "Destekler", faTable)}
        {MenuItemHandler(
          "/admin/webApplyAdmin",
          "Web Başvuru Formları",
          faTable
        )}
        {MenuItemHandler("/admin/company", "Şirketler", faBuilding)}
        {MenuItemHandler("/admin/product", "Ürünler", faStore)}
        {MenuItemHandler("/admin/orderProduct", "Siparişler", faShoppingBasket)}
        {MenuItemHandler("/admin/faq", "SSS", faQuestion)}
        {MenuItemHandler("/admin/ourServices", "Hizmetlerimiz", faBuildingFlag)}

        <SubMenu
          label="İzsu"
          icon={
            <FontAwesomeIcon icon={faStore} className="menuItemContentIcon" />
          }
          active={pathName === "/izsu"}
        >
          {MenuItemHandler("/admin/izsu/izsuApply", "Başvurular", faTable)}
          {MenuItemHandler(
            "/admin/izsu/izsuPaidInvoice",
            "Ödenmiş Faturalar",
            faTable
          )}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};
