import React, { Fragment, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "moment/locale/tr";
import { Editor } from "@tinymce/tinymce-react";
import classes from "./Input.module.css";
import PhoneInput from "react-phone-input-2";
import trPhone from "react-phone-input-2/lang/tr.json";
import "react-phone-input-2/lib/high-res.css";
import ReactInputMask from "react-input-mask";
import { ConvertCharset } from "../../../util/commonUtil";

export const Input = (props) => {
  useEffect(() => {
    const readOnlyElements = document.querySelectorAll(".readonly");
    if (readOnlyElements.length > 0) {
      for (const item of readOnlyElements) {
        item.addEventListener("keypress", (event) => {
          event.preventDefault();
        });
      }
    }
  }, []);

  const animatedComponents = makeAnimated();

  const InputHandler = () => {
    switch (props.type) {
      case "input":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label
                style={{
                  visibility: props.input.type === "checkbox" && "hidden",
                  display: props.disableLabel ? "none" : "flex",
                }}
                htmlFor={props.input.id}
              >
                {props.label}
              </label>
              <div
                className={
                  props.input.type === "checkbox" ? classes.checkboxDiv : ""
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  ...props.checkboxStyle,
                }}
              >
                <input
                  {...props.input}
                  name={props.input.id}
                  defaultChecked={
                    props.input.type === "checkbox" && props.checked
                  }
                />

                {props.input.type === "checkbox" && (
                  <label
                    htmlFor={props.input.id}
                    style={{ marginBottom: ".1rem" }}
                  >
                    {props.label}
                  </label>
                )}
              </div>
              {props.input.info && (
                <span className={classes.info}>{props.input.info}</span>
              )}
              {!props.validation?.status && (
                <span className={classes.error}>
                  {props.validation?.message}
                </span>
              )}
            </div>
          </Fragment>
        );
      case "radioGrup":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label>{props.label}</label>
              <div className={classes.radioGrup}>
                {props.option.map((item, index) => {
                  return (
                    <div key={item.id} className={classes.radioGrupElement}>
                      <input
                        type="radio"
                        id={`${props.input.name}${item.id}`}
                        value={item.value}
                        {...props.input}
                        defaultChecked={item.checked}
                      />
                      <label htmlFor={`${props.input.name}${item.id}`}>
                        {item.text}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </Fragment>
        );
      case "phoneNumber":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <PhoneInput
                localization={trPhone}
                placeholder={"+90 *** *** ** **"}
                inputProps={{
                  id: props.input.id,
                  name: props.input.id,
                  required: true,
                  style:
                    props.input.style !== undefined
                      ? props.input.style
                      : { padding: "0 4rem" },
                }}
                value={props.input.value}
                country="tr"
                onChange={props.input.onChange}
                {...props}
              />
              {!props.validation?.status && (
                <span className={classes.error}>
                  {props.validation?.message}
                </span>
              )}
            </div>
          </Fragment>
        );
      case "select":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <select {...props.input} name={props.input.id}>
                {props.value != undefined && props.value == "" ? (
                  <option selected value="">
                    {props.firstOption ? props.firstOption : "Seçim yapınız"}
                  </option>
                ) : (
                  <option value="">
                    {props.firstOption ? props.firstOption : "Seçim yapınız"}
                  </option>
                )}

                {props.option != null &&
                  props.option.length > 0 &&
                  props.option.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.text && item.text} {item.label && item.label}
                      </option>
                    );
                  })}
              </select>
            </div>
          </Fragment>
        );
      case "selectSearch":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <Select
                components={animatedComponents}
                options={props.option}
                {...props.input}
                name={props.input.id}
                value={props.value}
                isMulti={props.isMulti}
                noOptionsMessage={() => "Veri Yok"}
                placeholder={`Seçim${props.isMulti ? "(ler)" : ""} yapınız`}
                filterOption={(option, query) => {
                  return ConvertCharset(option.data.label).includes(
                    ConvertCharset(query),
                  );
                }}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    border: "1px solid #c1bebe",
                    borderRadius: "10px",
                    height: "auto",
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: "auto",
                    paddingTop: "0",
                    marginTop: "0",
                  }),
                  input: (provided, state) => ({
                    ...provided,
                    margin: "0",
                    padding: "0",
                  }),
                  // indicatorSeparator: (state) => ({
                  //
                  // }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: "45px",
                  }),
                  container: (provided) => ({
                    ...provided,
                  }),
                  menu: (provided) => ({
                    ...provided,
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected },
                  ) => {
                    return {
                      ...styles,

                      backgroundColor: isDisabled
                        ? "#ccc"
                        : isSelected
                          ? data.color
                          : isFocused
                            ? "rgba(17,64,114,0.73)"
                            : "#fff",
                      color: isDisabled
                        ? "#ccc"
                        : isSelected
                          ? "#114072"
                          : isFocused
                            ? "#fff"
                            : data.color,
                      cursor: isDisabled ? "not-allowed" : "default",
                      ":active": {
                        ...styles[":active"],
                        backgroundColor: !isDisabled
                          ? isSelected
                            ? data.color
                            : "#114072"
                          : undefined,
                      },
                    };
                  },
                }}
              />
            </div>
          </Fragment>
        );
      case "checkboxGrup":
        return (
          <Fragment>
            <div
              className={`
            ${classes.inputGroup} ${
              props.className !== undefined ? props.className : ""
            }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id} style={{ marginBottom: ".1rem" }}>
                {props.label}
              </label>
              <div className={classes.radioGrup}>
                {props.option.map((item, index) => {
                  return (
                    <div
                      key={item.id != undefined ? item.id : item.value}
                      className={classes.radioGrupElement}
                    >
                      <input
                        {...props.input}
                        id={`${item.value}${item.text}`}
                        value={item.value}
                        required={false}
                        defaultChecked={item.checked}
                      />
                      <label htmlFor={`${item.value}${item.text}`}>
                        {item.text}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </Fragment>
        );
      case "textArea":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <textarea
                rows={props.rows ? props.rows : 4}
                cols={props.cols ? props.cols : 50}
                style={{ borderRadius: ".7rem" }}
                {...props.input}
                name={props.input.id}
              ></textarea>
              {!props.validation?.status && (
                <span className={classes.error}>
                  {props.validation?.message}
                </span>
              )}
            </div>
          </Fragment>
        );
      case "mask":
        return (
          <Fragment>
            <div
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={props.style}
              id={props.divId}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <ReactInputMask
                mask={props.mask}
                {...props.input}
                name={props.input.id}
              />
            </div>
          </Fragment>
        );
      case "richText":
        return (
          <Fragment>
            <div
              id={props.divId}
              className={`
              ${classes.inputGroup} ${
                props.className !== undefined ? props.className : ""
              }`}
              style={{ width: "100%", zIndex: 1 }}
            >
              <label htmlFor={props.input.id}>{props.label}</label>
              <Editor
                {...props.input}
                textareaName={props.input.id}
                init={{
                  language: "tr",
                  height: 500,
                  menubar: true,

                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                apiKey="6052vu5hq9fpep2g8c3dkpfes83286fxldfbclvifyr2twic"
              />
            </div>
          </Fragment>
        );

      default:
        break;
    }
  };

  return <Fragment>{InputHandler()}</Fragment>;
};
