import React from "react";
import { Input } from "../../ui/input/Input";
import { EntityStatusData, SupportTypeData } from "../../../util/commonUtil";
import { Fragment } from "react";

export const FaqCreateForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          {formInput != null && formInput.id != null && (
            <input id="id" name="id" type="hidden" value={formInput.id} />
          )}

          <Input
            label="Soru"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "name",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.name != null
                  ? formInput.name
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Sıra"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.index != null
                  ? formInput.index
                  : 999,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  index: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        <Input
          label="Cevap"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
