import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";

export const WebApplyAdminCreateForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          <Input
            label="KampanyaBilgileriId"
            type="input"
            input={{
              id: "kampanyaBilgileriId",
              type: "number",
              required: "required",
              value:
                formInput != null && formInput.kampanyaBilgileriId != null
                  ? formInput.kampanyaBilgileriId
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  kampanyaBilgileriId: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="YardimTurleriId"
            type="input"
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.yardimTurleriId != null
                  ? formInput.yardimTurleriId
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  yardimTurleriId: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="BasvuruSekliId"
            type="input"
            input={{
              id: "basvuruSekliId",
              type: "number",
              required: "required",
              value:
                formInput != null && formInput.basvuruSekliId != null
                  ? formInput.basvuruSekliId
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  basvuruSekliId: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="BasvuruTipiId"
            type="input"
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.basvuruTipiId != null
                  ? formInput.basvuruTipiId
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  basvuruTipiId: event.target.value,
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex" }}>
          <Input
            checkboxStyle={{ alignItems: "center" }}
            label="TC Listesi Girilecek Mi? (Çocuk TC vb.)"
            type="input"
            checked={
              formInput != null &&
              formInput.isTcList != null &&
              formInput.isTcList
            }
            input={{
              id: "isTcList",
              type: "checkbox",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  isTcList: event.target.checked,
                }));
              },
            }}
          />
          <Input
            checkboxStyle={{ alignItems: "center" }}
            label="Yardım Yapılacak Adres Değiştirilebilir Mi?"
            type="input"
            checked={
              formInput != null &&
              formInput.isAddressChangeAble != null &&
              formInput.isAddressChangeAble
            }
            input={{
              id: "isAddressChangeAble",
              type: "checkbox",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  isAddressChangeAble: event.target.checked,
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
