import React, { Fragment, useContext, useEffect, useState } from "react";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import {
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import { ProductCreateForm } from "../../../components/admin/product/ProductCreateForm";
import { Link } from "react-router-dom";

export const Product = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Ürün";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState();
  const [dataList, setDataList] = useState();
  const [companyDataList, setCompanyDataList] = useState();
  const [supportDataList, setSupportDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "300px",
      cell: (row) => (
        <div className="processBox">
          <Link
            className="dataTableProcessCyan"
            style={{ marginRight: ".7rem" }}
            to="/admin/product/variant"
            state={row}
          >
            Varyantlar
          </Link>
          {DataTableProcess(
            row,
            (event) => DeleteHandler(event),
            () => EditHandler(row),
            null
          )}
        </div>
      ),
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
    {
      name: "Destek",
      selector: (row) => row.supportName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Şirket",
      selector: (row) => row.companyName,
      sortable: true,
      width: "200px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setShowModal(true);
    setFormInput(data);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "product",
        "get",
        null,
        null,
        "admin"
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  useEffect(() => {
    const GetCompanyDataList = async () => {
      const response = await appContext.FetchHandler(
        "company",
        "get",
        null,
        null
      );

      if (response.ok) {
        const tempData = [];
        for (const item of response.data) {
          if (
            item.entityStatus === 0 &&
            item.name != null &&
            item.name.length > 0
          )
            tempData.push({ value: item.id, text: item.name });
        }
        setCompanyDataList(tempData);
      }
    };
    const GetSupportDataList = async () => {
      const response = await appContext.FetchHandler(
        "support/ProductSupports",
        "get",
        null,
        null
      );

      if (response.ok) {
        const tempData = [];
        for (const item of response.data) {
          if (
            item.entityStatus === 0 &&
            item.name != null &&
            item.name.length > 0
          )
            tempData.push({ value: item.id, text: item.name });
        }
        setSupportDataList(tempData);
      }
    };
    GetCompanyDataList();
    GetSupportDataList();
  }, []);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";

        const response = await appContext.FetchHandler(
          "product",
          method,
          null,
          formInput
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "product",
          "delete",
          null,
          { id: event.target.id }
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput();
            setShowModal(true);
          }}
        >
          {pageTitle} Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle `}
        >
          <ProductCreateForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            companyDataList={companyDataList}
            supportDataList={supportDataList}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
