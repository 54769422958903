import React from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./AppointmentSearch.module.css";
import moment from "moment";

export const AppointmentSearch = ({
  appointmentForm,
  setAppointmentForm,
  submitHandler,
  placeOfServiceData,
  appointmentDateList,
  CreateHandler,
  title,
}) => {
  const date = new Date();

  const ShowPicker = () => {
    const inputDateElement = document.querySelector('input[type="date"]');
    inputDateElement.showPicker();
  };

  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <h3>{title}</h3>
        <Input
          label="Çamaşırhane"
          type="select"
          style={{ width: "100%" }}
          option={placeOfServiceData}
          input={{
            id: "esyaTuru",
            required: "required",
            value: appointmentForm.hizmetYeriId,
            onChange: (event) => {
              setAppointmentForm((prev) => ({
                ...prev,
                hizmetYeriId: event.target.value,
              }));
            },
          }}
        />
        {appointmentForm.hizmetYeriId != "" && (
          <Input
            type="input"
            label="Tarih"
            style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
            input={{
              id: "tarih",
              type: "date",
              placeholder: "Tarih",
              required: "required",
              value: appointmentForm.tarih,
              min: moment(date).format("YYYY-MM-DD"),
              onClick: () => ShowPicker(),
              onChange: (event) => {
                setAppointmentForm((prev) => ({
                  ...prev,
                  tarih: event.target.value,
                }));
              },
            }}
          />
        )}
      </form>

      <div id="dateList" className={classes.dateList}>
        {appointmentDateList && (
          <Fragment>
            <h4>Saat Seçiniz</h4>
            <div className={classes.groupBox}>
              {appointmentDateList.map((x, i) => {
                return (
                  <div className={classes.box} key={i}>
                    <h5>{x.aracAdi}</h5>

                    {x.randevuListesi.length > 0 &&
                      x.randevuListesi.map((y, j) => {
                        return (
                          <div
                            className={classes.boxDate}
                            key={j}
                            onClick={() =>
                                y.aktif && CreateHandler(y.randevuId.replace(".0", ""))
                            }
                          >
                            {/* <div className={classes.dateSelected}>08:00</div> */}
                            {y.aktif ? (
                              <div className={classes.date}>
                                {moment(y.tarihSaat).format("HH:mm")}
                              </div>
                            ) : (
                              <div className={classes.dateDisabled}>
                                {moment(y.tarihSaat).format("HH:mm")}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
