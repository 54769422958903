import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { ConvertCharset } from "../../../util/commonUtil";
import classes from "./DatatableAdmin.module.css";
import { DatatableAdminFilter } from "./DatatableAdminFilter";

export const DatatableAdmin = ({ data, columns, downloadExcel }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data.filter((item) =>
    item.name != null ? ConvertCharset(item.name).includes(filterText) : data
  );

  const paginationComponentOptions = {
    rowsPerPageText: "Sayfa Başına Gösterim",
    rangeSeparatorText: "Toplam",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tümü",
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className={classes.items}>
        {downloadExcel && (
          <button className={classes.btnExcel} onClick={downloadExcel}>
            Excel
          </button>
        )}

        <DatatableAdminFilter
          onFilter={(e) => setFilterText(ConvertCharset(e.target.value))}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  createTheme(
    "custom",
    {
      text: {
        primary: "#043657",
        secondary: "#043657",
      },
      background: {
        default: "#f9f9f9",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(4, 54, 87,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      highlightOnHover: {
        default: "#1670ad",
      },
    },
    "dark"
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
        borderRadius: "0rem",
        fontSize: "13px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#1b437e",
        color: "#fff",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        borderRadius: "0",
        border: "1px solid #ccc",
        justifyContent: "center",
      },
    },
    noData: { style: { margin: "1rem" } },
  };

  return (
    <div className={classes.datatable}>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="600px"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        paginationComponentOptions={paginationComponentOptions}
        noDataComponent="Veri bulunamadı"
        highlightOnHover
        theme="custom"
        customStyles={customStyles}
      />
    </div>
  );
};
