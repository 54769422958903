import React from "react";

export const AppContext = React.createContext({
  isLoggedIn: false,
  isMobile: false,
  loader: false,
  user: null,
  token: null,
  lang: null,
  supportData: null,
  gRecapReady: false,
  announcementData: [],
  showAnnouncment: false,
  setShowAnnouncment: () => {},
  setLang: () => {},
  setLoader: () => {},
  LoginHandler: (data) => {},
  LogoutHandler: () => {},
  FetchHandler: (url, method, contentType, data) => {},
  // GetLang: (name) => {},
  CheckTCWithBD: (data) => {},
  CheckTC: (data) => {},
  GetGRecapToken: () => {},
});
