import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import classes from "./AdminMain.module.css";

export const AdminMain = (props) => {
  return (
    <Fragment>
      <main className={classes.main}>
        <Outlet />
      </main>
    </Fragment>
  );
};
