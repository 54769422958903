import React, { Fragment } from "react";
import { EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";

export const CategoryEditForm = ({
  formInput,
  setFormInput,
  submitHandler,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          <Input
            label="Sıra"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "index",
              type: "number",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.index != null
                  ? formInput.index
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  index: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? formInput.entityStatus
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
        </div>
      </form>
    </Fragment>
  );
};
