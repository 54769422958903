import React, { Fragment } from "react";
import classes from "./AskidaStats.module.css";

export const AskidaStats = ({ data }) => {
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.box}>
          <div className={classes.boxTitle}>Askıda Bekleyen {data.name}</div>
          <div className={classes.boxResult}>{data.waiting}</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxTitle}>Askıdan Alınan {data.name}</div>
          <div className={classes.boxResult}>{data.done}</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxTitle}>Toplam Ödenen {data.name}</div>
          <div className={classes.boxResult}>{data.amount}</div>
        </div>
      </div>
    </Fragment>
  );
};
