import React, { Fragment, useContext, useEffect, useState } from "react";
import { CompanyCrudForm } from "../../../components/admin/company/CompanyCrudForm";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import {
  DataTableImage,
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import { OurServicesCrudForm } from "../../../components/admin/ourServices/OurServicesCrudForm";
import { CommonCrudForm } from "../../../components/admin/commonCrudForm/CommonCrudForm";

export const AnnouncementAdmin = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Duyuru";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState();
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) =>
        DataTableProcess(
          row,
          (event) => DeleteHandler(event),
          () => EditHandler(row),
          null,
        ),
    },
    {
      name: "Görsel",
      selector: (row) => DataTableImage(row.imageUrl),
      sortable: true,
      width: "100px",
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
    {
      name: "Kısa Açıklama",
      selector: (row) => row.shortDescription,
      sortable: true,
      width: "auto",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();

    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "Announcement",
        "get",
        null,
        null,
        "admin",
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData(document.getElementById("form"));
        formData.append("id", formInput.id);
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          "Announcement",
          method,
          "formData",
          formData,
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "Announcement",
          "delete",
          null,
          { id: event.target.id },
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput();
            setShowModal(true);
          }}
        >
          {pageTitle} Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>

      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle - Düzenle`}
        >
          <CommonCrudForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            isMedia={true}
            isPopup={true}
          />
        </ModalAdmin>
      )}
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};
