import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwalSuccess } from "../../../util/swalUtil";
import { ChangePasswordForm } from "../../../components/admin/changePasswordForm/ChangePasswordForm";
import classes from "./ChangePassword.module.css";

export const ChangePassword = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Şifre Değiştir";
  const [formInput, setFormInput] = useState(null);

  const SubmitHandler = async (event) => {
    event.preventDefault();
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "auth/changePassword",
          "post",
          null,
          formInput,
        );

        if (response.ok) {
          event.target.reset();
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>
      <div className={classes.changePassDiv}>
        <ChangePasswordForm
          formInput={formInput}
          setFormInput={setFormInput}
          submitHandler={SubmitHandler}
        />
      </div>
    </Fragment>
  );
};
