import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import classes from "./Home.module.css";
import HTMLReactParser from "html-react-parser";
import { RgbaConvert } from "../../../util/commonUtil";
import { BasvuruYapForm } from "../basvuruYapForm/BasvuruYapForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainSlider from "../../../components/client/mainSlider/MainSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFlushed } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { PopupModal } from "../../../components/client/popupModal/PopupModal";

export const Home = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const [data, setData] = useState();

  const [formInput, setFormInput] = useState({
    basvuruId: null,
    tcKimlikNo: null,
    dogumTarihi: null,
    cepTelefonu: null,
    kvkk: null,
    rizaMetni: null,
  });
  const [getSupportOpt, setGetSupportOpt] = useState();
  const [getSupportData, setGetSupportData] = useState();

  useEffect(() => {
    const layout = document.getElementById("layout");
    layout.style.backgroundColor = "#1b437e";
  }, []);

  useEffect(() => {
    GetDataList();
  }, []);

  const GetDataList = async () => {
    const response = await appContext.FetchHandler(
      "homePage",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (appContext.supportData != undefined || appContext.supportData != null) {
      const _tempDataOpt = [];
      Array.from(appContext.supportData).forEach((x) => {
        if (x.entityStatus == 0 || x.entityStatus == 3) {
          if (x.supportType == 0 || x.supportType == 1) {
            if (
              x.supportType != 1 &&
              x.id !== process.env.REACT_APP_SUT_KUZUSU_ID &&
              x.id !== process.env.REACT_APP_KURBAN_ID
            ) {
              _tempDataOpt.push({ value: x.id, text: x.name });
            }
          }
        }
      });
      setGetSupportOpt(_tempDataOpt);

      const tempData = Array.from(appContext.supportData).filter(
        (x) => x.supportType == 0 || x.supportType == 3 || x.supportType == 5,
      );

      setGetSupportData(tempData);
    }
  }, [appContext.supportData]);

  return (
    <Fragment>
      <div className={classes.socialBox}>
        <button
          title="Facebook"
          onClick={() => {
            Swal.fire({
              text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Facebook adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Facebook adresine mi gitmek istersiniz?",
              showCancelButton: true,
              confirmButtonColor: "#004684",
              confirmButtonText: "İzmir Büyükşehir Belediyesi",
              cancelButtonColor: "#0467be",
              cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
            }).then(async (result) => {
              if (result.isConfirmed) {
                window.open(
                  "https://www.facebook.com/izmirbuyuksehirbel",
                  "_blank",
                );
              } else if (
                result.isDismissed &&
                result.dismiss.toString() === "cancel"
              ) {
                window.open(
                  "https://www.facebook.com/izbbsosyalhizmetlerdairesibaskanligi",
                  "_blank",
                );
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faFacebook} className={classes.socialIcon} />
        </button>
        <button
          title="X"
          onClick={() => {
            Swal.fire({
              text: "Sosyal Hizmetler Dairesi Başkanlığı'nın X adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin X adresine mi gitmek istersiniz?",
              showCancelButton: true,
              confirmButtonColor: "#004684",
              confirmButtonText: "İzmir Büyükşehir Belediyesi",
              cancelButtonColor: "#0467be",
              cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
            }).then(async (result) => {
              if (result.isConfirmed) {
                window.open("https://twitter.com/izmirbld", "_blank");
              } else if (
                result.isDismissed &&
                result.dismiss.toString() === "cancel"
              ) {
                window.open("https://x.com/izBBsosyalhizdb", "_blank");
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faXTwitter} className={classes.socialIcon} />
        </button>
        <button
          title="Instagram"
          onClick={() => {
            Swal.fire({
              text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Instagram adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Instagram adresine mi gitmek istersiniz?",
              showCancelButton: true,
              confirmButtonColor: "#004684",
              confirmButtonText: "İzmir Büyükşehir Belediyesi",
              cancelButtonColor: "#0467be",
              cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
            }).then(async (result) => {
              if (result.isConfirmed) {
                window.open(
                  "https://www.instagram.com/izmirbuyuksehirbelediyesi/",
                  "_blank",
                );
              } else if (
                result.isDismissed &&
                result.dismiss.toString() === "cancel"
              ) {
                window.open(
                  "https://www.instagram.com/izbbsosyalhizmetlerdb/",
                  "_blank",
                );
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faInstagram} className={classes.socialIcon} />
        </button>
        <button
          title="Linkedin"
          onClick={() => {
            Swal.fire({
              text: "Sosyal Hizmetler Dairesi Başkanlığı'nın Linkedin adresine mi, yoksa İzmir Büyükşehir Belediyesi'nin Linkedin adresine mi gitmek istersiniz?",
              showCancelButton: true,
              confirmButtonColor: "#004684",
              confirmButtonText: "İzmir Büyükşehir Belediyesi",
              cancelButtonColor: "#0467be",
              cancelButtonText: "Sosyal Hizmetler Dairesi Başkanlığı",
            }).then(async (result) => {
              if (result.isConfirmed) {
                window.open(
                  "https://tr.linkedin.com/company/i̇zmir-büyükşehir-belediyesi",
                  "_blank",
                );
              } else if (
                result.isDismissed &&
                result.dismiss.toString() === "cancel"
              ) {
                window.open(
                  "https://www.linkedin.com/company/i̇zbb-sosyal-hizmetler-dairesi-başkanlığı/",
                  "_blank",
                );
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faLinkedin} className={classes.socialIcon} />
        </button>
      </div>
      {data && (
        <Fragment>
          <section
            className={classes.mainSection}
            style={{
              background: `linear-gradient( to bottom, ${RgbaConvert(
                data?.color1,
                0.5,
              )}, 
          ${RgbaConvert(data?.color2, 0.5)}),url('${data?.imageUrl}')`,
            }}
          >
            <div className={classes.supportBox}>
              <Link className={classes.supportItem} to="/destekAl">
                <img src="/assets/icons/destekAl.png" alt="" />
                <h2>DESTEK AL</h2>
              </Link>
              <Link className={classes.supportItem} to="/destekVer">
                <img src="/assets/icons/destekVer.png" alt="" />
                <h2>DESTEK VER</h2>
              </Link>
            </div>
            <div className={classes.contentContainer}>
              <h1>{data && data?.name}</h1>
              {data &&
                data?.longDescription &&
                HTMLReactParser(data.longDescription)}
            </div>
            <div className={classes.baskan}>
              <img src="/assets/img/brand/sosyalHizmetlerWhite.png" alt="" />
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};
