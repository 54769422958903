import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./GetSupport.module.css";
import { AppContext } from "../../../context/app-context";
import { SupportBox } from "../../../components/client/supportBox/SupportBox";

export const GetSupport = () => {
  const appContext = useContext(AppContext);
  const [supportData, setSupportData] = useState();

  useEffect(() => {
    if (appContext.supportData) {
      const tempData = Array.from(appContext.supportData).filter(
        (x) =>
          x.supportType == 0 ||
          x.supportType == 1 ||
          x.supportType == 3 ||
          x.supportType == 5,
      );
      setSupportData(tempData);
    }
  }, [appContext.supportData]);

  return (
    <Fragment>
      <section className={classes.mainSection}>
        <div className={classes.headBox}>
          <div className={classes.headTitle}>
            <img src="/assets/icons/destekAl.png" alt="" />
            <h1>DESTEK AL</h1>
          </div>
          <p></p>
        </div>
        <div className={classes.supportContainer}>
          {supportData &&
            supportData.map((x, i) => {
              return <SupportBox key={i} data={x} />;
            })}
        </div>
      </section>
    </Fragment>
  );
};
