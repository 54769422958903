import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./AppointmentMain.module.css";
import { AppContext } from "../../../context/app-context";
import { SupportBox } from "../../../components/client/supportBox/SupportBox";

export const AppointmentMain = () => {
  const appContext = useContext(AppContext);
  const [supportData, setSupportData] = useState();

  useEffect(() => {
    if (appContext.supportData) {
      const tempData = Array.from(appContext.supportData).filter(
        (x) => x.supportType == 1,
      );
      setSupportData(tempData);
    }
  }, [appContext.supportData]);

  return (
    <Fragment>
      <section className={classes.mainSection}>
        <div className={classes.headBox}>
          <div className={classes.headTitle}>
            <img src="/assets/icons/destekVer.png" alt="" />
            <h1>RANDEVU HİZMETLERİ</h1>
          </div>
          <p>
            İzmir Üniversitelerinde Kayıtlı Öğrenciyseniz Randevu Hizmetlerinden
            Faydalanabilirsiniz.
          </p>
        </div>
        <div className={classes.supportContainer}>
          {supportData &&
            supportData.map((x, i) => {
              return <SupportBox key={i} data={x} isGiveSupport={true} />;
            })}
        </div>
      </section>
    </Fragment>
  );
};
