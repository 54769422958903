import React from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./BasvuruSorgulaForm.module.css";
import { Validation } from "../../ui/input/Validation";

export const BasvuruSorgulaForm = ({
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
  submitHandler,
  supportData,
}) => {
  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <Input
          type="select"
          style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
          label="Başvuru"
          option={supportData}
          validation={
            formInput.basvuruId !== null && formInputIsValid.basvuruId
          }
          input={{
            id: "basvuruId",
            placeholder: "Başvuru",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                basvuruId: Validation("Başvuru", value, { isRequired: true }),
              }));

              setFormInput((prev) => ({
                ...prev,
                basvuruId: value,
              }));
            },
          }}
        />
        <Input
          type="input"
          style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
          label="T.C. Kimlik No"
          validation={
            formInput.tcKimlikNo != null && formInputIsValid.tcKimlikNo
          }
          input={{
            id: "tcKimlikNo",
            placeholder: "T.C. Kimlik No",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                tcKimlikNo: Validation("T.C. Kimlik No", value, {
                  isRequired: true,
                  type: 1,
                  min: 11,
                  max: 11,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                tcKimlikNo: value,
              }));
            },
          }}
        />

        <Input
          type="input"
          label="Doğum Tarihi"
          style={{ width: "100%", color: "#1B437E", fontWeight: "600" }}
          validation={
            formInput.dogumTarihi != null && formInputIsValid.dogumTarihi
          }
          input={{
            id: "dogumTarihi",
            type: "date",
            placeholder: "Doğum Tarihi",
            value: formInput.dogumTarihi != null ? formInput.dogumTarihi : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                dogumTarihi: Validation("Doğum Tarihi", value, {
                  isRequired: true,
                  type: 3,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                dogumTarihi: value,
              }));
            },
          }}
        />

        <div className={classes.formButton}>
          <button>SORGULA</button>
        </div>
      </form>
    </Fragment>
  );
};
