import React, { useEffect } from "react";
import { Fragment } from "react";
import classes from "./Appointment.module.css";
import { ContentHead } from "../../../components/client/contentHead/ContentHead";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AppointmentSearch } from "../../../components/client/appointmentSearch/AppointmentSearch";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import moment from "moment";
import Swal from "sweetalert2";
import { AppointmentMine } from "../../../components/client/appointmentMine/AppointmentMine";
import { SwalSuccessMessage } from "../../../util/swalUtil";

export const Appointment = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const [isAppointmentCreate, setIsAppointmentCreate] = useState(true);
  const [placeOfServiceData, setPlaceOfServiceData] = useState([]);
  const navigate = useNavigate();
  const date = new Date();
  const appointmentFormJson = {
    gToken: "",
    basvuruId: state.formInput.basvuruId.replace(".0", ""),
    yardimTuru: state.formInput.yardimTurleriId,
    hizmetYeriId: "",
    tcKimlikNo: state.formInput.tcKimlikNo,
    dogumTarihi: state.formInput.dogumTarihi,
    tarih: moment(date).format("YYYY-MM-DD"),
  };
  const [appointmentForm, setAppointmentForm] = useState(appointmentFormJson);
  const [appointmentDateList, setAppointmentDateList] = useState(null);
  const [userAppointmentsData, setUserAppointmentsData] = useState(null);

  useEffect(() => {
    const layout = document.getElementById("layout");
    layout.style.backgroundColor = "#edf5ff";
  }, []);

  const AppointmentBtnHandler = (status) => {
    switch (status) {
      case 0:
        setIsAppointmentCreate(true);
        break;
      case 1:
        setIsAppointmentCreate(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (placeOfServiceData.length === 0) {
      appContext.GetGRecapToken().then(async (gToken) => {
        const response = await appContext.FetchHandler(
          "appointment/placeOfService",
          "post",
          "",
          { gToken: gToken, yardimTuru: state.formInput.yardimTurleriId },
        );

        if (response.ok) {
          const _tempData = [];

          Array.from(response.data.data).forEach((x) => {
            _tempData.push({ value: x.id.replace(".0", ""), text: x.adi });
          });
          setPlaceOfServiceData(_tempData);
        }
      });
    }
    UserAppointmentsHandler();
  }, [appContext.gRecapReady]);

  useEffect(() => {
    const AppointmentSearchHandler = () => {
      appContext.GetGRecapToken().then(async (gToken) => {
        appointmentForm.gToken = gToken;
        const response = await appContext.FetchHandler(
          "appointment/search",
          "post",
          "",
          appointmentForm,
        );

        if (response.ok) {
          setAppointmentDateList(response.data.data);
        }
      });
    };
    appointmentForm.hizmetYeriId != "" && AppointmentSearchHandler();
  }, [appointmentForm]);

  const CreateHandler = (id) => {
    Swal.fire({
      title: "Randevu Onay",
      text: "Randevunuz oluşturulacak onaylıyor musunuz?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        appContext.GetGRecapToken().then(async (gToken) => {
          const response = await appContext.FetchHandler(
            "appointment/save",
            "post",
            "",
            {
              ...state.formInput,
              gToken: gToken,
              yardimTuruId: appointmentForm.yardimTuru,
              randevuId: id,
            },
          );

          if (response.ok) {
            UserAppointmentsHandler();
            Swal.fire({
              title: "Randevu Al",
              text: "Randevunuz oluşturulmuştur.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
              }
            });
          }
        });
      } else {
        return;
      }
    });
  };

  const CancelHandler = (id) => {
    Swal.fire({
      title: "İptal",
      text: "Randevunuz iptal edilecek emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        appContext.GetGRecapToken().then(async (gToken) => {
          const response = await appContext.FetchHandler(
            "appointment/cancel",
            "post",
            "",
            {
              gToken: gToken,
              randevuId: id,
              tcKimlikNo: state.formInput.tcKimlikNo,
            },
          );

          if (response.ok) {
            UserAppointmentsHandler();
            Swal.fire({
              title: "Randevu İptal",
              text: "Randevunuz iptal edilmiştir.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
              }
            });
          }
        });
      } else {
        return;
      }
    });
  };

  const UserAppointmentsHandler = () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      const response = await appContext.FetchHandler(
        "appointment/UserAppointments",
        "post",
        "",
        {
          gToken: gToken,
          yardimTuruId: appointmentForm.yardimTuru,
          tcKimlikNo: appointmentForm.tcKimlikNo,
        },
      );

      if (response.ok) {
        setUserAppointmentsData(response.data.data);
      }
    });
  };

  return (
    <Fragment>
      <section className={classes.section}>
        <ContentHead data={state.state} formTitle />
        <div className={classes.buttonGroup}>
          <button
            className={
              isAppointmentCreate ? classes.btnBlue : classes.btnDisabled
            }
            onClick={() => AppointmentBtnHandler(0)}
          >
            Randevu Oluştur
          </button>
          <button
            className={
              !isAppointmentCreate ? classes.btnBlue : classes.btnDisabled
            }
            onClick={() => AppointmentBtnHandler(1)}
          >
            Randevularım
          </button>
        </div>
        {isAppointmentCreate ? (
          placeOfServiceData.length > 0 && (
            <AppointmentSearch
              appointmentForm={appointmentForm}
              setAppointmentForm={setAppointmentForm}
              placeOfServiceData={placeOfServiceData}
              appointmentDateList={appointmentDateList}
              CreateHandler={CreateHandler}
              title={state.state.name}
            />
          )
        ) : (
          <AppointmentMine
            CancelHandler={CancelHandler}
            userAppointmentsData={userAppointmentsData}
          />
        )}
      </section>
    </Fragment>
  );
};
