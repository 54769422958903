import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal);

export const SwalSuccess = () => {
  swal.fire({
    title: "İşlem Başarılı",
    icon: "success",
    confirmButtonText: "Tamam",
    confirmButtonColor: "#1b437e",
  });
};

export const SwalSuccessMessage = (message) => {
  swal.fire({
    title: "İşlem Başarılı",
    text: message,
    icon: "success",
    confirmButtonText: "Tamam",
  });
};

export const SwalInfoMessage = (message) => {
  swal.fire({
    title: "Bilgi",
    text: message,
    icon: "info",
    confirmButtonText: "Tamam",
  });
};

export const SwalStatusMessage = (message) => {
  swal.fire({
    title: "Durum",
    text: message,
    icon: "info",
    confirmButtonText: "Tamam",
  });
};

export const SwalErrorMessage = (message) => {
  swal.fire({
    title: "Hata",
    text: message,
    icon: "error",
    confirmButtonText: "Tamam",
    confirmButtonColor: "#1b437e",
  });
};

export const SwalErrorMessageLang = (message, title, confirm) => {
  swal.fire({
    title: title,
    text: message,
    icon: "error",
    confirmButtonText: confirm,
  });
};

export const SwalErrorResponse = (response) => {
  swal.fire({
    title: "Hata",
    text:
      response.errors && response.errors.title
        ? response.errors.title
        : response.errorMessage,
    icon: "error",
    confirmButtonText: "Tamam",
    confirmButtonColor: "#1b437e",
  });
};

export const SwalInfoResponse = (response) => {
  swal.fire({
    title: "Bilgi",
    html:
      response.errors && response.errors.title
        ? response.errors.title
        : response.errorMessage,
    icon: "info",
    confirmButtonText: "Tamam",
    confirmButtonColor: "#1b437e",
  });
};

export const SwallDelete = async () => {
  return Swal.fire({
    title: "Emin misiniz?",
    text: "Veri silinecektir.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1b437e",
    cancelButtonColor: "#d33",
    confirmButtonText: "Evet",
    cancelButtonText: "Hayır",
  });
};

export const SwallApprove = async () => {
  return Swal.fire({
    title: "Emin misiniz?",
    text: "İşlem uygulanacak",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1b437e",
    cancelButtonColor: "#d33",
    confirmButtonText: "Evet",
    cancelButtonText: "Hayır",
  });
};

export const SwallApproveHtml = async (title, html) => {
  return Swal.fire({
    title: title,
    html: html,
    icon: "info",
    showCancelButton: true,
    confirmButtonColor: "#1b437e",
    cancelButtonColor: "#d33",
    confirmButtonText: "Kabul Ediyorum",
    cancelButtonText: "Kabul Etmiyorum",
  });
};

// Swal.fire({
//   title: "Emin misiniz?",
//   text: "İşlem uygulanacak..",
//   icon: "warning",
//   showCancelButton: true,
//   confirmButtonColor: "#3085d6",
//   cancelButtonColor: "#d33",
//   confirmButtonText: "Evet",
//   cancelButtonText: "Hayır",
// }).then(async (result) => {
//   if (result.isConfirmed) {
//   }
// });
