import React, { useContext, useEffect, useState } from "react";
import classes from "./Sss.module.css";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/app-context";
import HTMLReactParser from "html-react-parser";

export const Sss = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const main = document.getElementById("main");
    main.style.backgroundColor = "#edf5ff";
  }, []);

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler("faq", "get", null, null);

      if (response.ok) {
        setData(response.data);
      }
    };
    GetDataList();
  }, []);

  const AccordionHandler = (id) => {
    setData((prev) => [
      ...prev.map((x) => (x.id === id ? { ...x, isOpen: !x.isOpen } : x)),
    ]);
  };

  const Accordion = () => {
    return data.map((x, i) => {
      return (
        <div
          className={classes.accoBox}
          key={i}
          onClick={() => AccordionHandler(x.id)}
        >
          <div className={classes.accoBoxTitleBox}>
            <h3>{x.name}</h3>
            <FontAwesomeIcon icon={x.isOpen ? faMinus : faPlus} />
          </div>

          <div style={{ display: x.isOpen ? "block" : "none" }}>
            <span className={classes.pLine}></span>
            {x.longDescription && HTMLReactParser(x.longDescription)}
          </div>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <section className={classes.section}>
        <div className={classes.headBox}>
          <h1>Sıkça Sorulan Sorular</h1>
        </div>
        <Accordion />
      </section>
    </Fragment>
  );
};
