import React, { Fragment, useContext, useEffect, useState } from "react";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { AppContext } from "../../../context/app-context";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import {
  DataTableProcess,
  EntityStatusHtml,
  ProductQuantityTypeHtml,
  DataTableImage,
  ScrollTop,
} from "../../../util/commonUtil";
import { useLocation } from "react-router-dom";
import { ProductVariantCreateForm } from "../../../components/admin/productVariant/ProductVariantCreateForm";
import moment from "moment";

export const ProductVariant = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const pageTitle = `${state.name} - Varyant`;
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState();
  const [dataList, setDataList] = useState();

  const columns = [
    {
      name: "İşlemler",
      width: "220px",
      cell: (row) =>
        DataTableProcess(
          row,
          (event) => DeleteHandler(event),
          () => EditHandler(row),
          null
        ),
    },
    {
      name: "Görsel",
      selector: (row) => DataTableImage(row.imageUrl),
      sortable: true,
      width: "auto",
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
    {
      name: "Stok Tip",
      selector: (row) => ProductQuantityTypeHtml(row.productQuantityType),
      sortable: true,
      width: "150px",
    },
    {
      name: "Stok",
      selector: (row) => row.quantity,
      sortable: true,
      width: "120px",
    },
    {
      name: "Satış Fiyatı",
      selector: (row) => `${row.salePrice.toString().replaceAll(".", ",")} ₺`,
      sortable: true,
      width: "130px",
    },
    {
      name: "Vergi Oranı",
      selector: (row) => `%${row.vatPercent.toString().replaceAll(".", ",")}`,
      sortable: true,
      width: "130px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setShowModal(true);
    setFormInput(data);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "productVariant/list",
        "post",
        null,
        { productId: state.id },
        "admin"
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData(document.getElementById("form"));
        formData.append("productId", state.id);
        formInput.eventDate &&
          formData.append(
            "eventDate",
            moment(formInput.eventDate).format("DD/MM/YYYY HH:mm")
          );
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          "productVariant",
          method,
          "formData",
          formData
        );
        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "productVariant",
          "delete",
          null,
          { id: event.target.id }
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput({ productId: state.id });
            setShowModal(true);
          }}
        >
          {pageTitle} Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle `}
        >
          <ProductVariantCreateForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            productType={state.productType}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
