import React, { Fragment, useEffect } from "react";
import classes from "./PopupModal.module.css";

export const PopupModal = ({
  showModal,
  setShowModal,
  title,
  index,
  data,
  style,
}) => {
  return (
    <Fragment>
      {showModal && (
        <Fragment>
          <div
            className={classes.modalBackdrop}
            onClick={() =>
              setShowModal((prev) => ({
                ...prev,
                [index]: false,
              }))
            }
          ></div>
          <div className={classes.modal}>
            <div
              style={{
                display: "flex",
                justifyContent: title ? "space-between" : "flex-end",
              }}
            >
              {title && <h2>{title}</h2>}

              <button
                type="button"
                className={classes.modalClose}
                onClick={() =>
                  setShowModal((prev) => ({
                    ...prev,
                    [index]: false,
                  }))
                }
              >
                X
              </button>
            </div>
            <hr></hr>
            <div className={classes.modalContent}>{data}</div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
