import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./SupportBox.module.css";

export const SupportBox = ({ data, isGiveSupport }) => {
  const _data = { ...data, isGiveSupport: isGiveSupport };
  return (
    <Fragment>
      <Link
        className={classes.supportBox}
        to={data.supportType == 5 ? data.outUrl : `/${data.url}?id=${data.id}`}
        target={data.supportType == 5 ? "_blank" : "_self"}
        state={_data}
      >
        <img
          src={data.imageUrl ? data.imageUrl : "/assets/img/logo.png"}
          alt={data.name}
        />
        <h4>{data.name}</h4>
        <p>{data.shortDescription}</p>
        <button className={classes.boxButton}>BAŞVUR</button>
      </Link>
    </Fragment>
  );
};
