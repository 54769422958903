import React from "react";
import classes from "./StuffForm.module.css";
import { Fragment } from "react";

import { Input } from "../../ui/input/Input";
import { useState } from "react";

import { StufFileUpload } from "../stuffFileUpload/StufFileUpload";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import {
  SwalErrorMessage,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { ScrollTop } from "../../../util/commonUtil";

export const StuffForm = ({
  formInput,
  setFormInput,
  submitHandler,
  stuffTypeData,
  stuffKindData,
  GetStuffKind,
}) => {
  const appContext = useContext(AppContext);
  const stuffJson = {
    _id: 0,
    esyaTipiText: "",
    esyaTipi: 0,
    esyaTuruText: "",
    esyaTuru: 0,
    calismaDurumu: 0,
    adet: 0,
    teslimEdecekTel: "",
    il: "İzmir",
    il_ID: 35,
    ilce: "",
    ilce_ID: 0,
    mahalle: "",
    mahalle_ID: 0,
    yol: "",
    yol_ID: 0,
    dis_Kapi: "",
    ic_Kapi: "",
    adres: "",
    resim: [],
  };
  const [stuff, setStuff] = useState(stuffJson);
  const [cityCodesData, setCityCodesData] = useState();
  const [districtCodesData, setDistrictCodesData] = useState();
  const [neighbourhoodCodes, setNeighbourhoodCodes] = useState();
  const [streetCodes, setStreetCodes] = useState();

  useEffect(() => {
    DistrictHandler(35);
  }, [formInput.isAddressChangeAble, appContext.gRecapReady]);

  // useEffect(() => {
  //
  //   CityHandler();
  // }, []);

  const CityHandler = () => {
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/cityCodes",
        "post",
        "",
        { gToken: gToken },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setCityCodesData(_tempData);
      }
    });
  };

  const DistrictHandler = (cityId) => {
    if (cityId === "" || cityId == 0 || isNaN(cityId)) {
      setDistrictCodesData([]);
      setNeighbourhoodCodes([]);
      setStreetCodes([]);
      return;
    }
    setStuff((prev) => ({
      ...prev,
      ilce: "",
      ilce_ID: 0,
      mahalle: "",
      mahalle_ID: 0,
      yol: "",
      yol_ID: 0,
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/DistrictCodes",
        "post",
        "",
        { gToken: gToken, id: cityId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setDistrictCodesData(_tempData);
      }
    });
  };

  const NeighbourhoodHandler = (districtId) => {
    if (districtId === "" || districtId == 0 || isNaN(districtId)) {
      setNeighbourhoodCodes([]);
      setStreetCodes([]);
      return;
    }

    setStuff((prev) => ({
      ...prev,
      mahalle_ID: 0,
      yol_ID: 0,
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/NeighborhoodCodes",
        "post",
        "",
        { gToken: gToken, id: districtId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setNeighbourhoodCodes(_tempData);
      }
    });
  };

  const StreetHandler = (nbhId) => {
    if (nbhId === "" || nbhId == 0 || isNaN(nbhId)) {
      setStreetCodes([]);
      return;
    }
    setStuff((prev) => ({
      ...prev,
      yol_ID: 0,
    }));
    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;
      const response = await appContext.FetchHandler(
        "ort/streetCodes",
        "post",
        "",
        { gToken: gToken, id: stuff.ilce_ID, nbhId: nbhId },
      );
      if (response.ok) {
        const _tempData = [];
        Array.from(response.data).forEach((x) => {
          if (parseInt(x.id) > 0)
            _tempData.push({
              value: parseInt(x.id.replace(".0", "")),
              text: x.adi,
            });
        });
        setStreetCodes(_tempData);
      }
    });
  };

  const AddHandler = (event) => {
    event.preventDefault();

    if (stuff.teslimEdecekTel.length < 12) {
      SwalErrorMessage("Telefon numarasını eksik veya hatalı girdiniz.");
      return;
    }

    if (stuff.resim.length == 0) {
      SwalErrorMessage("Görsel eklemeniz gerekmektedir.");
      return;
    }
    stuff._id = Math.floor(Math.random() * 100);

    setFormInput((prev) => ({
      ...prev,
      esyaList: [...prev.esyaList, stuff],
    }));
    setStuff(stuffJson);

    document.getElementById("form").reset();
    let _tel = document
      .getElementById("teslimEdecekTel")
      .value.replaceAll("+", "")
      .replaceAll(" ", "");
    setStuff((prev) => ({
      ...prev,
      teslimEdecekTel: _tel,
    }));

    SwalSuccessMessage(
      "Eşya, Bağışlanacak Eşyalara eklenmiştir. Birden fazla eşya ekleyebilirsiniz. Başvurunuzun tamamlanabilmesi için Bağış Yap butonuna tıklamayı unutmayınız.",
    );
    ScrollTop();
  };

  const RemoveHandler = (id) => {
    const filtered = Array.from(formInput.esyaList).filter((x) => x._id != id);
    setFormInput((prev) => ({
      ...prev,
      esyaList: filtered,
    }));
  };

  useEffect(() => {}, [stuff]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.stuffMain}>
          <form id="form" className={classes.form} onSubmit={AddHandler}>
            <h3 className={classes.stuffContentTitle}>Eşya Ekle</h3>
            <div>
              <Input
                label="Eşya Tipi"
                type="select"
                style={{ width: "100%" }}
                option={stuffTypeData}
                input={{
                  id: "esyaTipi",
                  required: "required",
                  onChange: (event) => {
                    const index = event.target.selectedIndex;
                    setStuff((prev) => ({
                      ...prev,
                      esyaTipiText: event.target[index].text,
                      esyaTipi: parseInt(event.target.value),
                    }));
                    GetStuffKind(parseInt(event.target.value));
                  },
                }}
              />
              {stuffKindData && (
                <Fragment>
                  <Input
                    label="Eşya Türü"
                    type="select"
                    style={{ width: "100%" }}
                    option={stuffKindData}
                    input={{
                      id: "esyaTuru",
                      required: "required",
                      onChange: (event) => {
                        const index = event.target.selectedIndex;
                        setStuff((prev) => ({
                          ...prev,
                          esyaTuruText: event.target[index].text,
                          esyaTuru: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Eşya Çalışır Durumda mı?"
                    type="select"
                    style={{ width: "100%" }}
                    option={[
                      { value: 1, text: "Evet" },
                      { value: 2, text: "Hayır" },
                    ]}
                    input={{
                      id: "calismaDurumu",
                      required: "required",
                      onChange: (event) => {
                        setStuff((prev) => ({
                          ...prev,
                          calismaDurumu: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Adet"
                    type="select"
                    style={{ width: "100%" }}
                    option={[
                      { value: 1, text: "1" },
                      { value: 2, text: "2" },
                      { value: 3, text: "3" },
                      { value: 4, text: "4" },
                      { value: 5, text: "5" },
                      { value: 6, text: "6" },
                      { value: 7, text: "7" },
                      { value: 8, text: "8" },
                      { value: 9, text: "9" },
                      { value: 10, text: "10" },
                    ]}
                    input={{
                      id: "adet",
                      required: "required",
                      onChange: (event) => {
                        setStuff((prev) => ({
                          ...prev,
                          adet: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <StufFileUpload stuff={stuff} setStuff={setStuff} />
                  <Input
                    label="İlçe"
                    type="select"
                    style={{ width: "100%" }}
                    option={districtCodesData}
                    value={stuff.ilce_ID}
                    input={{
                      id: "ilce_ID",
                      required: "required",
                      onChange: (event) => {
                        const index = event.target.selectedIndex;
                        NeighbourhoodHandler(parseInt(event.target.value));
                        setStuff((prev) => ({
                          ...prev,
                          ilce: event.target[index].text,
                          ilce_ID: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Mahalle"
                    type="select"
                    style={{ width: "100%" }}
                    option={neighbourhoodCodes}
                    value={stuff.mahalle_ID}
                    input={{
                      id: "mahalle_ID",
                      onChange: (event) => {
                        const index = event.target.selectedIndex;
                        StreetHandler(parseInt(event.target.value));
                        setStuff((prev) => ({
                          ...prev,
                          mahalle: event.target[index].text,
                          mahalle_ID: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Sokak"
                    type="select"
                    style={{ width: "100%" }}
                    option={streetCodes}
                    value={stuff.yol_ID}
                    input={{
                      id: "yol_ID",

                      onChange: (event) => {
                        const index = event.target.selectedIndex;
                        setStuff((prev) => ({
                          ...prev,
                          yol: event.target[index].text,
                          yol_ID: parseInt(event.target.value),
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Adres"
                    type="textArea"
                    style={{ width: "100%" }}
                    input={{
                      id: "adres",
                      required: "required",
                      onChange: (event) => {
                        setStuff((prev) => ({
                          ...prev,
                          adres: event.target.value,
                        }));
                      },
                    }}
                  />
                  <Input
                    label="Teslim Edecek Kişinin Telefonu"
                    type="phoneNumber"
                    style={{ width: "100%" }}
                    input={{
                      id: "teslimEdecekTel",
                      type: "text",
                      required: "required",
                      onChange: (event) => {
                        setStuff((prev) => ({
                          ...prev,
                          teslimEdecekTel: event,
                        }));
                      },
                    }}
                  />
                </Fragment>
              )}
            </div>
            <div className={classes.formButton}>
              <button>EKLE</button>
            </div>
          </form>
          <div className={classes.stuffList}>
            <h3 className={classes.stuffContentTitle}>Bağışlanacak Eşyalar</h3>
            <div className={classes.stuffListContainer}>
              {Array.from(formInput.esyaList).map((x, i) => {
                return (
                  <div className={classes.stuffListBox} key={i}>
                    <div style={{ display: "flex" }}>
                      <img src={x.resim[0].preview} alt="" />
                      <FontAwesomeIcon
                        icon={faRemove}
                        title="Kaldır"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => RemoveHandler(x._id)}
                      />
                    </div>

                    <ul>
                      <li>
                        <span>Eşya Tipi:</span>
                        {x.esyaTipiText}
                      </li>
                      <li>
                        <span>Eşya Türü:</span> {x.esyaTuruText}
                      </li>
                      <li>
                        <span>Adet:</span> {x.adet}
                      </li>
                      <li>
                        <span>İl:</span> {x.il}
                      </li>
                      <li>
                        <span>İlçe:</span> {x.ilce}
                      </li>
                      <li>
                        <span>Mahalle:</span> {x.mahalle}
                      </li>
                      <li>
                        <span>Sokak:</span> {x.yol}
                      </li>
                      <li>
                        <span>Adres:</span>
                        {x.adres}
                      </li>
                      <li>
                        <span>Tel:</span>
                        {x.teslimEdecekTel}
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {formInput.esyaList.length !== 0 && (
          <div
            className={classes.formButton}
            style={{ marginTop: "2rem" }}
            onClick={submitHandler}
          >
            <button>BAĞIŞ YAP</button>
          </div>
        )}
      </div>
    </Fragment>
  );
};
