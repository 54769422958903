import React, { Fragment } from "react";
import classes from "./ProductBox.module.css";
import { Link } from "react-router-dom";

export const ProductBox = ({ products }) => {
  return (
    <Fragment>
      <div className={classes.productContainer}>
        {products &&
          Array.from(products).map((x, i) => {
            return (
              <Link
                className={classes.productBox}
                key={i}
                to="/productDetail"
                state={x}
              >
                <img
                  src={x.imageUrl ? x.imageUrl : "/assets/img/logo.png"}
                  alt=""
                />
                <h4>{x.name}</h4>
                <p>{x.shortDescription}</p>
                <button>Detay</button>
              </Link>
            );
          })}
      </div>
    </Fragment>
  );
};
