import React, { useContext, useEffect, useState } from "react";
import classes from "./OurServices.module.css";
import { Fragment } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/app-context";
import HTMLReactParser from "html-react-parser";

export const OurServices = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const main = document.getElementById("main");
    main.style.backgroundColor = "#edf5ff";
  }, []);

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "ourServices",
        "get",
        null,
        null
      );

      if (response.ok) {
        setData(response.data);
      }
    };
    GetDataList();
  }, []);

  return (
    <Fragment>
      <section className={classes.section}>
        <div className={classes.headBox}>
          <h1>Hizmetlerimiz</h1>
        </div>
        <div className={classes.container}>
          {data &&
            Array.from(data).map((x, i) => {
              return (
                <div className={classes.item} key={i}>
                  <div className={classes.itemContent}>
                    <img src={x.imageUrl} alt="" />
                    <h2>{x.name}</h2>
                    <p>{x.shortDescription}</p>
                    <br />
                    {x.longDescription && HTMLReactParser(x.longDescription)}
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </Fragment>
  );
};
