import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./IzsuApply.module.css";
import { AppContext } from "../../../context/app-context";
import { IzsuStatusHtml, ScrollTop } from "../../../util/commonUtil";
import { SwalSuccess, SwallApprove, SwallDelete } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import moment from "moment";
import { IzsuApplyDetail } from "../../../components/admin/izsuApplyDetail/IzsuApplyDetail";

export const IzsuApply = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "İzsu Askıda Fatura Başvurular";
  const [showModal, setShowModal] = useState(false);
  const [dataList, setDataList] = useState();
  const [crudState, setCrudState] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "300px",
      cell: (row) => {
        return (
          <div
            className="dataTableProcess"
            style={{ flex: 1, justifyContent: "space-evenly" }}
          >
            <button
              className="dataTableProcessInfo"
              onClick={() => DetailHandler(row.id)}
            >
              Detay
            </button>
            <button
              className="dataTableProcessCyan"
              onClick={() => ApproveHandler({ id: row.id, izsuStatus: 2 })}
            >
              Onayla
            </button>
            <button
              className="dataTableProcessDelete"
              onClick={() => ApproveHandler({ id: row.id, izsuStatus: 3 })}
            >
              Reddet
            </button>
          </div>
        );
      },
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Durum",
      selector: (row) => IzsuStatusHtml(row.izsuStatus),
      sortable: true,
      width: "200px",
    },
    {
      name: "Puan",
      selector: (row) => row.score,
      sortable: true,
      width: "75px",
    },
    {
      name: "Izsu Abone No",
      selector: (row) => row.izsuAboneNo,
      sortable: true,
      width: "150px",
    },
    {
      name: "TC No",
      selector: (row) => row.tcNo,
      sortable: true,
      width: "150px",
    },
    {
      name: "Telefon",
      selector: (row) => row.phoneNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Başvuru Tarihi",
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
      sortable: true,
      width: "200px",
    },
    {
      name: "İşlem Tarihi",
      selector: (row) => moment(row.processDate).format("DD-MM-YYYY"),
      sortable: true,
      width: "200px",
    },
  ];

  const ApproveHandler = async (data) => {
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "izsu/applyApprove",
          "post",
          null,
          { id: data.id, izsuStatus: data.izsuStatus }
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  const GetDetail = async (id) => {
    setSelectedData(null);
    const response = await appContext.FetchHandler(
      "izsu/applyDetail",
      "post",
      null,
      { id: id }
    );

    if (response.ok) {
      setSelectedData(response.data);
      setShowModal(!showModal);
    }
  };

  const DetailHandler = (id) => {
    GetDetail(id);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        "izsu/ApplyList",
        "get",
        null,
        null
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "company",
          "post",
          null,
          ""
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>

      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title="Detay"
        >
          <IzsuApplyDetail data={selectedData} />
        </ModalAdmin>
      )}
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};
