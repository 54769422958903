import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import { Validation } from "../../ui/input/Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faRepeat } from "@fortawesome/free-solid-svg-icons";
import classes from "./OtpForm.module.css";
import Swal from "sweetalert2";

export const OtpForm = ({
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
  submitHandler,
  createOtpHandler,
}) => {
  return (
    <Fragment>
      <form
        id="form"
        className={classes.form}
        onSubmit={(event) => {
          event.preventDefault();
          submitHandler();
        }}
      >
        <div className={classes.otpBox}>
          <Input
            label="Doğrulama Kodu"
            type="input"
            validation={formInput.otp !== 0 && formInputIsValid.otp}
            style={{ width: "100%" }}
            input={{
              id: "otp",
              required: "required",
              placeholder: "Doğrulama Kodu",
              onChange: (event) => {
                const value = event.target.value;
                setFormInputIsValid((prev) => ({
                  ...prev,
                  otp: Validation("Doğrulama Kodu", value, {
                    isRequired: true,
                    type: 1,
                    min: 6,
                    max: 6,
                  }),
                }));
                setFormInput((prev) => ({
                  ...prev,
                  otp: value,
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Doğrula
          </button>
          <button
            type="button"
            className={classes.btnReSend}
            onClick={() => {
              Swal.fire({
                title: "Bilgi",
                text: "Doğrulama kodu telefonunuza tekrar gönderilecektir.",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#1b437e",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tamam",
                cancelButtonText: "İptal",
              }).then(async (res) => {
                if (res.isConfirmed) {
                  createOtpHandler();
                }
              });
            }}
          >
            Kodu Tekrar Gönder
          </button>
        </div>
      </form>
    </Fragment>
  );
};
