import React from "react";
import { Input } from "../../ui/input/Input";
import {
  EntityStatusData,
  RgbConvert,
  RgbaConvert,
  SupportTypeData,
} from "../../../util/commonUtil";
import { Fragment } from "react";

export const HomePageCreateForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <div style={{ display: "flex" }}>
          {formInput != null && formInput.id != null && (
            <input id="id" name="id" type="hidden" value={formInput.id} />
          )}

          <Input
            label="Ad"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "name",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.name != null
                  ? formInput.name
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            label="Renk 1"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "color1",
              type: "color",
              required: "required",
              min: "1",
              value:
                formInput != null && formInput.color1 != null
                  ? formInput.color1
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  color1: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Renk 2"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "color2",
              type: "color",
              required: "required",
              value:
                formInput != null && formInput.color2 != null
                  ? formInput.color2
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  color2: event.target.value,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex", margin: "1rem" }}>
            {     formInput != null &&
                formInput.imageUrl != null &&
                formInput.imageUrl &&(
                    <img
                        src={formInput.imageUrl }
                        width="200"
                        alt=""
                        style={{ margin: "1rem" }}
                    />
                )}

          <Input
            label="Arka Plan Görsel"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "file",
              type: "file",
              accept: "image/*",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  file: event.target.files[0],
                }));
              },
            }}
          />
        </div>
        <Input
          label="Açıklama"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
