import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./LoginFormAdmin.module.css";

export const LoginFormAdmin = ({
  submitHandler,
  loginFormInput,
  setLoginFormInput,
  setFormType,
}) => {
  return (
    <Fragment>
      <form
        className={`form ${classes.loginForm}`}
        onSubmit={(event) => {
          event.preventDefault();
          submitHandler(loginFormInput);
        }}
      >
        <Input
          label="Email"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "email",
            type: "email",
            required: "required",
            onChange: (event) => {
              setLoginFormInput((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            },
          }}
        />
        <div className={classes.forgotDiv}>
          <Input
            label="Şifre"
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "password",
              type: "password",
              required: "required",
              onChange: (event) => {
                setLoginFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
          <button
            type="button"
            onClick={() => {
              setFormType(1);
            }}
          >
            Şifremi Unuttum?
          </button>
        </div>

        <button type="submit" className="btnSave">
          Giriş
        </button>
      </form>
    </Fragment>
  );
};
