import React, { Fragment } from "react";
import classes from "./DatatableAdminFilter.module.css";
export const DatatableAdminFilter = ({ filterText, onFilter, onClear }) => {
  return (
    <Fragment>
      <input
        id="search"
        type="text"
        placeholder="Ara"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className={classes.filterSearch}
      />
      <button className={classes.clearButton} type="button" onClick={onClear}>
        X
      </button>
    </Fragment>
  );
};
