import React from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./StuffStartForm.module.css";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import { DestekVerRizaMetni, KVKK, RizaMetni } from "../../../util/commonText";
import { Validation } from "../../ui/input/Validation";

export const StuffStartForm = ({
  formInputJson,
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
  submitHandler,
}) => {
  const StartForm = () => {
    switch (formInput.basvuruTipi) {
      case 0:
        break;
      case 1:
        return (
          <div>
            <Input
              label="T.C. Kimlik No"
              type="input"
              style={{ width: "100%" }}
              validation={formInput.tc != null && formInputIsValid.tc}
              input={{
                id: "tc",
                placeholder: "T.C. Kimlik No",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    tc: Validation("T.C. Kimlik No", value, {
                      isRequired: true,
                      min: 11,
                      max: 11,
                      type: 1,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    tc: value,
                  }));
                },
              }}
            />
            <Input
              label="Doğum Tarihi"
              type="input"
              style={{ width: "100%" }}
              validation={
                formInput.dogumTarihi != null && formInputIsValid.dogumTarihi
              }
              input={{
                id: "dogumTarihi",
                type: "date",
                placeholder: "Doğum Tarihi",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    dogumTarihi: Validation("Doğum Tarihi", value, {
                      isRequired: true,
                      type: 3,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    dogumTarihi: value,
                  }));
                },
              }}
            />
            <Input
              label="Adınız"
              type="input"
              style={{ width: "100%" }}
              validation={formInput.adi != null && formInputIsValid.adi}
              input={{
                id: "adi",
                placeholder: "Adınız",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    adi: Validation("Ad", value, {
                      isRequired: true,
                      min: 2,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    adi: value,
                  }));
                },
              }}
            />
            <Input
              label="Soyadınız"
              type="input"
              style={{ width: "100%" }}
              validation={formInput.soyadi != null && formInputIsValid.soyadi}
              input={{
                id: "soyadi",
                type: "text",
                placeholder: "Soyadınız",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    soyadi: Validation("Soyad", value, {
                      isRequired: true,
                      min: 2,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    soyadi: event.target.value,
                  }));
                },
              }}
            />
            <Input
              label="Cep Telefonu"
              type="phoneNumber"
              style={{ width: "100%" }}
              onlyCountries={["tr"]}
              validation={
                formInput.cepTelefonu != null && formInputIsValid.cepTelefonu
              }
              input={{
                id: "cepTelefonu",
                type: "text",
                onChange: (event) => {
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    cepTelefonu: Validation("Cep Telefonu", event, {
                      isRequired: true,
                      min: 12,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    cepTelefonu: event,
                  }));
                },
              }}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <div className={classes.group}>
              <Input
                label="Vergi Numarası"
                type="input"
                style={{ width: "100%" }}
                validation={
                  formInput.vergiNo != null && formInputIsValid.vergiNo
                }
                input={{
                  id: "vergiNo",
                  placeholder: "Vergi Numarası",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      vergiNo: Validation("Vergi Numarası", value, {
                        isRequired: true,
                        min: 10,
                        max: 10,
                        type: 1,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      vergiNo: value,
                    }));
                  },
                }}
              />
              <Input
                label="Firma Adı"
                type="input"
                style={{ width: "100%" }}
                validation={
                  formInput.firmaAdi != null && formInputIsValid.firmaAdi
                }
                input={{
                  id: "firmaAdi",
                  placeholder: "Firma Adı",
                  onChange: (event) => {
                    const value = event.target.value;
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      firmaAdi: Validation("Firma Adı", value, {
                        isRequired: true,
                        min: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      firmaAdi: event.target.value,
                    }));
                  },
                }}
              />
            </div>
            <Input
              label="Firma Adres"
              type="textArea"
              style={{ width: "100%" }}
              validation={
                formInput.firmaAdres != null && formInputIsValid.firmaAdres
              }
              input={{
                id: "firmaAdres",
                placeholder: "Firma Adres",
                onChange: (event) => {
                  const value = event.target.value;
                  setFormInputIsValid((prev) => ({
                    ...prev,
                    firmaAdres: Validation("Firma Adres", value, {
                      isRequired: true,
                      min: 20,
                    }),
                  }));
                  setFormInput((prev) => ({
                    ...prev,
                    firmaAdres: value,
                  }));
                },
              }}
            />
            <div className={classes.group}>
              <Input
                label="Cep Telefonu"
                type="phoneNumber"
                style={{ width: "100%" }}
                onlyCountries={["tr"]}
                validation={
                  formInput.cepTelefonu != null && formInputIsValid.cepTelefonu
                }
                input={{
                  id: "cepTelefonu",
                  onChange: (event) => {
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      cepTelefonu: Validation("Cep Telefonu", event, {
                        isRequired: true,
                        min: 12,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      cepTelefonu: event,
                      firmaTelefon: event,
                    }));
                  },
                }}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        <Input
          label="Başvuru Tipi"
          type="select"
          style={{ width: "100%" }}
          option={[
            { value: 1, text: "Vatandaş" },
            { value: 2, text: "Kurum/Kuruluş" },
          ]}
          input={{
            id: "basvuruTipi",
            type: "text",
            value: formInput.basvuruTipi,
            onChange: (event) => {
              const value = parseInt(event.target.value);
              setFormInputIsValid((prev) => ({
                ...prev,
                tc: {
                  status: value !== 1,
                  message: "T.C. Kimlik: Zorunludur.",
                },
                dogumTarihi: {
                  status: value !== 1,
                  message: "Doğum Tarihi: Zorunludur.",
                },
                adi: { status: value !== 1, message: "Adınız: Zorunludur." },
                soyadi: {
                  status: value !== 1,
                  message: "Soyadınız: Zorunludur.",
                },
                cepTelefonu: {
                  status: false,
                  message: "Telefon Numarası: Zorunludur.",
                },
                kvkk: { status: false, message: "KVKK: Onayı zorunludur." },
                rizaMetni: {
                  status: false,
                  message: "Rıza Metni: Onayı zorunludur.",
                },
                vergiNo: {
                  status: value === 1,
                  message: "Vergi Numarası: Zorunludur.",
                },
                firmaAdi: {
                  status: value === 1,
                  message: "Firma Adı: Zorunludur.",
                },
                firmaAdres: {
                  status: value === 1,
                  message: "Firma Adresi: Zorunludur.",
                },
              }));
              formInputJson.basvuruTipi = value;
              setFormInput(formInputJson);
            },
          }}
        />
        {StartForm()}
        {(formInput.basvuruTipi === 1 || formInput.basvuruTipi === 2) && (
          <Fragment>
            <Input
              disableLabel={true}
              label={HTMLReactParser(
                `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline">KVKK Aydınlatma Metni</span>’ni okuduğumu onaylıyorum.</span>`,
              )}
              style={{ width: "100%" }}
              type="input"
              validation={formInput.kvkk != null && formInputIsValid.kvkk}
              checked={
                formInput != null && formInput.kvkk != null && formInput.kvkk
              }
              checkboxStyle={{
                justifyContent: "flex-start",
                border: "none",
                height: "1rem",
                marginTop: "1rem",
              }}
              input={{
                id: "kvkk",
                type: "checkbox",
                onChange: (event) => {
                  Swal.fire({
                    width: 1000,
                    html: KVKK(),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#118c11",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                  }).then(async (result) => {
                    const element = document.getElementById(event.target.id);
                    if (result.isConfirmed) {
                      element.checked = true;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        kvkk: {
                          status: true,
                          message: ``,
                        },
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        kvkk: true,
                      }));
                    } else {
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        kvkk: {
                          status: false,
                          message: `KVKK: Onayı zorunludur.`,
                        },
                      }));
                      element.checked = false;
                      setFormInput((prev) => ({
                        ...prev,
                        kvkk: false,
                      }));
                    }
                  });
                },
              }}
            />
            <Input
              disableLabel={true}
              label={HTMLReactParser(
                `<span style="color:#9D9D9D;text-decoration:underline"><span style="color:#1c2f53;text-decoration:underline">Rıza Metni</span>’ni okuduğumu onaylıyorum.</span>`,
              )}
              style={{ width: "100%" }}
              type="input"
              validation={
                formInput.rizaMetni != null && formInputIsValid.rizaMetni
              }
              checked={formInput.rizaMetni != null && formInput.rizaMetni}
              checkboxStyle={{
                justifyContent: "flex-start",
                border: "none",
                height: "1rem",
              }}
              input={{
                id: "rizaMetni",
                type: "checkbox",
                onChange: (event) => {
                  Swal.fire({
                    width: 1000,
                    html: DestekVerRizaMetni(),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#118c11",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                  }).then(async (result) => {
                    const element = document.getElementById(event.target.id);
                    if (result.isConfirmed) {
                      element.checked = true;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        rizaMetni: {
                          status: true,
                          message: ``,
                        },
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        rizaMetni: true,
                      }));
                    } else {
                      element.checked = false;
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        rizaMetni: {
                          status: false,
                          message: `Rıza Metni: Onayı zorunludur.`,
                        },
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        rizaMetni: false,
                      }));
                    }
                  });
                },
              }}
            />
          </Fragment>
        )}
        <div className={classes.formButton}>
          <button>BAŞVUR</button>
        </div>
      </form>
    </Fragment>
  );
};
