import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./IzsuPaidInvoice.module.css";
import { MinMaxDate } from "../../../components/ui/input/MinMaxDate";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { SwalErrorMessage } from "../../../util/swalUtil";
import moment from "moment";
import { AppContext } from "../../../context/app-context";
import { ConvertCurrency, OrderStatusHtml } from "../../../util/commonUtil";

export const IzsuPaidInvoice = () => {
  const appContext = useContext(AppContext);
  const date = new Date();
  const [formInput, setFormInput] = useState({
    minDate: moment(date).format("YYYY-MM-DD"),
    maxDate: moment(date).format("YYYY-MM-DD"),
  });
  const [dataList, setDataList] = useState();
  const [excelButtonStatus, setExcelButtonStatus] = useState(true);
  const columns = [
    {
      name: "İşlem No",
      cell: (row) => {
        return row.islemNo;
      },
      width: "160px",
    },
    {
      name: "Abone Ad Soyad",
      cell: (row) => {
        return row.aboneFullName;
      },
      width: "170px",
    },
    {
      name: "Borç",
      cell: (row) => {
        return ConvertCurrency(row.borc);
      },
      width: "130px",
    },
    {
      name: "Abone TC",
      cell: (row) => {
        return row.aboneTC;
      },
      width: "130px",
    },
    {
      name: "Abone No",
      cell: (row) => {
        return row.aboneNo;
      },
      width: "130px",
    },
    {
      name: "Sözleşme No",
      cell: (row) => {
        return row.sozlesmeNo;
      },
      width: "110px",
    },
    {
      name: "ThkFis No",
      cell: (row) => {
        return row.thkFisNo;
      },
      width: "110px",
    },
    {
      name: "Dönem",
      cell: (row) => {
        return moment(row.donem).format("DD/MM/YYYY");
      },
      width: "110px",
    },
    {
      name: "Son Ödeme Tarihi",
      cell: (row) => {
        return moment(row.sonOdemeTarihi).format("DD/MM/YYYY");
      },
      width: "150px",
    },

    {
      name: "Ödeme Yapan",
      cell: (row) => {
        return row.odemeYapanFullName;
      },
      width: "150px",
    },
    {
      name: "Ödeme Tarihi",
      cell: (row) => {
        return moment(row.paidDate).format("DD/MM/YYYY");
      },
      width: "120px",
    },
  ];

  const GetData = async (event) => {
    if (event != undefined) event.preventDefault();

    const formData = new FormData();
    formData.append(
      "minDate",
      moment(formInput.minDate).format("DD/MM/YYYY HH:mm")
    );
    formData.append(
      "maxDate",
      moment(formInput.maxDate).format("DD/MM/YYYY HH:mm")
    );

    const response = await appContext.FetchHandler(
      "izsu/PaidInvoiceList",
      "post",
      "formData",
      formData
    );

    if (response.ok) {
      setDataList(response.data);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  const GetExcel = async () => {
    const formData = new FormData();
    formData.append(
      "minDate",
      moment(formInput.minDate).format("DD/MM/YYYY HH:mm")
    );
    formData.append(
      "maxDate",
      moment(formInput.maxDate).format("DD/MM/YYYY HH:mm")
    );

    const response = await appContext.FetchHandler(
      "Report/grandPlazaCcSalesExcel",
      "post",
      "formData",
      formData
    );

    if (response.ok) {
      window.open(response.data);
    }
  };
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3>İzsu Askıda Ödenmiş Faturalar</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            dataList
              ? GetExcel()
              : SwalErrorMessage("Aktarılacak veri bulunamadı");
          }}
        >
          Excele Aktar
        </button>
      </div>

      <div>
        <MinMaxDate
          formInput={formInput}
          setFormInput={setFormInput}
          submitHandler={GetData}
          buttonStatus={excelButtonStatus}
        />
      </div>
      {dataList && <DatatableAdmin data={dataList} columns={columns} />}
    </Fragment>
  );
};
