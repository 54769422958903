import React from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./ChildTcForm.module.css";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { SwalErrorMessage } from "../../../util/swalUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

export const ChildTcForm = ({ formInput, setFormInput, submitHandler }) => {
  const appContext = useContext(AppContext);

  const RemoveChild = (child) => {
    const filter = Array.from(
      formInput.basvurulanAileBilgisiTcKimlikNoListesi
    ).filter((x) => x !== child);

    setFormInput((prev) => ({
      ...prev,
      basvurulanAileBilgisiTcKimlikNoListesi: filter,
    }));
  };

  const AddChild = async (event) => {
    event.preventDefault();
    const child = event.target[0].value;
    const exist = Array.from(
      formInput.basvurulanAileBilgisiTcKimlikNoListesi
    ).includes(child);
    if (exist) {
      SwalErrorMessage("Bu TC daha önce eklenmiş.");
      return;
    }
    const gToken = appContext.GetGRecapToken().then(async (gToken) => {
      const validTc = await appContext.CheckTC({
        tcKimlikNo: child,
        gToken: gToken,
      });

      if (validTc.ok) {
        event.target.reset();
        setFormInput((prev) => ({
          ...prev,
          basvurulanAileBilgisiTcKimlikNoListesi: [
            ...prev.basvurulanAileBilgisiTcKimlikNoListesi,
            child,
          ],
        }));
      }
    });
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <form id="form" className={classes.form} onSubmit={AddChild}>
          <div className={classes.childList}>
            <h3>TC Listesi</h3>
            <p>
              Başvuruda bulunmak istediğiniz kişilerin TC kimlik numaralarını
              ekleyiniz.
            </p>
            <ul>
              {formInput.length == 0 ? (
                <span>Liste boş</span>
              ) : (
                Array.from(
                  formInput.basvurulanAileBilgisiTcKimlikNoListesi
                ).map((x, i) => {
                  return (
                    <li key={i}>
                      <span> {`${i + 1}.`}</span>
                      <span>{x}</span>
                      <FontAwesomeIcon
                        onClick={() => RemoveChild(x)}
                        style={{
                          color: "red",
                          margin: "0 1rem",
                          cursor: "pointer",
                        }}
                        title="Sil"
                        icon={faDeleteLeft}
                      />
                    </li>
                  );
                })
              )}
            </ul>
          </div>
          <Input
            type="input"
            style={{ width: "100%" }}
            input={{
              id: "tcKimlikNo",
              type: "number",
              placeholder: "TC Kimlik No",
              required: "required",
            }}
          />
          <div className={classes.formButton}>
            <button type="submit">Ekle</button>
          </div>
        </form>
        <button
          type="button"
          className={classes.saveButton}
          onClick={() => submitHandler()}
        >
          BAŞVUR
        </button>
      </div>
    </Fragment>
  );
};
