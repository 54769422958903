import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./OzelAlanlarForm.module.css";

import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { WebApplyFileUpload } from "../webApplyFileUpload/WebApplyFileUpload";

export const OzelAlanlarForm = ({
  ozelAlanlarData,
  setOzelAlanlarData,
  ekliDokumanBilgisiData,
  formInput,
  setFormInput,
  submitHandler,
}) => {
  const appContext = useContext(AppContext);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const FindQuestionAnswer = () => {
    if (
      formInput.ozelAlanlar == undefined ||
      selectedQuestion == undefined ||
      selectedQuestion.bagliComponentId == undefined ||
      selectedQuestion.bagliComponentId == null
    )
      return;

    const filtered = Array.from(formInput.ozelAlanlar).filter(
      (x) => x.bagliComponentId == selectedQuestion.bagliComponentId,
    )[0];

    if (filtered !== undefined) {
      appContext.GetGRecapToken().then(async (gToken) => {
        const response = await appContext.FetchHandler(
          "webApply/QuestionAnswers",
          "post",
          "",
          {
            gToken: gToken,
            componentId: filtered.bagliComponentId,
            value: filtered.DEGERI,
          },
        );

        if (response.ok) {
          const _tempData = [...ozelAlanlarData];
          Array.from(_tempData).forEach((x) => {
            Array.from(x.sorular).forEach((y) => {
              if (y.componentId == filtered.bagliComponentId) {
                y.cevaplar = response.data;
              }
            });
          });
          setOzelAlanlarData(_tempData);
        }
      });
    }
  };

  useEffect(() => {
    formInput.ozelAlanlar.length > 0 && FindQuestionAnswer();
  }, [formInput.ozelAlanlar]);

  useEffect(() => {
    const AnswerHandler = () => {
      const filtered = Array.from(formInput.ozelAlanlar).filter(
        (x) => x.COMPONENT_ID === selectedQuestion.componentId,
      )[0];

      if (filtered === undefined || selectedQuestion.tip === "checkboxGrup") {
        setFormInput((prev) => ({
          ...prev,
          ozelAlanlar: [
            ...prev.ozelAlanlar,
            {
              bagliComponentId: selectedQuestion.bagliComponentId,
              COMPONENT_ID: selectedQuestion.componentId,
              DEGERI: selectedQuestion.degeri,
            },
          ],
        }));
      } else {
        const index = Array.from(formInput.ozelAlanlar).indexOf(filtered);
        const item = formInput.ozelAlanlar[index];
        item.DEGERI = selectedQuestion.degeri;
        setFormInput((prev) => ({
          ...prev,
          ozelAlanlar: formInput.ozelAlanlar,
        }));
      }

      selectedQuestion.bagliComponentId != null && FindQuestionAnswer();
    };

    selectedQuestion != null && AnswerHandler();
  }, [selectedQuestion]);

  const TypeHandler = (type) => {
    type = parseInt(type);
    switch (type) {
      case 1:
        return "text";
      case 5:
        return "number";
      case 6:
        return "select";
      case 7:
        return "text";
      case 8:
        return "radio";
      case 9:
        return "date";
      case 13:
        return "";
      case 14:
        return "number";
      case 16:
        return "checkbox";
      case 17:
        return "textArea";
      case 19:
        return "textArea";
      default:
        break;
    }
  };
  const BaseInputHandler = (type) => {
    type = parseInt(type);
    switch (type) {
      case 1:
        return "input";
      case 5:
        return "input";
      case 6:
        return "select";
      case 7:
        return "input";
      case 8:
        return "radioGrup";
      case 9:
        return "input";
      case 13:
        return "phoneNumber";
      case 14:
        return "input";
      case 16:
        return "checkboxGrup";
      case 17:
        return "textArea";
      case 19:
        return "textArea";
      default:
        break;
    }
  };

  const Inputs = () => {
    return Array.from(ozelAlanlarData).map((x, i) => {
      return (
        <div key={i} className={classes.ozelAlanlar}>
          <h2>{x.grupAdi}</h2>
          {Array.from(x.sorular).map((y, j) => {
            const optData = [];
            const inputType = BaseInputHandler(y.veriGirisTipleriId);
            if (inputType === "select") {
              for (const item of y.cevaplar) {
                optData.push({ value: item.id, text: item.adi });
              }
            }
            if (inputType === "checkboxGrup") {
              for (const item of y.cevaplar) {
                optData.push({ value: item.id, text: item.adi });
              }
            }
            if (inputType === "radioGrup") {
              for (const item of y.cevaplar) {
                optData.push({ value: item.id, text: item.adi });
              }
            }
            if (
              (inputType === "select" ||
                inputType === "checkboxGrup" ||
                inputType === "radioGrup") &&
              optData.length <= 0
            ) {
              return;
            }

            return (
              <Input
                key={j}
                firstOption={
                  optData.length > 0
                    ? null
                    : "Önceki soruya cevap vermeniz gerekiyor"
                }
                type={BaseInputHandler(y.veriGirisTipleriId)}
                option={optData}
                style={{ width: "100%" }}
                label={y.etiketAdi}
                input={{
                  id: y.componentId,
                  type: TypeHandler(y.veriGirisTipleriId),
                  placeholder: y.etiketAdi,
                  required: y.zorunlu === "1" ? true : false,
                  onChange: (event) => {
                    setSelectedQuestion({
                      bagliComponentId:
                        y.bagliComponentId != null
                          ? y.bagliComponentId.replace(".0", "")
                          : null,
                      componentId: y.componentId.replace(".0", ""),
                      degeri:
                        inputType === "phoneNumber"
                          ? event.substring(2)
                          : event.target.value.replace(".0", ""),
                      tip: BaseInputHandler(y.veriGirisTipleriId),
                    });
                    // AnswerHandler(
                    //   y.bagliComponentId,
                    //   y.componentId,

                    // );
                  },
                }}
              />
            );
          })}
        </div>
      );
    });
  };
  return (
    <Fragment>
      <form id="form" className={classes.form} onSubmit={submitHandler}>
        {ozelAlanlarData && Inputs()}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {ekliDokumanBilgisiData && (
            <WebApplyFileUpload
              data={ekliDokumanBilgisiData}
              formInput={formInput}
              setFormInput={setFormInput}
              submitHandler={submitHandler}
              ozelAlanlarData={true}
            />
          )}
        </div>

        <div className={classes.formButton}>
          <button>BAŞVUR</button>
        </div>
      </form>
    </Fragment>
  );
};
