import React, { Fragment } from "react";
import classes from "./SupportGiveTakeTab.module.css";

export const SupportGiveTakeTab = ({ supportTab, setSupportTab }) => {
  return (
    <Fragment>
      <div className={classes.container}>
        <div
          className={`${classes.box} ${supportTab === 0 && classes.selected}`}
          onClick={() => setSupportTab(0)}
        >
          <img src="/assets/icons/destekAl.png" alt="" />
          <span>Destek Al</span>
        </div>
        <div
          className={`${classes.box} ${supportTab === 1 && classes.selected}`}
          onClick={() => setSupportTab(1)}
        >
          <img src="/assets/icons/destekVer.png" alt="" />
          <span>Destek Ver</span>
        </div>
      </div>
    </Fragment>
  );
};
