import React from "react";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";
import { useState } from "react";
import { DataTableProcess, EntityStatusHtml } from "../../../util/commonUtil";
import { useEffect } from "react";
import { SwalSuccess, SwallApprove, SwallDelete } from "../../../util/swalUtil";
import { Fragment } from "react";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { WebApplyAdminCreateForm } from "../../../components/admin/webApply/WebApplyAdminCreateForm";

export const WebApplyAdmin = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Web Başvuru Formları";
  const apiName = "webApply";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState();
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) => (
        <div>
          <button
            className="dataTableProcessEdit"
            onClick={() => EditHandler(row)}
          >
            Tanımlamalar
          </button>
        </div>
      ),
    },

    {
      name: "Ad",
      selector: (row) => row.name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
    {
      name: "Kısa Açıklama",
      selector: (row) => row.shortDescription,
      sortable: true,
      width: "auto",
    },
  ];

  const EditHandler = (data) => {
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        apiName,
        "get",
        null,
        null,
        "admin"
      );
      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          apiName,
          method,
          null,
          formInput
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Tanımlamalar `}
        >
          <WebApplyAdminCreateForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
