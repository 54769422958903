import React, { Fragment } from "react";
import { EntityStatusData, LangData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";

export const LangCrudForm = ({
  formInput,
  setFormInput,
  submitHandler,
  setShowModal,
}) => {
  return (
    <Fragment>
      <form id="form" className="form" onSubmit={submitHandler}>
        <Input
          label="Ad"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "name",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.name != null ? formInput.name : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            },
          }}
        />
        <div style={{ display: "flex" }}>
          <Input
            label="Dil"
            type="select"
            style={{ width: "100%" }}
            option={LangData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.lang != null
                  ? formInput.lang
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  lang: parseInt(event.target.value),
                }));
              },
            }}
          />
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={EntityStatusData()}
            input={{
              id: "entityStatus",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.entityStatus != null
                  ? parseInt(formInput.entityStatus)
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  entityStatus: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>
        <Input
          label="Kısa Açıklama"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "shortDescription",
            type: "text",
            required: "required",
            value:
              formInput != null && formInput.shortDescription != null
                ? formInput.shortDescription
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                shortDescription: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Açıklama"
          type="richText"
          input={{
            id: "longDescription",
            required: "required",
            value:
              formInput != null && formInput.longDescription != null
                ? formInput.longDescription
                : "",
            onEditorChange: (value, editor) => {
              setFormInput((prev) => ({
                ...prev,
                longDescription: value,
              }));
            },
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
          <button
            type="button"
            className="btnCancel"
            onClick={() => setShowModal(false)}
          >
            İptal
          </button>
        </div>
      </form>
    </Fragment>
  );
};
