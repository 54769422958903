import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./IzsuInvoiceList.module.css";
import { ConvertCurrency } from "../../../util/commonUtil";
import { Link, useLocation } from "react-router-dom";
import { PaymentForm } from "../paymentForm/PaymentForm";
import { AppContext } from "../../../context/app-context";
import { ThreeDModal } from "../ThreeDModal/ThreeDModal";

export const IzsuInvoiceList = ({ invoiceList, filter, setFilter }) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const [showPayment, setShowPayment] = useState(false);

  const formInputJson = {
    paymentType: 0,
    userType: 0,
    fullName: "",
    phoneNumber: "",
    email: "",
    title: "",
    taxNo: "",
    invoiceAddress: "",
    kvkk: false,
    saleAggrement: false,
    cardNumber: "",
    expiry: "",
    cvc: "",
    holderName: "",
    focus: "",
    variantId: null,
    quantity: 1,
    price: 0,
    totalAmount: 0,
    supportType: 3,
  };

  const [formInput, setFormInput] = useState(formInputJson);
  const [showThreeDModal, setShowThreeDModal] = useState(false);
  const [threedData, setThreedData] = useState(null);
  const [verId, setVerId] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [product, setProduct] = useState({ productPaymentType: 0 });

  useEffect(() => {
    const PaySecure = async () => {
      if (verId == null) return;
      let body = {
        holderName: formInput.holderName,
        cardNumber: formInput.cardNumber,
        expiry: formInput.expiry,
        cvc: formInput.cvc,
        verId: verId,
      };
      const response = await appContext.FetchHandler(
        "payment/paySecure",
        "post",
        null,
        body
      );
      setShowThreeDModal(false);

      if (response.ok) {
        setPaymentSuccess(true);
        setFormInput(formInputJson);
      }
    };
    PaySecure();
  }, [verId]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin ===
            process.env.REACT_APP_API_URL.toString().replace("/api", "") &&
          event.data !== "0"
        ) {
          setVerId(event.data);
        } else {
          setShowThreeDModal(false);
        }
      },
      false
    );
  }, []);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    appContext.GetGRecapToken().then(async (gToken) => {
      formInput.gToken = gToken;

      const response = await appContext.FetchHandler(
        "payment",
        "post",
        null,
        formInput
      );

      if (response.ok) {
        if (response.data.isThreeD) {
          setThreedData(response.data);
          setShowThreeDModal(true);
        } else {
          setFormInput(formInputJson);
          setPaymentSuccess(true);
        }
      }
    });
  };

  const FilterButtons = () => {
    const filters = [
      { min: 1, max: 999999999999, title: "Tümü" },
      { min: 10, max: 100, title: "10 - 100 TL" },
      { min: 100, max: 200, title: "100 - 200 TL" },
      { min: 200, max: 300, title: "200 - 300 TL" },
      { min: 300, max: 400, title: "300 - 400 TL" },
      { min: 400, max: 500, title: "400 - 500 TL" },
      { min: 500, max: 999999999999, title: "500 TL ve Üstü" },
    ];
    return filters.map((x, i) => {
      return (
        <button key={i} onClick={() => setFilter(x)}>
          {x.title}
        </button>
      );
    });
  };

  const PayBillHandler = (invoice) => {
    setShowPayment(true);
    setProduct((prev) => ({
      ...prev,
      invoice,
    }));

    setFormInput((prev) => ({
      ...prev,
      aboneNo: invoice.aboneNo,
      sozlesmeNo: invoice.sozlesmeNo,
      thkFisNo: invoice.thkFisNo,
    }));
  };

  const InvoiceRender = () => {
    return Array.from(invoiceList).map((x, i) => {
      return (
        x.borc && (
          <div
            key={i}
            className={classes.box}
            onClick={() => PayBillHandler(x)}
          >
            <div className={classes.boxContent}>
              <div className={classes.boxHead}>
                <h3>İZSU</h3>
                <p>Fiş No: {x.thkFisNo}</p>
              </div>
              <div className={classes.boxMain}>{ConvertCurrency(x.borc)}</div>
            </div>
            <button className={classes.boxButton}>Şimdi Öde</button>
          </div>
        )
      );
    });
  };

  const SelectedInvoiceRender = () => {
    return (
      <div className={classes.box}>
        <div className={classes.boxContent}>
          <div className={classes.boxHead}>
            <h3>İZSU</h3>
            <p>Fiş No: {product.invoice.thkFisNo}</p>
          </div>
          <div className={classes.boxMain}>
            {ConvertCurrency(product.invoice.borc)}
          </div>
        </div>
        <button className={`${classes.boxButton} ${classes.selectedInvoice}`}>
          Ödenecek Fatura
        </button>
      </div>
    );
  };

  const AllInvoiceHandler = () => {
    setProduct({ productPaymentType: 0 });
    setShowPayment(false);
  };
  const PaymentResult = () => {
    return (
      <Fragment>
        <div className={classes.paymentSuccess}>
          <img src="/assets/icons/success.png" alt="" />
          <h2>Ödeme Başarılı</h2>
          <div className={classes.paymentSuccessContentBox}>
            <p>
              Değerli Yurttaşımız; Faturanız elektronik fatura olarak
              düzenlenerek tarafınıza e-posta ile iletilecektir.
              Saygılarımızla...
            </p>
            <p>
              Not: Yoğunluktan dolayı elektronik faturanızın gönderiminde
              gecikmeler oluşabilmektedir. Tarafınıza iletilen e-postayı gelen
              kutusunda göremiyorsanız lütfen gereksiz (spam) klasörünü kontrol
              ediniz.
            </p>
          </div>

          <Link to="/" className={classes.btnRed}>
            Anasayfa
          </Link>
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <div className={classes.container}>
        {paymentSuccess ? (
          PaymentResult()
        ) : showPayment ? (
          <div className={classes.paymentContainer}>
            {threedData != null && showThreeDModal && (
              <ThreeDModal
                showModal={showThreeDModal}
                setShowModal={setShowThreeDModal}
                data={threedData}
              />
            )}
            <button
              className={classes.allInvoice}
              onClick={() => AllInvoiceHandler()}
            >
              Tüm Faturalar
            </button>
            {product && SelectedInvoiceRender()}
            <PaymentForm
              formInput={formInput}
              setFormInput={setFormInput}
              product={product}
              submitHandler={SubmitHandler}
              isProduct={false}
            />
          </div>
        ) : (
          <Fragment>
            <div className={classes.filter}>{FilterButtons()}</div>
            <div className={classes.boxContainer}>
              {invoiceList && InvoiceRender()}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
